

























































































import {Component, Vue} from 'vue-property-decorator';
import AhaMoment from "@/components/workspace/AhaMoment.vue";
import BoardMembers from "@/components/board/BoardMembers.vue";

@Component({
  components: {AhaMoment},
})
export default class RoomTutorial extends Vue {
  step: number = 3;
  loading: boolean = false;

  mounted() {
    this.$gtag.event('pmb_app_tutorial_mounted');
  }

  inviteMember() {
    this.$gtag.event('pmb_app_room_tutorial_member_invite_accepted');
    //@ts-ignore
    this.$parent.close();
    this.openBoardMembers();
    this.routeToBoard();
  }

  skipMemberInvite() {
    this.$gtag.event('pmb_app_room_tutorial_member_invite_skip');
    this.routeToBoard();
  }

  completeTutorial() {
    this.$gtag.event('pmb_app_room_tutorial_challenge_accepted');
    this.routeToBoard();
  }

  openBoardMembers() {
    this.$buefy.modal.open({
      component: BoardMembers,
      width: '480px',
      parent: this,
      props: {
        onboarding: true
      },
      canCancel: false,
    });
  }

  nextStep() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
      this.step++;
    }, 600);
  }

  routeToBoard() {
    this.$gtag.event('pmb_app_tutorial_completed');
    //@ts-ignore
    this.$parent.close();
  }
}
