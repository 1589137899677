
































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import Sprint from "@/components/board/sprint/Sprint.vue";
import {Objective as ObjectiveObject, ObjectivePaginator} from "@/typescript/types";
import {ObjectivesListQuery} from "@/graphql/objective";
import Objective from "@/components/board/objective/Objective.vue";
import Template from "@/components/workspace/Template.vue";
import {Container, Draggable} from "vue-smooth-dnd";
import {isAfter, isBefore, isEqual, parseISO} from "date-fns";
import sortBy from "lodash/sortBy";


@Component({
  components: {Template, Sprint, Objective, Container, Draggable},

})
export default class ObjectiveLists extends Vue {
  allObjectives: ObjectivePaginator | null = null;
  page = 1;
  loading: boolean = false;

  @Prop({required: false, default: false})
  isCategory!: boolean

  @Prop({required: false, default: false})
  isNorthstar!: boolean

  @Prop({required: false, default: null})
  categoryName!: String

  @Prop({required: true})
  filterType!: String

  @Prop({required: true})
  filterTypeId!: String

  @Prop({required: false, default: false})
  canCreateObjective!: boolean

  @Prop({required: false, default: null})
  objectiveStatus!: String

  @Prop({required: false, default: 'y'})
  objectiveMoveLockAxis!: String

  @Prop({required: false, default: false})
  cleanPreviousData!: boolean

  @Prop({required: false, default: null})
  userId!: String

  @Prop({required: false, default: null})
  startDate!: Date

  @Prop({required: false, default: null})
  endDate!: Date

  @Prop({required: false, default: true})
  shouldOpenShareUrl!: boolean

  @Prop({required: false, default: null})
  masterDashboardStart!: Date

  @Prop({required: false, default: null})
  masterDashboardEnd!: Date

  @Prop({required: false, default: null})
  sortByDateType!: String

  get board() {
    return this.$store.state.board;
  }


  get hasMorePages() {
    return this.allObjectives?.paginatorInfo.hasMorePages;
  }

  get totalData() {
    return this.allObjectives?.paginatorInfo.total;
  }

  drag(data: any): void {
    this.$emit('drag', data);
  }

  loadMore() {
    if(this.$props.isCategory) {
      this.page++;
      this.getAllCategoryObjectives();
    }else{
      this.page++;
      this.getAllObjectives();
    }
  }

  get filteredObjective() {
    if(this.allObjectives && this.allObjectives.data && this.allObjectives!.data.length > 0) {
      return this.allObjectives!.data;
    }
    return [];

  }

  getSortedObjectives(objectives: any) {

    if(this.isNorthstar) {
      return this.filterDuplicateData(sortBy(objectives, [this.$store.state.objective_sort_type]));
    }

    return objectives;
  }


  getAllCategoryObjectives() {
   
    this.loading = true;
    this.$apollo.query({
      query: ObjectivesListQuery,
      fetchPolicy: 'network-only',
      variables: {
        filter_type: this.$props.filterType,
        filter_type_id: this.$props.filterTypeId,
        filter_type_category: this.$props.categoryName,
        status: this.$props.objectiveStatus,
        userId: this.$props.userId,
        page: this.page,
        sortByDateType: this.$props.sortByDateType,
        start_date: this.$props.startDate,
        due_date: this.$props.endDate,
      }
    }).then((result: any) => {
      this.loading = false;
      if(this.allObjectives && this.allObjectives.data.length > 0) {
        this.allObjectives.data = this.filterDuplicateData([...this.allObjectives.data, ...result.data.getObjectivesLists.data]);
        this.allObjectives.paginatorInfo = result.data.getObjectivesLists.paginatorInfo;
      }else{
        this.allObjectives = result.data.getObjectivesLists;
      }

      if(this.page === 1){
        this.$events.fire('total-count-'+this.$props.categoryName, result.data.getObjectivesLists.paginatorInfo.total)
      }

    })
  }

  getAllObjectives() {
    this.loading = true;
    this.$apollo.query({
      query: ObjectivesListQuery,
      fetchPolicy: 'network-only',
      variables: {
        filter_type: this.$props.filterType,
        filter_type_id: this.$props.filterTypeId,
        status: this.$props.objectiveStatus,
        start_date: this.$props.startDate,
        due_date: this.$props.endDate,
        userId: this.$props.userId,
        page: this.page,
        master_dashboard_start: this.$props.masterDashboardStart,
        master_dashboard_end: this.$props.masterDashboardEnd,
        sortByDateType: this.$props.sortByDateType
      }
    }).then((result: any) => {

      this.loading = false;
      if(this.allObjectives && this.allObjectives.data.length > 0) {
        this.allObjectives.data = this.filterDuplicateData([...this.allObjectives.data, ...result.data.getObjectivesLists.data]);
        this.allObjectives.paginatorInfo = result.data.getObjectivesLists.paginatorInfo;
      }else{
        this.allObjectives = result.data.getObjectivesLists;
      }
    })
  }

  filterDuplicateData(arr: Array<any>) {
    return arr.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i)
  }

  scrollIntoView(name: string)  {

    this.$nextTick(function () {
      window.location.href = '#' + name;
    });
  }

  onDragStart(_dragResult: any): void {

  }

  onDropObjective(_dropResult: any, _containerName: any): void {

  }

  getGhostParentObjective() {
    return document.body;
  }

  getChildPayloadObjective(index: number) {
    if (this.allObjectives) {
      return this.allObjectives.data[index]
    }
  }

  addNewObjective(objective: ObjectiveObject) {

    let index = this.allObjectives!.data.findIndex((obj: any) => obj.id === objective.id);

    if(index == -1) {

      if((isAfter(parseISO(objective.start_date), this.$props.startDate)  && isBefore(parseISO(objective.start_date), this.$props.endDate)) || isEqual(parseISO(objective.start_date), this.$props.startDate) ) {
        this.allObjectives!.data.push(objective);
        this.allObjectives!.paginatorInfo.total += 1;
      }
    }else{
      if(this.$props.isCategory && this.categoryName == 'due') {

        if(isAfter(parseISO(objective.due_date), new Date())) {
          this.allObjectives!.data.splice(index, 1);
          this.allObjectives!.paginatorInfo.total -= 1;
        }
      }

    }

  }

  mounted() {
   
    if(this.cleanPreviousData) {
      this.allObjectives = null;
    }

    if(this.$props.isCategory) {
      this.getAllCategoryObjectives()
    }else{
      this.getAllObjectives();
    }

    this.$events.listen('objective-created', (eventData: any) => {

      if(this.isCategory && this.categoryName == 'unassigned_northstar') {

        if((isAfter(parseISO(eventData.start_date), this.$props.startDate)  && isBefore(parseISO(eventData.start_date), this.$props.endDate)) || isEqual(parseISO(eventData.start_date), this.$props.startDate) ){
          Vue.prototype.$consoleLog(eventData)
          this.addNewObjective(eventData);
        }

      }

    })

    this.$events.listen('snooze-objective-clear', (eventData: any) => {
      if(this.isCategory && this.categoryName == 'due' && this.allObjectives) {
        let index = this.allObjectives.data.findIndex((obj: any) => obj.id === eventData.id);

        if (index != -1) {
          //@ts-ignore
          this.allObjectives.data.splice(index, 1);
          this.allObjectives.paginatorInfo.total -= 1;
          this.$forceUpdate();
        }
      }
    })

    //For northstar

    this.$events.listen('objective-created-from-northstar', (eventData => {
      if(this.filterTypeId && this.allObjectives && this.allObjectives.data && this.filterTypeId == eventData.northStarId && this.isNorthstar) {
        let index = this.allObjectives.data.findIndex((objective: any) => objective.id === eventData.objective.id);
        if(index < 0){
      
          this.allObjectives.data!.push(eventData.objective);
          this.allObjectives!.paginatorInfo.total += 1;
        }

        this.$forceUpdate();
      }

    }));

    this.$events.listen('northstar-objective-status-updated', eventData => {
      if(this.allObjectives && this.isNorthstar) {
        let index = this.allObjectives.data.findIndex((obj: any) => obj.id === eventData.objId);
        if(index != -1){
          //@ts-ignore
          this.allObjectives.data[index].status = eventData.status;
          this.$forceUpdate();
        }
      }

    });

    this.$events.listen('objective-updated-from-northstar', eventData => {
      if(this.isNorthstar && this.allObjectives) {
        let index = this.allObjectives.data.findIndex((obj: any) => obj.id === eventData.id);

        if (index != -1) {
          //@ts-ignore
          this.allObjectives.data[index] = eventData;
          this.$forceUpdate();
        }
      }
    })

    this.$events.listen('objective-updated', eventData => {
   
      if(this.isCategory && this.allObjectives) {
        let index = this.allObjectives.data.findIndex((obj: any) => obj.id === eventData.id);

        if (index != -1) {
          //@ts-ignore
          this.allObjectives.data[index] = eventData;
          this.$forceUpdate();
        }
      }else if(this.allObjectives) {
        let index = this.allObjectives.data.findIndex((obj: any) => obj.id === eventData.id);

        if(index == -1) {
          this.allObjectives.data.push(eventData);
        }
      }
    })

    this.$events.listen('northstar-linked-to-objective', (eventData: any) => {
      if(this.isNorthstar && this.filterTypeId === eventData.northStar.id && this.allObjectives) {
        this.allObjectives.data.push(eventData.objective);
        this.allObjectives!.paginatorInfo.total += 1;
      }else if(this.isNorthstar && this.filterTypeId != eventData.northStar.id && this.allObjectives){
        let index = this.allObjectives.data.findIndex((obj: any) => {
          return obj.id === eventData.objective.id;
        });

        if (index != -1) {
          this.allObjectives.data.splice(index, 1);
          this.allObjectives!.paginatorInfo.total -= 1;
        }

      }


      if(this.isCategory && this.categoryName == 'unassigned_northstar') {
        if(eventData.previousNorthstar && eventData.northStar == null){
          let index = this.allObjectives!.data.findIndex((obj: any) => {
            return obj.id === eventData.objective.id;
          });


          if(index == -1) {
            this.allObjectives!.data.push(eventData.objective);
            this.allObjectives!.paginatorInfo.total += 1;
          }

        }else{
          let index = this.allObjectives!.data.findIndex((obj: any) => {
            return obj.id === eventData.objective.id;
          });

          if (index != -1) {
            this.allObjectives!.data.splice(index, 1);
            this.allObjectives!.paginatorInfo.total -= 1;
          }
        }
      }
    })

    this.$events.listen('objective-deleted', (eventData => {
      if(this.allObjectives) {
        let index = this.allObjectives!.data.findIndex((objective: ObjectiveObject) => objective.id === eventData.id);

        this.allObjectives!.data.splice(index, 1);
        this.allObjectives!.paginatorInfo.total -= 1;
      }


    }))

    this.$events.listen('remove-objective-form-unassigned-northstar', (eventData => {
      if(this.allObjectives && this.isCategory && this.categoryName == 'unassigned_northstar') {
        let index = this.allObjectives!.data.findIndex((objective: ObjectiveObject) => objective.id === eventData.objectiveId);

        this.allObjectives!.data.splice(index, 1);
        this.allObjectives!.paginatorInfo.total -= 1;
      }


    }))

    this.$events.listen('set-objective-recurrence', eventData => {
      this.allObjectives!.data.map(obj => {
        if(obj.id === eventData.objective_id) {
          obj.is_recurring = true;
          obj.start_date = eventData.start_date;
          return obj;
        }
      })



      // if(this.$props.masterDashboardStart) {
      //   if(isAfter(eventData.start_date, this.$props.masterDashboardEnd)) {
      //     this.allObjectives!.data.filter(obj => {
      //       return obj.id != eventData.objective_id
      //     })
      //   }
      // }

    })

    this.$events.listen('delete-objective-recurrence', eventData => {
      this.allObjectives!.data.map(obj => {
        if(obj.id === eventData.objective_id) {
          obj.is_recurring = false;
          return obj;
        }
      })
    })

    this.$events.listen('northstar-color-change', eventData => {

      this.allObjectives!.data.map(obj => {
        //@ts-ignore
        if(obj && obj.northStars.length > 0) {
          //@ts-ignore
          if(obj.northStars[0].id === eventData.northstar_id) {
            //@ts-ignore
            obj.northStars[0].color = eventData.color
            return obj;
          }
        }

      })

    })

    this.$events.listen('objective-status-updated', (eventData: any) => {
      if(this.isCategory && this.allObjectives) {
        let index = this.allObjectives.data.findIndex((obj: any) => obj.id === eventData.id);

        if (index != -1) {
          //@ts-ignore
          this.allObjectives.data[index] = eventData;
          this.$forceUpdate();
        }
      }
    })

    // if(this.board) {
    //   this.$apollo.subscribe({
    //     query: ObjectiveUpdatedSubscription,
    //     variables: {
    //       board_id: this.board.id,
    //     },
    //
    //
    //   }).subscribe((result) => {
    //     if(result && result.data.objectiveUpdated) {
    //       if(result.data.objectiveUpdated.northStars.length <= 0) {
    //         this.addNewObjective(result.data.objectiveUpdated);
    //
    //       }
    //     }
    //
    //   })
    // }




  }

  @Watch('userId',{ deep: true })
  userIdChange() {
    this.allObjectives = null;
    this.page = 1;
    if(this.$props.isCategory) {
      this.getAllCategoryObjectives();
    }else{
      this.getAllObjectives();
    }
  }

  @Watch('$store.state.active_board_pid', { deep: true })
  boardChange() {
    this.allObjectives = null;
    this.page = 1;
    if(this.$props.isCategory) {
      this.getAllCategoryObjectives();
    }else{
      this.getAllObjectives();
    }
  }

  @Watch('startDate',{ deep: true })
  startDateChange(newVal: Date, oldVal: Date) {
    if(newVal != oldVal) {
      this.allObjectives = null;
      this.page = 1;
      if (this.$props.isCategory) {
        this.getAllCategoryObjectives();
      } else {
        this.getAllObjectives();
      }
    }
  }

  @Watch('masterDashboardStart',{ deep: true })
  masterStartDateChange(newVal: Date, oldVal: Date) {
    if(!isEqual(newVal, oldVal)) {
    
      this.page = 1;
      this.allObjectives = null;
      if(this.$props.isCategory) {
        this.getAllCategoryObjectives();
      }else{
        this.getAllObjectives();
      }
    }

  }

  @Watch('sortByDateType',{ deep: true })
  sortByDateTypeChange(newVal: String, oldVal: String) {
    if(newVal != oldVal) {
  
      this.page = 1;
      this.allObjectives = null;
      if(this.$props.isCategory) {
        this.getAllCategoryObjectives();
      }else{
        this.getAllObjectives();
      }
    }

  }

}
