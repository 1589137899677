














import {Component, Vue,} from 'vue-property-decorator';
import NotificationBarCountDownTimer from './NotificationBarCountDownTimer.vue';
import InitialOffer from '../subscription/InitialOffer.vue';

@Component({
  components: {NotificationBarCountDownTimer},
})
export default class OfferNotificationBar extends Vue {

  mounted() {
    this.$store.commit('set_initial_offer_code', true);
  }

  openOfferModal() {
    // @ts-ignore
    this.modalOpen = true;
    this.$buefy.modal.open({
      component: InitialOffer,
      // @ts-ignore
      customClass: 'round-corners',
      width: '85vw',
      parent: this,
      canCancel: false,
    });
  }
}
