






















































import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class WeeklyEngagement extends Vue {
  highestValue: number | null = 9;
  totalValue: number = 14;

  @Prop({ required: false })
  completedCount!: number | null;

  get currentHighestValue() {
    if(this.completedCount! <= 7) {
      return 7;
    } else {
      return this.completedCount! + 1;
    }
  }

  get days() {
    return [
      { day: 'Monday', current: 7 },
      { day: 'Tuesday', current: this.completedCount! }
    ];
  }

  get completionPercentage() {
    if (this.totalValue === 0) {
      return '0%'; // Handle the case where totalValue is 0 to avoid division by zero.
    }
    const percentage = (this.completedCount! / this.totalValue) * 100;
    return percentage.toFixed(1) + '%'; // Returns the percentage with one decimal place
  }


  getAnimationDelay(index: number) {
    // Animation for delay of graph bars. Takes the index of v-for loop and converts it to ms
    return index * 100 + 'ms';
  }

  compareWithHighest(num: number) {
    // This calculates how to display a representative visual height of progress of each bar item
    if(this.currentHighestValue) {
      return (num / this.currentHighestValue * 100) - 15 + '%';
    } else {
      return '10%';
    }
  }


  colors: Array<string> = [
    '#bd5fb4',
    '#773f75',
    '#4a677d',
    '#776015',
    '#66737a',
    '#7b7343',
    '#316863',
    '#2e825f',
    '#4e6068',
    '#717171',
    '#434343',
  ];
}
