
































import {Component, Vue} from 'vue-property-decorator';
import AutoGenRegister from "@/components/auth/AutoGenRegister.vue";

@Component({
  components: {AutoGenRegister},
})
export default class LandingPageCollaborative extends Vue {

  goals: Array<{ name: string, items: Array<any> }> = [
    {
      name: 'Improve collaboration',
      items: []},
    {
      name: 'Team building activites',
      items: []},
    {
      name: 'Increase team engagement',
      items: [
        'Check-in on a colleague',
        'Plan your team goals',
        'Schedule offsite',
      ]},
  ];

  mounted() {
    this.$store.commit('set_onboarding_use_case', 'collaborative');
  }
}
