













































































import {Component, Vue} from 'vue-property-decorator';
import {UpdateUserAccountType} from "@/graphql/auth";
import {CreateWorkspaceMutation, WorkspaceQuery} from "@/graphql/workspace";
import {CreateBoardMutation} from "@/graphql/board";
import InRoomActivation from "@/components/auth/register/InRoomActivation.vue";

@Component({
  components: {},
})


export default class RegistrationSteps extends Vue {
  currentStep: Number = 2
  userAccountType: string | null = null;
  isLoading: boolean = false;
  nameWorkspace: boolean = false;
  workspaceName: string | null = 'Work';
  permissions = 'workspace';
  autoCreating: boolean = false;
  referralCode: string | null = null


  templateId: string | null = null

  mounted() {
    let urlParams = new URLSearchParams(window.location.search);
    this.referralCode = (this.$store.state.autoGenUserData && this.$store.state.autoGenUserData!.referral_code) ? this.$store.state.autoGenUserData!.referral_code : urlParams.get('u_referral_code');
    this.templateId = (this.$store.state.autoGenUserData && this.$store.state.autoGenUserData!.template_id) ? this.$store.state.autoGenUserData!.template_id : urlParams.get('template_id');
    this.$gtag.event('pmb_app_onboarding_mounted');

    if(this.$store.state.onboarding_completed) {
      this.$router.push({ name: 'dashboard-layout' })
    } 
    else {
      this.autoCreate();
    }

    if(this.$store.state.onboarding_use_case) {
      this.$gtag.event('pmb_onboarding_use_case_' + this.$store.state.onboarding_use_case);
    }
  }

  autoCreate() {
    if(this.$store.state.currently_onboarding_in_room && !this.$store.state.onboarding_complete) {
      this.openActivationModal();
    } else {
      this.autoCreating = true;
      this.createWorkspace();
    }
  }

  get onboardingUseCase() {
    return this.$store.state.onboarding_use_case;
  }

  get workspaceDemoName() {
    if(this.onboardingUseCase === 'collaborative') {
      return 'Work'
    } else if(this.onboardingUseCase === 'personal') {
      return 'Home'
    } else {
      return 'Work'
    }
  }

  get boardName() {
    if(this.onboardingUseCase === 'collaborative') {
      return 'Goal planning'
    } else if(this.onboardingUseCase === 'personal') {
      return 'Goal planning'
    } else {
      return 'Goal planning'
    }
  }

  get domainName() {
    return this.workspaceDemoName;
  }

  updateUserAccountType(type: string) {
    if(this.isLoading) {
      return
    }
    this.userAccountType = type;
    this.isLoading = true;
    this.$gtag.event('pmb_app_onboarding_user_type_' + type);
    this.$apollo
      .mutate({
        mutation: UpdateUserAccountType,
        variables: {
          account_type: this.userAccountType
        },
      })
      .then((_data: any) => {
        this.$gtag.event('pmb_app_onboarding_account_type_selected');
        this.createWorkspace();
      })
      .catch((e: any) => {
        Vue.prototype.$consoleLog(e)
      })
  }

  createWorkspace() {
    this.$apollo.mutate({
      mutation: CreateWorkspaceMutation,
      variables: {
        name: this.workspaceDemoName,
        domain: this.domainName,
        referral_code: this.referralCode,
        template_id: this.templateId
      }
    }).then(({ data: { createWorkspace: workspace } }) => {
      this.$gtag.event('pmb_app_onboarding_workspace_created');
      this.$store.commit('set_workspace', workspace);
      this.$store.commit('set_active_workspace_pid', workspace.pid);
      this.$store.commit('set_active_workspace_access', workspace.my_access);
      this.$store.commit('set_currently_onboarding', true);
      this.createBoard(workspace.id)
    })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        //this.clicked = false;
      });
  }

  createBoard(workspaceId: string) {
    this.$apollo
      .mutate({
        mutation: CreateBoardMutation,
        variables: {
          name: this.boardName,
          workspace: workspaceId,
          permissions: this.permissions,
          board_level_identifier: 2,
          sprint_interval: 2
        },
        refetchQueries: [{ query: WorkspaceQuery, variables: { pid: this.$store.state.active_workspace_pid } }],
      })
      .then(({ data: { createBoard: board } }) => {
        this.$store.commit('set_currently_onboarding', false);
        this.$store.commit('set_currently_onboarding_in_room', true);
        this.$router.push({
          name: 'room', params: {
            board_pid: board.pid,
            workspace_pid: this.$store.state.active_workspace_pid,
          }
        });
        this.$gtag.event('pmb_app_onboarding_board_create_success');
        this.$store.commit('set_currently_in_activation_step', true);
        this.openActivationModal();

      })
      .catch((_error) => {
        this.$gtag.event('pmb_app_onboarding_board_create_failed');
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
  openActivationModal() {
    this.$buefy.modal.open({
      component: InRoomActivation,
      //@ts-ignore
      customClass: 'add_goal_from_onboarding',
      canCancel: false,
      parent: this,
    });
  }
}

