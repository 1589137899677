







































import {Component, Mixins} from 'vue-property-decorator';
import {UpdateForgottenPasswordMutation} from '@/graphql/auth';
import LoginLayout from '@/components/auth/login/LoginLayout.vue';
import {ValidationErrorParserMixin} from "@/mixins/ValidationErrorParserMixin";

@Component({
  components: {LoginLayout},
})
export default class NewPasswordForm extends Mixins(ValidationErrorParserMixin) {
  errorMessage: string | null = null;
  password = '';
  password_confirmation = '';
  clicked = false;

  get email() {
    return this.$route.query.email;
  }

  get token() {
    return this.$route.query.token;
  }

  save() {
    this.clicked = true;
    this.errorMessage = null;
    this.$apollo
      .mutate({
        mutation: UpdateForgottenPasswordMutation,
        variables: {
          email: this.email,
          token: this.token,
          password: this.password,
          password_confirmation: this.password_confirmation,
        },
      })
      .then(({data: {updateForgottenPassword}}) => {
        if (updateForgottenPassword.status === 'PASSWORD_NOT_UPDATED') {
          this.errorMessage = `Your password could not be reset. Make sure the link either clicked the button
                        in the email or copied and pasted the complete link.`;
        } else {
          this.$router.replace({name: 'login'})
        }
      })
      .catch((error) => {
        this.parseValidationError(error)
      })
      .finally(() => {
        this.clicked = false;
      });
  }
}
