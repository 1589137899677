





































































































import { Component, Vue } from 'vue-property-decorator';
import WorkspaceAvatar from "@/components/workspace/WorkspaceAvatar.vue";
import UserSidebar from "@/components/navigation/UserSidebar.vue";
import UserStatus from "@/components/user/UserStatus.vue";
import MemberListItem from "@/components/navigation/MemberListItem.vue";
import WorkspaceMenu from "@/components/navigation/WorkspaceMenu.vue";
import MyWorkspacesList from "@/components/navigation/MyWorkspacesList.vue";
import UserProfile from "@/components/user/profile/UserProfile.vue";
import PomodoroTimer from "@/components/user/PomodoroTimer.vue";
import UserAvatar from "@/components/user/UserAvatar.vue";
import {Workspace} from "@/typescript/types";
import UserFeedbackButton from "../user/UserFeedbackButton.vue"


@Component({
  components: {
    UserAvatar,
    PomodoroTimer,
    MyWorkspacesList,
    WorkspaceMenu,
    MemberListItem,
    WorkspaceAvatar,
    UserSidebar,
    UserFeedbackButton
  },
})
export default class WorkspaceSwitcher extends Vue {
  sliceAmount: number = 0;
  showingMoreWorkspaces: boolean = false;
  paginateWithCount: number = 5;
  currentPaginationCount: number = 0;
  activeTab: number = 0;
  dynamicTooltip: string = "";

  get me() {
    return this.$store.state.me;
  }

  get workspace() {
    return this.$store.state.workspace;
  }

  get isAdminOfWorkspace() {
    return this.$store.getters.isAdminOfWorkspace;
  }

  get currentWorkspacePid() {
    return this.$store.state.workspace.pid;
  }

  get isPremium() {
    if(this.$store.state.workspace.currentSubscription) {
      return true
    } else { return false }
  }

  get workspaceMembersCount() {
    return this.$store.state.workspace.members.length;
  }

  updateTooltip(workspace: Workspace) {
    if(this.currentWorkspacePid === workspace.pid) {
      this.dynamicTooltip = `${workspace.name} - Current workspace`;
    } else {
      this.dynamicTooltip = `${workspace.name}`;
    }
  }
  

  shouldShowTwoLetters(workspaceName: string) {
    const firstLetter = workspaceName.trim()[0].toLowerCase();
  
    const count = this.me.workspaces.reduce((acc: any, workspace: any) => {
      if(workspace.name.trim()[0].toLowerCase() === firstLetter) {
        acc += 1;
      }
      return acc;
    }, 0);

    // If more than one workspace starts with the same letter, return true
    return count > 1;
  }

  openUserProfile() {
    this.$buefy.modal.open({
      component: UserProfile,
      // @ts-ignore
      customClass: 'is-paddingless user_profile_modal',
      width: '700px',
      parent: this,
    });
  }

  openSwitchWorkspace() {
    this.$buefy.modal.open({
      component: MyWorkspacesList,
      width: '420px',
      customClass: 'my-workspaces-modal',
      parent: this,
    });
  }

  openWorkspace(workspace: Workspace) {
    if(this.$route.params.workspace_pid !== workspace.pid) {
      this.$store.commit('set_active_workspace_access', workspace.my_access);
      if(workspace.my_access == 0){
        this.$router.push({ name: 'dashboard-layout', params: { workspace_pid: workspace.pid } }).catch(()=>{});
      }else{
        this.$store.commit('set_workspace', workspace);
        this.$store.commit('set_active_workspace_pid', workspace.pid);
        this.$router.push({ name: 'WorkspaceBlocked'});
      }
    }
  }

  goToWorkspaceCreate() {
    this.$gtag.event('pmb_create_new_workspace_initiated');
    this.$router.push('/create-workspace');
  }

  get hasStatus() {
    if(this.me.status) {
      return this.me.status.emoji;
    } else {
      return null;
    }
  }

  get hasUnreadNotification() {
    let unReadNotification = this.$store.state.me.notifications.data.filter((noti: any) => {
      return noti.read_at == null;
    });

    if(unReadNotification.length > 0){
      this.switchFavicon(true)
      return true;
    } else {
      this.switchFavicon(false)
      return false;
    }
  }

  switchFavicon(isNotifyFavicon: boolean) {
    const faviconElement = document.getElementById('favicon') as HTMLLinkElement;
    if (!faviconElement) {
      // Favicon element with id "favicon" not found
      return;
    }

    if (!isNotifyFavicon) {
      // Switch to the default favicon with a unique query string
      faviconElement.href = window.location.origin + '/favicon.ico?v=' + new Date().getTime();
    } else {
      // Switch to the "notify" favicon with a unique query string
      faviconElement.href = window.location.origin + '/favicon-notify.ico?v=' + new Date().getTime();
     
    }
  }

  openStatusModal() {
    this.$buefy.modal.open({
      component: UserStatus,
      // @ts-ignore
      customClass: 'is-paddingless',
      width: '520px',
      parent: this,
    });
  }

}
