





































































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {
  ObjectivesListQuery,
  UpdateObjectivesOrder,
  UpdateObjectiveNorthStars
  //ObjectiveUpdatedSubscription
} from "@/graphql/objective";
import {Board, NorthStar, Objective as ObjectiveObject, ObjectivePaginator} from "@/typescript/types";
import {Container, Draggable} from "vue-smooth-dnd";
import Objective from "@/components/board/objective/Objective.vue";
import NorthStarData from "@/components/northstars/NorthStarData.vue";
import GuidedCreateObjective from "@/components/board/objective/GuidedCreateObjective.vue";
import {BoardQuery} from "@/graphql/board";
import store from "@/store";
import CreateObjectiveTrigger from "@/components/objectives/CreateObjectiveTrigger.vue";
import Template from "@/components/templates/Template.vue";
import {isPast} from "date-fns";
import sortBy from "lodash/sortBy";




@Component({
  components:{Template, CreateObjectiveTrigger, Objective, Container, Draggable, GuidedCreateObjective},
})

export default class NorthstarObjectiveLists extends Vue {
  allObjectives: ObjectivePaginator | null = null;
  page: number = 1;
  objectiveDueDate: Date | null = null;
  previousSelectedNorthstar: Array<any> = [];
  goalCollapsed: boolean = true;
  completeCount: number = 0;
  objectiveStatus: string = 'all'
  showAllObjectives: boolean = true;
  showCompletedObjectives: boolean | null = null;
  placedOnFreeSpace: boolean = false;


  @Prop({required: true})
  northStar!: NorthStar

  @Prop({required: false, default: true})
  shouldOpenShareUrl!: boolean

  @Prop({required: false, default: false})
  masonry!: boolean

  @Prop({required: false, default: null})
  board!: Board

  @Prop({required: false, default: null})
  startDate!: Date

  @Prop({required: false, default: null})
  endDate!: Date

  @Prop({required: false, default: null})
  sortByDateType!: String

  @Prop({default: true, required: false})
  openAddObjective!: boolean;

  @Prop({default: null, required: false})
  connectedObjectives!: any

  get showAddObjective() {
    if(this.northStar) {
      //@ts-ignore
      if(this.northStar.boards.length > 0) {
        return true;
      }

    }
    return false;
  }

  allowShowCompleted(status: string) {
    this.completedObjectiveCount();
    let allow;
    if(this.showCompletedObjectives) {
      allow =  this.showCompletedObjectives;
    } else {
      allow = this.$store.state.objective_data_completed_activities;
    }
    if(status === 'completed' && allow) {
      return true;
    } else if(status !== 'completed') {
      return true;
    } else {
      return false;
    }
  }

  get background() {
    if(this.northStar.color) {
      return this.northStar.color;
    } else {
      return '#696969';
    }
  }

  get hasMorePages() {
    return this.allObjectives?.paginatorInfo.hasMorePages;
  }

  getSortedObjectives(objectives: any) {

    if(this.sortByDateType == 'due_date') {
      return this.filterDuplicateData(sortBy(objectives, ['due_date']))
    }else if(this.sortByDateType == 'start_date') {
      return this.filterDuplicateData(sortBy(objectives, ['start_date']))
    }
    return this.filterDuplicateData(sortBy(objectives, ['order']));

  }

  completedObjectiveCount() {
   
    return this.allObjectives?.data.filter((obj: any) => {
      return obj.completed_at != null;
    }).length;
  }

  handleObjectivesTagClick() {
    if(!this.goalCollapsed) {
      this.goalCollapsed = !this.goalCollapsed;
      this.showCompletedObjectives = true;
    } else {
      this.showCompletedObjectives = !this.showCompletedObjectives;
    }
  }

  created() {
  
    if(this.connectedObjectives != null && typeof this.connectedObjectives == 'object') {
      let data = this.connectedObjectives;
      if(data.data.length > 0) {
        data['__typename'] = 'ObjectivePaginator'
      }

      this.allObjectives = data;

    }
    //this.getAllObjectives();
  }

  filterDuplicateData(arr: Array<any>) {
    return arr.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i)
  }

  hasPassedDueDate(date: Date) {
    return isPast(new Date(date));
  }

  openSpotlight(northstar: NorthStar, board: string) {
    this.$buefy.modal.open({
      component: GuidedCreateObjective,
      props: {
        dueDateFromSprint: this.endDate,
        startDateFromSprint: this.startDate,
        northStar: northstar,
        focusOnMount: true,
        inModal: true,
        boardId: board
      },
      // @ts-ignore
      customClass: 'create-new-objective blurless',
      fullScreen: true,
      parent: this,
    });
  }

  getAllObjectives() {
    this.$apollo.query({
      query: ObjectivesListQuery,
      fetchPolicy: 'network-only',
      variables: {
        filter_type: 'northstar',
        filter_type_id: this.northStar.id,
        status: this.objectiveStatus,
        master_dashboard_start: this.$props.startDate,
        master_dashboard_end: this.$props.endDate,
        page: this.page,
        sortByDateType: this.$props.sortByDateType
      },

    }).then((result: any) => {
   
      if(this.allObjectives && this.allObjectives.data.length > 0) {
        this.allObjectives.data = this.filterDuplicateData([...this.allObjectives.data, ...result.data.getObjectivesLists.data]);
        this.allObjectives.paginatorInfo.total = result.data.getObjectivesLists.paginatorInfo.total;
        this.allObjectives.paginatorInfo.currentPage = result.data.getObjectivesLists.paginatorInfo.currentPage;
        this.allObjectives.paginatorInfo.hasMorePages = result.data.getObjectivesLists.paginatorInfo.hasMorePages;
      }else{
        this.allObjectives = result.data.getObjectivesLists;
        this.$store.commit('set_total_objectives_in_timeStamp', (this.$store.getters.getTotalObjectivesInTimeStamp != null) ? this.$store.getters.getTotalObjectivesInTimeStamp + result.data.getObjectivesLists.paginatorInfo.total : result.data.getObjectivesLists.paginatorInfo.total)
      }
    })
  }

  onDragStart(_dragResult: any, containerName: any): void {

    this.previousSelectedNorthstar?.push(containerName);
   
  }


  getGhostParentObjective() {
    return document.body;
  }

  getChildPayloadObjective(index: number) {

    if (this.allObjectives) {
      return this.allObjectives.data[index]
    }
  }

  openNorthStarAsModal(id: string) {
    this.$buefy.modal.open({
      component: NorthStarData,
      props: {
        onmodal: true,
        editable: true,
        showMilestones: true,
        board: (this.$store.state.board) ? this.$store.state.board : this.$props.board,
        id: id
      },
      width: '780px',
      animation: 'none',
      customClass: 'northstar-detailed is-paddingless',
      // @ts-ignore
      parent: this,
    });
  }

  loadMore() {
    this.page++;
    this.getAllObjectives();
  }

  mounted() {
    //For northstar

    this.$events.listen('objective-created-from-northstar', (eventData => {
      
      if(this.allObjectives && this.allObjectives.data && this.northStar.id == eventData.northStarId) {
        let index = this.allObjectives.data.findIndex((objective: any) => objective.id === eventData.objective.id);
        if(index < 0){

          this.allObjectives.data!.push(eventData.objective);
        }

        this.$forceUpdate();
      }

    }));

    this.$events.listen('objective-deleted', (eventData => {
      if(this.allObjectives && eventData.northStars.length > 0 && this.northStar.id == eventData.northStars[0].id) {
        let index = this.allObjectives!.data.findIndex((objective: ObjectiveObject) => objective.id === eventData.id);

        this.allObjectives!.data.splice(index, 1);
      }
    }))

    this.$events.listen('objective-updated-from-northstar', eventData => {

      if(eventData.northStars.length > 0) {
        let ns = eventData.northStars[0].id;
        if(this.allObjectives && (this.northStar.id == ns)) {
          let index = this.allObjectives.data.findIndex((obj: any) => obj.id === eventData.id);

          if (index != -1) {
            //@ts-ignore
            this.allObjectives.data[index] = eventData;
            this.$forceUpdate();
          }else{
            this.allObjectives.data.push(eventData)
          }
        }
      }

    })

    this.$events.listen('northstar-linked-to-objective', eventData => {
    
      if(eventData.previousNorthstar.length > 0 && this.northStar.id == eventData.previousNorthstar[0].id && this.allObjectives) {
        let index = this.allObjectives?.data.findIndex((obj: any) => {
          return obj.id == eventData.objective.id;
        });

        if(index != -1) {
          this.allObjectives?.data.splice(index, 1);
        }
      }else if( eventData.northStar && this.northStar.id == eventData.northStar.id && this.allObjectives) {
        this.allObjectives?.data.push(eventData.objective)
      }
    })

    this.$events.listen('northstar-color-change', eventData => {
      if(this.$props.northStar.id == eventData.northstar_id) {
        this.allObjectives?.data.map((obj: any) => {
          return obj.northStars[0].color = eventData.color;
        })
    
      }
    })

    this.$events.listen('northstar-linked-to-objective-self', eventData => {
      if(this.northStar.id == eventData.northStar) {

        this.allObjectives?.data.push(eventData.objective)
        this.modifyOrder();
      }

    })

    this.$events.listen('northstar_board_selected', eventData => {
      if(this.northStar.id === eventData.id) {
        this.northStar.boards = eventData.boards;
      }

    })

    this.$events.listen('snooze-objective-clear', (eventData: any) => {

      if(this.allObjectives && eventData.northStars.length > 0 && eventData.northStars[0].id == this.northStar.id && eventData.due_date == null) {
        let index = this.allObjectives.data.findIndex((obj: any) => obj.id === eventData.id);

        if (index == -1) {
          this.allObjectives.data.push(eventData)
        }
      }
    })

    this.$events.listen('set-objective-recurrence', (eventData: any) => {
      if(this.allObjectives) {
        let index = this.allObjectives.data.findIndex((obj: any) => obj.id === eventData.objective_id);

        if (index != -1) {
          //@ts-ignore
          this.allObjectives.data[index].start_date = eventData.start_date;
          this.allObjectives.data[index].is_recurring = true;
          this.$forceUpdate();
        }
      }
    })

    this.$events.listen('delete-objective-recurrence', (eventData: any) => {
      if(this.allObjectives) {
        let index = this.allObjectives.data.findIndex((obj: any) => obj.id === eventData.objective_id);

        if (index != -1) {
          //@ts-ignore
          //this.allObjectives.data[index].start_date = eventData.start_date;
          this.allObjectives.data[index].is_recurring = false;
          this.$forceUpdate();
        }
      }
    })

    this.$events.listen('toggle-goals-on-dnd', (eventData: any) => {

      if(this.$props.northStar.id == eventData.northstarId) {
        this.goalCollapsed = eventData.toggle
      }

    })

    this.$events.listen('self-remove-objective-form-northstar', (eventData) => {

      if(eventData.northstarId == this.$props.northStar.id && this.allObjectives && this.allObjectives?.data.length > 0) {
        let index = this.allObjectives?.data.findIndex((obj: any) => {
          return obj.id == eventData.objectiveId;
        });


        if(index != -1) {

          this.allObjectives?.data.splice(index, 1);
          this.modifyOrder();
        }
      }


    })

    // this.$apollo.subscribe({
    //   query: ObjectiveUpdatedSubscription,
    //   variables: {
    //     board_id: this.board.id,
    //   },
    //
    // }).subscribe((result) => {
    //   if(result && result.data.objectiveUpdated) {
    //     if(result.data.objectiveUpdated.northStars.length >= 1 && result.data.objectiveUpdated.northStars[0].id == this.$props.northStar.id) {
    //       if(this.allObjectives && this.allObjectives.data.length > 0) {
    //         this.allObjectives.data = this.filterDuplicateData([...this.allObjectives.data, result.data.objectiveUpdated]);
    //
    //       }
    //
    //     }
    //   }
    //
    // })



  }

  onDrop(dropResult: any,containerName: any): void {
    //let {removedIndex, addedIndex, payload} = dropResult

    if (this.allObjectives?.data) {
      this.applyDrag(dropResult,containerName);
    }
  }

  applyDrag(dragResult: any, containerName: any): void {

    let {removedIndex, addedIndex, payload} = dragResult
    if (removedIndex === null && addedIndex === null || !payload) return;


    if (!this.allObjectives?.data) {
      return;
    }


    //For DnD to different Northstar
    if(removedIndex == null && addedIndex != null){

      //dragOnFreeSpace = false;
      this.linkNewNorthstar(containerName, payload);
      if(payload.northStars.length > 0) {
        this.$events.fire('self-remove-objective-form-northstar', {objectiveId: payload.id, northstarId: payload.northStars[0].id})
      }
      this.$events.fire('remove-objective-form-unassigned-northstar', {objectiveId: payload.id})
      this.modifyOrder();


    }

    //For DnD on same northstar
    if(removedIndex != null && addedIndex != null) {

      //dragOnFreeSpace = false;
      if (addedIndex >= this.allObjectives?.data.length) {
        let k = addedIndex - this.allObjectives?.data.length + 1;
        while (k--) {
          //@ts-ignore
          this.allObjectives?.data.push(undefined);
        }
      }
      this.allObjectives?.data.splice(addedIndex, 0, this.allObjectives?.data.splice(removedIndex, 1)[0]);
      this.modifyOrder();
      this.updateNorthstarObjectivesOrder();

    }





  }

  shouldAcceptDrop (_sourceContainerOptions: any, _payload: any) {
    if(this.northStar != undefined) {
      //@ts-ignore
      if(this.northStar?.boards?.length >= 1) {
        return true;
      }
      return false
    }
    return false;


  }



  modifyOrder() {
    this.allObjectives?.data.forEach((item: any, i: number) => {
      item.order = i;

    });
    this.$store.commit('set_objective_sort_type', 'order');
    this.$events.fire('sort_by_date_master_dashboard', 'order')
  }

  updateNorthstarObjectivesOrder() {
    this.$apollo.mutate({
      mutation: UpdateObjectivesOrder,
      variables: {
        objectiveIds: this.allObjectives?.data.map(item => item.id)
      }
    }).then((_res) => {
      
    })
  }

  linkNewNorthstar(northStar: string, objective: ObjectiveObject) {

    this.$apollo.mutate({
      mutation: UpdateObjectiveNorthStars,
      variables:{
        id: objective.id,
        northStars: [northStar]
      },

      refetchQueries: [{ query: BoardQuery, variables: { pid: store.state.active_board_pid } }],
    }).then((_res: any) => {
      this.$events.fire('northstar-linked-to-objective-self', {objective: _res.data.updateObjective
        , northStar: northStar});


    })
  }

  toggleShowCompleted() {
    this.showAllObjectives = !this.showAllObjectives;
   
    if(this.showAllObjectives) {
      
      this.objectiveStatus = 'all'
      this.allObjectives = null;
      this.page = 1;
      this.getAllObjectives();
    }else{
     
      this.objectiveStatus = 'not_completed'
      this.allObjectives = null;
      this.page = 1;
      this.getAllObjectives();
    }
  }



  @Watch('startDate',{ deep: true })
  startDateChange(newVal: Date, oldVal: Date) {
    if(newVal != oldVal) {
      this.allObjectives = null;
      this.page = 1;
      this.getAllObjectives();

    }
  }

  @Watch('northStar', {deep: true})
  northStarChanged(newVal: any) {
  
    this.northStar = newVal
  }

  @Watch('sortByDateType',{ deep: true })
  sortByDateTypeChange(newVal: String, oldVal: String) {
    if(newVal != oldVal) {
      this.allObjectives = null;
      this.page = 1;
      this.getAllObjectives();

    }

  }






}

