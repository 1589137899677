














































import {Component, Vue} from 'vue-property-decorator';
import WorkspaceSettingsIndex from "@/components/settings/workspace/WorkspaceSettingsIndex.vue";
import WorkspacePlanManager from "@/components/workspace/WorkspacePlanManager.vue";
import WorkspacePinnedMessages from "@/components/workspace/WorkspacePinnedMessages.vue";
import FilesManager from "@/components/workspace/FilesManager.vue";
import MembersManager from "@/components/workspace/MembersManager.vue";

@Component({
  components: {MembersManager, FilesManager, WorkspacePinnedMessages, WorkspacePlanManager, WorkspaceSettingsIndex},
})
export default class WorkspaceManagerIndex extends Vue {
  activeTab: string | null = 'settings';

  mounted() {
    this.setDefualtTab()
   
    this.$events.listen('close', (_) => {
      //@ts-ignore
      this.$parent.close();
    })
  }

  get planList() {
    return this.$store.state.plans;
  }

  get workspaceSubscription() {
    return this.$store.state.workspace.currentSubscription;
  }

  get currentPlan() {
    if(this.workspaceSubscription && !this.workspaceSubscription.hasAccess) {
      return this.planList[0];
    }else if(this.workspaceSubscription == null) {
      return this.planList[0];
    }
    return this.workspaceSubscription.plan;
  }

  get isAdminOfWorkspace() {
    return this.$store.getters.isAdminOfWorkspace;
  }

  setDefualtTab() {
    if(this.isAdminOfWorkspace) {
      this.activeTab = 'settings';
    }else {
      this.activeTab = 'plan';
    }
  }

  closeModal() {
    //@ts-ignore
    this.$parent.close();
  }

}
