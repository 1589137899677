import gql from 'graphql-tag';

export const PlansDetailedFragment = gql`
  fragment PlansDetailed on Plan {
      id
      title
      description
      price
      monthly_base_price
      interval
      planAccess {
          id
          max_user
          max_board
          file_manager_access
          create_chat_message_access
          pinned_message_access
          room_privacy_settings_access
      }
      stripe_plan_id
      stripeProduct {
          id
          stripe_product_id
          name
      }
  }
`

export const getAllPlans = gql`
    query GetAllPlans {
        plans {
            ...PlansDetailed
        }
    }
    ${PlansDetailedFragment}
`