












































import {Component, Vue} from 'vue-property-decorator';
import AhaMoment from "@/components/workspace/AhaMoment.vue";
import DemoList from "@/components/auth/demo/DemoList.vue";

@Component({
  components: {DemoList, AhaMoment},
})
export default class RegisterLayout extends Vue {
  expanded: boolean = true;
  runGuestActive: boolean = false;
  firstLoad: boolean = false;
  previewMode: boolean = false;
  outsideClick: boolean = false;

  mounted() {
    this.expanded = true;
    this.firstLoad = true;
    setTimeout(() => {
      this.expanded = false;
      this.firstLoad = false;
    }, 600);
  }

  clickOutside() {
    this.outsideClick = true;
 
    setTimeout(() => {
      this.outsideClick = false;
    }, 600);
  }

  runGuestAuth() {
    if(this.expanded || this.runGuestActive) {
      return;
    }
    this.runGuestActive = true;
    this.$gtag.event('pmb_decision_guest_click_outside');
    setTimeout(() => {
      this.$router.replace({ query: { ...this.$route.query, autoRun: 'guest' } });
    }, 200);
  }
}
