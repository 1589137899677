






























































































































































































































































































import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import reduce from "lodash/reduce";
import {ValidationErrorParserMixin} from "@/mixins/ValidationErrorParserMixin";
import CountDownTimer from "@/components/general/CountDownTimer.vue";
import {addMinutes} from "date-fns";
import {AlternateVerification, MeQuery, ResendEmailVerification, VerifyEmailWithPin} from "@/graphql/auth";
import Template from "@/components/workspace/Template.vue";
import UserService from "@/services/UserService";
import SocialAuth from "@/components/auth/SocialAuth.vue";
import { CreateFeedbackMutation } from '@/graphql/feedback';
import {feedback_types} from "@/graphql/feedback";

@Component({
  apollo: {
    feedback_types: {
      query: feedback_types,
      result(_result) {
        this.feedbackTypeId = _result.data.feedback_types[0].id;
      }
    },
  },
  components: {SocialAuth, Template, CountDownTimer}
})
export default class VerifyUser extends Mixins(ValidationErrorParserMixin) {
  clicked = false;
  verification: Array<string | null> = [null, null, null, null, null, null];
  startCounter: boolean = false;
  startDate: Date | null = null;
  endDate: Date | null = null;
  password: string | null = null;
  isAlternate: boolean = false;
  email: string | null = null;
  name: string | null = null;
  error: string | null = null;
  first_name: string | null = null;
  last_name: string | null = null;
  service: UserService | null = null;
  showAlternativeVerificationTrigger: boolean = false;
  showAlternativeVerification: boolean = false;
 
  openVerfy: boolean = false;
  specialCode: string = "000000";
  unlocked: boolean = false;

  feedbackTypeId: string = ""

  public $refs!: {
    p0: HTMLFormElement;
    p1: HTMLFormElement;
    p2: HTMLFormElement;
    p3: HTMLFormElement;
    p4: HTMLFormElement;
    p5: HTMLFormElement;
  };

  @Prop({default: false, required: false})
  claimWs!: boolean

  @Prop({default: false, required: false})
  clickedClaim!: boolean

  @Prop({default: false, required: false})
  canBypass!: boolean

  @Prop({default: true, required: false})
  signUpWithEmail!: boolean

  @Prop({default: false, required: false})
  fromCheckout!: boolean

  @Prop({default: false, required: false})
  fromPlanSelector!: boolean
  
  @Prop({default: false, required: false})
  activateFeedback!: boolean

  get feedbackDescription(): string {
    return `User: ${this.first_name || ''} ${this.last_name || ''} Email: ${this.email || ''} Total activities: ${this.activitesCreated || 0} Account created: ${this.me?.created_at || 'N/A'}`;
  }
  
  get me() {
    return this.$store.state.me;
  }

  get isContinueButtonDisablled(){

    if(!!this.first_name && !!this.last_name && this.isPasswordValid && this.isEmailValid){
      return false;
    }
    return true;
  }


  get verificationPin() {
    return reduce(
      this.verification,
      function (sum, n) {
        if (n !== null && n != "-") {
          return sum + n;
        }
        return sum;
      },
      ''
    );
  }

  get pinOk() {
    return (this.verificationPin.length == 6 || this.password);
  }

  get isPasswordValid() {
    const password = this.password ?? '';
    return /^(?=.*[A-Z])(?=.*\d).{8,}$/.test(password);
    
  }

  get showPasswordError() {
    if(!this.isPasswordValid && this.password) {
      return true;
    } else {
      return false;
    }
  }

  get isEmailValid() {
    const email = this.email ?? '';
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
  }

  get workspace() {
    return this.$store.state.workspace;
  }

  get workspaceId() {
    return this.$store.state.workspace.id;
  }

  get meId() {
    return this.$store.state.me.id;
  }

  get activitesCreated() {
    return this.workspace.totalObjectivesCount
  }

  claimLater() {
    // @ts-ignore
    this.$parent.close()
  }

  submitFeedback() {
    this.$gtag.event('pmb_user_feedback_submitted');
    this.$apollo
      .mutate({
        mutation: CreateFeedbackMutation,
        variables: {
          creator_id: this.meId,
          workspace_id: this.workspaceId,
          feedback_type_id: this.feedbackTypeId,
          title: "User reached 5 activites (and claimed account after)",
          description: this.feedbackDescription
        },
      })
      .then(() => {
        this.$gtag.event('pmb_user_plan_opt_out');
      });
  }

  sendVerificationCode() {
    this.startCounter = true;
    this.startDate = new Date();
    this.endDate = addMinutes(this.startDate, 1);
    this.isAlternate = false;
    setTimeout(() => this.showAlternativeVerificationTrigger = true, 10000);
    this.$apollo.mutate({
      mutation: ResendEmailVerification,
    }).then((res: any) => {
      if(res.data.resendEmailVerification) {
        this.$buefy.toast.open({
          message: 'Verification email sent!',
          position: 'is-bottom-right',
          type: 'is-black',
        });
      }
    }).catch((_) => {
      //
    });

    // this.service?.resendEmailVerification().then(() => {
    //   Vue.prototype.$consoleLog('Email sent')
    // }).catch(() => {
    //   this.$gtag.exception({
    //     'description': 'Registration code was not received',
    //     'fatal': false
    //   })
    // })
  }

  confirm() {
    this.clicked = true;

    const enteredCode = this.verification.join('');

    if(enteredCode === this.specialCode && process.env.NODE_ENV === 'development') {
    // Handle successful verification with special code
      this.handleSuccess();
    } else {
    // Proceed with the normal verification process
      if(this.isAlternate) {
        this.alternativeVerification();
      } else {
      // Normal verification process
        this.$apollo.mutate({
          mutation: VerifyEmailWithPin,
          variables: {
            pin: this.verificationPin,
          },
          refetchQueries: [{query: MeQuery}]
        }).then((_) => {
        // Handle successful verification
          this.handleSuccess();
        }).catch((error) => {
        // Handle verification failure
          this.parseValidationError(error)
          this.resetAndFocus();
        }).finally(() => {
          this.clicked = false;
        });
      }
    }
  }

  handleSuccess() {
  // Your logic after successful verification
    this.$gtag.event('pmb_user_claim_account_verification_success');
    this.$buefy.toast.open({
      message: 'You can now access your account',
      position: 'is-bottom-right',
      type: 'is-black',
    });
    this.$store.commit('set_claim_account_verify', false);
    this.$store.commit('set_currently_on_verification_code_step', false);
    this.$store.commit('set_currently_onboarding_in_room', false);
    this.clicked = false;
    this.$emit('account-claimed', true)

   
    if(this.activateFeedback) {
      this.submitFeedback();
    }

    //@ts-ignore
    this.$parent.close();
      
    if(!this.fromPlanSelector) {
      location.reload(true);
    }
   
    
    

    
   
    
  
  }

  // Restarts timer when account is claimed so a new 10 minute period starts before offer modal is displayed
  restartUserSessionTimer() {
    this.$store.commit('set_user_session_time', null); 
  }

  resetAndFocus() {
    this.verification = [null, null, null, null, null, null];
    this.$nextTick(() => {
      this.$refs.p0.focus();
    });
  }

  
  alternativeVerification() {
    this.$apollo.mutate({
      mutation: AlternateVerification,
      variables:{
        password: this.password
      }
    }).then((_) => {
      //@ts-ignore
      this.$parent.close();
    }).catch((error) => {
      this.parseValidationError(error)
    }).finally(() => {
      this.$store.commit('set_claim_account_verify', false);
      this.$store.commit('set_currently_on_verification_code_step', false);
      this.$store.commit('set_currently_onboarding_in_room', false);
      if(this.$store.state.user_from_ad) {
        this.$store.commit('set_show_initial_offer_notification_bar', true); 
      }
      this.clicked = false;
      location.reload(true);
      //@ts-ignore
      this.$parent.close();
      //@ts-ignore
      //this.$refs.fNameInput.focus();
    });
  }

  
  
  claimAccount() {
    this.clicked = true;
    this.service?.claimAccount(this.email, this.first_name, this.last_name, this.password).then(() => {
      this.$gtag.event('pmb_user_claimed_account_manually');
      this.$store.commit('set_auto_gen_user_data', null);
      this.$store.commit('set_currently_on_verification_code_step', true);
      
      //this.$store.commit('set_claim_account_verify', true);
      //@ts-ignore
      //this.$parent.close();
      // this.sendVerificationCode();
      this.handleSuccess();
      // if(this.clickedClaim) {
      //   this.openVerfy = true;
      //this.$events.fire('open-verification')
      // }

      this.$nextTick(() => {
        if (this.$refs.p0) {
          this.$refs.p0.focus();
        } 
      });
    },
    
    ).catch((error: any) => {
      this.parseValidationError(error);
      this.$gtag.event('pmb_user_claimed_account_failed');
    }).finally(() => {
      this.clicked = false;
    });
  }
  
  beforeDestroy() {
    this.$events.$off('open-verification')
    //@ts-ignore
    this.$parent.close();
  }


  mounted() {
    console.log("activate feedback", this.activateFeedback)
    this.$nextTick(() => {
      if (this.$refs.p0) {
        this.$refs.p0.focus();
      } 
    });
  
    if(this.me.isFake) {
      this.$store.commit('set_claim_account_verify', true);
    }else{
      this.$store.commit('set_claim_account_verify', false);
    }

    this.$events.fire('verify-modal-opened');

    this.service = new UserService(this.$apollo);
    this.$events.listen('time-ended', (_) => {
      this.startCounter = false;
    })


    if(this.$store.state.autoGenUserData) {
      this.email = (this.$store.state.autoGenUserData.email != '') ? this.$store.state.autoGenUserData.email : null;
      this.first_name = (this.$store.state.autoGenUserData.first_name != '') ? this.$store.state.autoGenUserData.first_name : null;
      this.last_name = (this.$store.state.autoGenUserData.last_name != '') ? this.$store.state.autoGenUserData.last_name : null;
    }

    if(this.$store.state.currently_on_verification_code_step) {
      this.showAlternativeVerificationTrigger = true;
    }
  }

  @Watch('verification')
  watchVerificationCode(newValue: string[]) {
    if (this.verificationPin && this.verificationPin.length > 6) {
      let verify = this.verificationPin;
      this.$nextTick(() => {
        verify = verify.replace('-', '');
        if (verify.length > 6) {
          verify = verify.slice(0, -1)
        }
        this.verification = [...verify];
      })
    }
    const isComplete = newValue.every(v => v !== null && v !== '');
    if (isComplete) {
      this.confirm();
    }
  }
}
