










































































































































































































import gql from 'graphql-tag';
import {Component, Vue} from 'vue-property-decorator';
import {CreateReactionMutation, RemoveReactionMutation} from "@/graphql/reaction";
import {
  BoardMessageDetailedFragment,
  BoardMessagesQuery,
  BoardQuery,
  PinBoardMessage,
  UnPinBoardMessage
} from '@/graphql/board';
import formatDistance from "date-fns/formatDistance";
import UserAvatar from '@/components/user/UserAvatar.vue';
import ConfirmDelete from "@/components/general/ConfirmDelete.vue";
import UrlPreview from "@/components/chat/UrlPreview.vue";
import {Reaction} from "@/typescript/types";
import Template from "@/components/templates/Template.vue";
import {WorkspaceQuery} from "@/graphql/workspace";
import {BoardAttachments} from "@/graphql/attachment";

@Component({
  components: {Template, UrlPreview, ConfirmDelete, UserAvatar},
  props: {
    chatMessageId: {},
    chatMessage: {},
    chatSenderName: {},
    chatSenderInitials: {},
    chatSenderAvatar: {},
    chatSenderId: {},
    chatSenderIsMe: {},
    messageCreatedAt: {},
    trailingTop: {},
    trailingBtm: {},
    newDay: {},
    reactionSummaries: {},
    hasSeparator: {},
    index: {},
    reactionData: {},
    attachments: {},
    pinnedUsers: {},
    isEdited: {},
    linkedBoard: {},
    fromPinnedMessage:{}
  },
})
export default class ChatMessage extends Vue {
  isHovered = false;
  expanded: boolean = false;
  hasLinkPreview = false;
  detectedUrls: Array<any> = [];
  whoReactedText: string | null = null;
  isHidden: boolean | null = null;
  emojis: Array<{ icon: string, name: string }> = [
    {icon: '👍', name: 'like'},
    {icon: '👎', name: 'dislike'},
    {icon: '👏', name: 'clap'},
    {icon: '🙏', name: 'pray'},
    {icon: '😄', name: 'smile'},
    {icon: '😢', name: 'sad'},
    {icon: '❤️️', name: 'love'}
  ];
  disableEmoji: boolean = false;

  mounted() {
    this.checkUrl();
    this.$events.fire('messageloded', this.$props.chatMessageId);
  }

  get board() {
    return this.$store.state.board;
  }

  get workspace() {
    return this.$store.state.workspace;
  }

  get me() {
    return this.$store.state.me;
  }

  get activeWorkspace() {
    return this.$store.state.workspace;
  }

  get myReactions() {
    return this.$props.reactionData.filter((reaction: Reaction) => {
      return reaction.reacter.id === this.me.id;
    });
  }

  get isPinned(){
    let index = this.$props.pinnedUsers.findIndex((user: any) => user.id === this.me.id);
    if(index != -1){
      return true;
    }
    return false;
  }

  get myReactedEmojis() {
    let reactions = this.myReactions;
    let emojis = reactions.map((react: Reaction) => {
      return react.reaction;
    });
    return emojis;
  }

  get reactedText() {
    let text = this.whoReactedText?.replace(this.me.name, 'You');
    return text?.replace(/,(?=[^,]*$)/, ' and ');
  }

  checkIfUserBlockedFromWorkspace(userId: string) {
    let member = this.workspace.allMembers.filter((member: any) => {
      return member.user.id === userId;
    });

    if (member.length > 0 && member[0].access_workspace == 0) {
      return false;
    } else {
      return true;
    }
  }

  previewLoaded() {
    
    this.$emit('preview-loaded', true);
  }

  isMeReacter(emoji: string): boolean {
    let isMe = this.reactorsWithEmoji(emoji).filter((e: any) => e.id === this.me.id);
    if (isMe.length > 0) {
      return true;
    } else {
      return false
    }
  }

  reactedEmoji(emoji: string) {
    return this.emojis.find((em: any) => {
      return em.name === emoji;
    });
  }

  reactorsWithEmoji(emoji: string): Array<String> {
    let reacters = this.$props.reactionData.map((reacter: Reaction) => {
      if (reacter.reaction != emoji) {
        return;
      }
      return reacter.reacter;
    });
    return reacters.filter((rec: string) => {
      return rec !== undefined;
    });
  }

  getNamesWhoReacted(emoji: string): void {
    let names = this.reactorsWithEmoji(emoji).map((reactername: any) => {
      return reactername.name;
    })
    this.whoReactedText = names.join();
  }

  isReactedEmojis(emoji: string): boolean {
    return this.myReactedEmojis.includes(emoji);
  }


  react(reaction: string, id: string) {
    let randId = Math.random().toString(36).substring(7);
    this.$emit('new-reaction', {
      id: randId,
      created_at: new Date(),
      reaction: reaction,
      reactions : {
        avatar: null,
        id: this.me.id,
        initials: this.me.initials,
        name: this.me.name,
        __typename: "User"
      },
      __typename: "Reaction",
    }, {messageId: this.$props.chatMessageId});

    this.$apollo
      .mutate({
        mutation: CreateReactionMutation,
        variables: {
          reactable_type: 'App\\Models\\BoardMessage',
          reactable_id: id,
          reaction: reaction
        },
        refetchQueries: [{
          query: BoardMessagesQuery,
          variables: {
            id: (this.board) ? this.board.id : this.$props.linkedBoard.id
          }
        }],
      })
  }

  forHumans(d: Date) {
    return formatDistance(new Date(d), new Date());
  }

  checkUrl() {
    const string = this.$props.chatMessage;
    const regex = /\bhttps?:\/\/\S+/gi;
    let m;

    while ((m = regex.exec(string)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }

      // The result can be accessed through the `m`-variable. and we can add multiple link preview
      m.forEach((match: any) => {
        this.hasLinkPreview = true;
        this.detectedUrls.push(match);
      });
    }
  }

  deleteBoardMessage(messageId: string) {
    this.isHidden = true;

    this.$apollo
      .mutate({
        mutation: gql`
                    mutation deleteBoardMessage($id: ID!) {
                        deleteBoardMessage(id: $id) {
                            ...BoardMessageDetailed
                        }
                    }
                    ${BoardMessageDetailedFragment}
                `,
        variables: {
          id: messageId,
        },
        refetchQueries: [{query: BoardMessagesQuery, variables: {id: (this.board) ? this.board.id : this.$props.linkedBoard.id}}, {query: BoardQuery, variables:{pid: (this.board) ? this.board.pid : this.$props.linkedBoard.pid}}, {query: WorkspaceQuery, variables:{pid: this.activeWorkspace.pid}},{query: BoardAttachments, variables:{board_id: this.board.id}}],
      }).then((_) => {
        this.$emit('board-message-deleted', messageId);
      }).catch((_) => {
        
      })
      .finally(() => {
        this.$buefy.toast.open({
          message: 'Message deleted',
          position: 'is-top-right',
          type: 'is-black',
        });
      });
  }

  openDeleteModal() {
    this.$buefy.modal.open({
      component: ConfirmDelete,
      props: {
        deleteItemName: 'Are you sure you want to delete this message?'
      },
      events: {
        //@ts-ignore ToDo: fix type issue
        'confirmed': value => {
          if (value === true) {
            this.deleteBoardMessage(this.$props.chatMessageId);
          }
        }
      },
      width: '500px',
      parent: this,
    });
  }

  removeReaction(emoji: string) {
    let myReaction = this.myReactions;
    let data = myReaction.find((react: Reaction) => {
      return react.reaction === emoji;
    });

    if (!data) {
      return;
    }

    this.$apollo
      .mutate({
        mutation: RemoveReactionMutation,
        variables: {
          id: data.id
        },
        refetchQueries: [{
          query: BoardMessagesQuery,
          variables: {
            id: (this.board) ? this.board.id : this.$props.linkedBoard.id
          }
        }],
      })
  }

  isRawFile(file: any){
    if('type' in file){
      return true;
    }
    return false;
  }

  getAttachment(fileName: string, folder: string) {
    //@ts-ignore
    return this.$attachmentsLinks.file_path[folder] + fileName;
  }

  getRawAttachment(file: any){
    return URL.createObjectURL(file);
  }

  async downloadFile(url: string,name: string){
    let blob = await fetch(url).then(r => r.blob());
    var fileURL = window.URL.createObjectURL(blob);
    var fURL = document.createElement('a');

    fURL.href = fileURL;
    fURL.setAttribute('download', name);
    document.body.appendChild(fURL);

    fURL.click();
  }

  getType(file: any){
    return (this.isRawFile(file)) ? file.type : file.file_type;
  }

  getImgUrl(type: any, file: any){

    switch (type){
      case 'image/png' :
        return {url: this.getRawAttachment(file), fileName: file.name};
      case 'image/jpg' :
        return {url: this.getRawAttachment(file), fileName: file.name};
      case 'image/jpeg':
        return {url: this.getRawAttachment(file), fileName: file.name};
      case 'audio/webm':
        return {url: this.getRawAttachment(file), fileName: file.name};
      case 'png' :
        return {url: this.getAttachment(file.file_name, 'attachments'), fileName: file.file_name};
      case 'jpg' :
        return {url: this.getAttachment(file.file_name, 'attachments'), fileName: file.file_name};
      case 'jpeg':
        return {url: this.getAttachment(file.file_name, 'attachments'), fileName: file.file_name};
      case 'webm':
        return {url: this.getAttachment(file.file_name, 'attachments'), fileName: file.file_name};



    }
  }

  pinMessage(messageId: string){

    this.$apollo.mutate({
      mutation: PinBoardMessage,
      variables:{
        id: messageId
      },
      refetchQueries:[{query: WorkspaceQuery, variables:{id: this.activeWorkspace.id}}]
    })
      .catch((_) => {
       
      })
      .then((result: any) => {
        
        this.$props.pinnedUsers.push(result.data.pinMessage);
        this.$buefy.toast.open({
          message: 'Message pinned',
          position: 'is-top-right',
          type: 'is-black',
        });
      });
  }

  unPinMessage(messageId: string) {

    this.$apollo.mutate({
      mutation: UnPinBoardMessage,
      variables:{
        id: messageId
      },
      refetchQueries:[{query: WorkspaceQuery, variables:{id: this.activeWorkspace.id}}]
    })
      .catch((_) => {
        
      })
      .finally(() => {
        let index = this.$props.pinnedUsers.findIndex((user: any) => user.id === this.me.id);
        if(index != -1){
          this.$props.pinnedUsers.splice(index, 1);
        }
        this.$buefy.toast.open({
          message: 'Message unpinned',
          position: 'is-top-right',
          type: 'is-black',
        });
      });
  }

  editMessage(messageId: string, chat: any) {
    this.$events.fire('edit-message', {'messageId': messageId, 'message': chat});
  }


}
