




















































































































































































































































































































































































































































































































































































import {Component, Vue} from 'vue-property-decorator';
import NorthStarService from "../../NorthStarService"
import {NorthStar, Objective as ObjectiveObject, Objective} from "../../typescript/types";
import CreateObjectiveTrigger from "../../components/objectives/CreateObjectiveTrigger.vue";
import { endOfWeek } from "date-fns";
import gql from "graphql-tag";
import {ObjectiveDetailedFragment, UpdateObjective} from "../../graphql/objective";
import VerifyUser from "../../components/auth/VerifyUser.vue";
import ConfettiCanon from "../../components/general/ConfettiCanon.vue";
import { PublicBoardTemplates } from '../../graphql/boardTemplate';
import {PublicBoardTemplatePaginator} from "@/typescript/types";
import { SingleNorthStar } from '@/graphql/NorthStar';

//@ts-ignore
import image1 from '../../assets/images/AddGoalBg1.png';
//@ts-ignore
import image2 from '../../assets/images/goalbg3.webp';
//@ts-ignore
import image3 from '../../assets/images/AddGoalBg2.jpg';
//@ts-ignore
import image4 from '../../assets/images/cgb1.webp';
//@ts-ignore
import image5 from '../../assets/images/cbg2.webp';

@Component({
  apollo: {
    PublicBoardTemplates: {
      query: PublicBoardTemplates,
      variables() {
        return {
          page: this.publicBoardTemplatesPageCount
        }

      },
      result(result) {
        console.log(result)
        if (result && result.data && result.data.PublicBoardTemplates && this.PublicBoardTemplatesData && this.PublicBoardTemplatesData.data.length > 0) {
          // @ts-ignore
          this.PublicBoardTemplatesData.data  = this.filterDuplicateData([...this.PublicBoardTemplatesData.data, ...result.data.PublicBoardTemplates.data]);
          this.PublicBoardTemplatesData.paginatorInfo = result.data.PublicBoardTemplates.paginatorInfo;

        }else{
          this.PublicBoardTemplatesData = result.data.PublicBoardTemplates;
        }
      },
    },
  },
  components: {ConfettiCanon, CreateObjectiveTrigger},
})
export default class AddGoalsAndActivites extends Vue {
  createNorthStarInput: string | null = null;
  createActivityInput: string | null = null;
  submitted: boolean = false;
  northstarsAddedCount: number = 0;
  service: NorthStarService | null = null;
  addedNorthStars: NorthStar[] = [];
  addedObjectives: Objective[] = [];
  addActivitiesActive: boolean = false;
  lastAddedNorthstar: NorthStar | null = null;
  assignedPriority: string = 'normal';
  introductionActive: boolean = false;
  addNorthstarManuallyActive: boolean = true;
  publicBoardTemplatesPageCount: number = 1;
  minimize: boolean = true;
  PublicBoardTemplatesData: PublicBoardTemplatePaginator | null = null;
  selectedGoalSuggestion: any | null = null;
  highlightButton: boolean = false;
  highlightCustomizeButton: boolean = false;
  suggestionWait: boolean = false;
  delayedComplete: boolean = false;
  randomBackground: string = '';
  selectedTemplateId: string = '';
  selectedGoalId: string = '';
  templatePreviewActive: boolean = false;
  northstarData: NorthStar | null = null;
  showTemplates: boolean = false;
  newAddedNorthStars: NorthStar[] = [];
  startDate: Date = new Date();
  dueDate: Date | null = null;
  northstarId: string = "";
  fireConfetti: boolean = false;
  showColorPicker: boolean = false;
  finalColor: string = '';
  allowSwitchStatus: boolean = true;
  activeGoal: Object | null = null;
  selectedColor: string = '#773f75'
  selctedColorAdjusted: string | null = null;
  addedBoard: string | null = null;
  selectedSuggestions: Array<string> = [];
  selectedObjectives: Array<string> = [];
  customizeLoad: boolean = false;
  manualAddLoading: boolean = false;

  colors: Array<string> = [
    '#773f75',
    '#64024C',
    '#820263',
    '#bd5fb4',
    '#5B507A',
    '#2E294E',
    '#374ea9',
    '#4a677d',
    '#4e6068',
    '#66737a',
    '#717171',
    '#434343',
    '#1F4730',
    '#316863',
    '#2e825f',
    '#546356',
    '#642B2C',
    '#654C4F',
    '#776015',
    '#7b7343',
  ];

  placeHolderColors: Array<string> = [
    '#754373',
    '#4a677d',
    '#7b7343',
  ];

  get me() {
    return this.$store.state.me;
  }

  get workspace() {
    return this.$store.state.workspace;
  }


  get onboardingUseCase() {
    return this.$store.state.onboarding_use_case;
  }


  get addedNorthstarsCount() {
    return this.addedNorthStars.length;
  }

  get recommendedNsCountLeft() {
    return 3 - this.addedNorthstarsCount;
  }

  get addedActivitiesCount() {
    return this.addedObjectives.length;
  }

  get recommendedActivitiesCountLeft() {
    return 3 - this.addedActivitiesCount;
  }

  get isOnGuestAccount() {
    return this.$store.state.me.isFake;
  }

  get isFromAd() {
    return this.$store.state.user_from_ad
  }

  get endDate() {
    return endOfWeek(new Date(), {weekStartsOn: 1});
  }

  get board() {
    return this.$store.state.board
  }

  get suggestColorFromNsCount() {
    let count = this.addedNorthStars.length;
    if(count === 0) {
      return '#773f75';
    } else if(count === 1) {
      return '#4a677d';
    } else if(count === 2) {
      return '#7b7343';
    } else {
      return null;
    }
  }

  selectedTemplate(id: string) {
    this.selectedTemplateId = id;
    this.templatePreviewActive = true;

    this.$apollo.query({
      query: SingleNorthStar,
      variables: {
        goal_id: id
      }
    }).then((result: any) => {
      if(result) {
        this.northstarData = result.data.getNorthStar;
        console.log("northstar data: " + this.northstarData)
      }
    })
  }

  colorSelected(color: string) {
    if (color === 'No color') {
      this.selectedColor = '';
    } else {
      this.selectedColor = color;
      this.selctedColorAdjusted = this.selectedColor;
    }
  }

  highlightCorrectActivityButton() {
    this.$nextTick(function () {
      // @ts-ignore
      this.$refs.createActivityInput.focus();
    });
  }

  bounceInput() {
    this.highlightButton = true;
    setTimeout(() => this.highlightButton = false, 1000);

    this.$nextTick(function () {
      if(this.$refs.createActivityInput) {    
        // @ts-ignore
        this.$refs.createActivityInput.focus();
      } else if (this.$refs.createNsInput) {  
        // @ts-ignore
        this.$refs.createNsInput.focus();
      }
    });
  }

  bounceCustomizeButton() {
    this.highlightCustomizeButton = true;
    setTimeout(() => this.highlightCustomizeButton = false, 1000);
  }

  focusOnManuallyAddNorthstar() {
    this.addNorthstarManuallyActive = true;
    this.$nextTick(function () {
      // @ts-ignore
      this.$refs.createNsInput.focus();
    });
  }

  createOrAddNorthStar(manually: boolean | null): void {
    this.hideTemplates();
    if(manually) {
      this.$gtag.event('pmb_new_app_activation_northstar_added_manually');
    }
    this.$gtag.event('pmb_new_app_activation_northstar_added');
    this.submitted = true;
    let startDate = new Date();
    
    let finalColor = this.selectedColor;

    this.manualAddLoading = true;
    if (!finalColor && this.colors.length > 0) {
      finalColor = this.colors[Math.floor(Math.random() * this.colors.length)];
    }

    if (this.$store.state.board) {
        this.service?.createNorthStar(this.$store.state.workspace, this.$store.state.board, this.createNorthStarInput!, startDate, finalColor).then((result: any) => {
          this.$store.commit('set_newly_created_northstar_count', (this.$store.state.newlyCreatedNorthstarsCountInWorkspace == null) ? 1 : this.$store.state.newlyCreatedNorthstarsCountInWorkspace + 1)
          this.$store.commit('set_newly_created_northstar_in_board', result.data.createNorthStar.id);
          this.$gtag.event('pembio_activation_create_north_star');
          this.$events.fire('new_northstar_created', result.data.createNorthStar)
          this.lastAddedNorthstar = result.data.createNorthStar;
          this.addedNorthStars.push(result.data.createNorthStar);
          if(this.addedNorthstarsCount >= 1) {
            this.jumpToActivitiesStep();
          }
        })
    }
    this.$nextTick(function () {
      // @ts-ignore
      this.$refs.createNsInput.focus();
    });
    this.createNorthStarInput = null;
    this.$emit('northstar-added', true);
  }
  
  copyNorthstar(northstar_id: string) {
    this.$gtag.event('pmb_create_ns_from_templates');
    this.customizeLoad = true
    if(this.ifAlreadySelected(northstar_id)) {
      return
    }
    if(this.$store.state.board) {
      this.addedBoard = this.$store.state.board.id;
    }
    if(this.selectedSuggestions.length == 0 || this.addedBoard != null) {
      this.selectedSuggestions.push(northstar_id);
      
        this.service?.copyNorthStar(northstar_id, this.addedBoard, this.workspace.id).then((res: any) => {
          this.jumpToActivitiesStep();
          if(res.data.copyNorthStar && res.data.copyNorthStar.boards.length > 0) {
            this.newAddedNorthStars = res.data.copyNorthStar;
            this.lastAddedNorthstar = res.data.copyNorthStar;
            this.northstarId = res.data.copyNorthStar.id;
            this.addedBoard = res.data.copyNorthStar.boards[0].id;
            this.$store.commit('set_newly_created_northstar_count', (this.$store.state.newlyCreatedNorthstarsCountInWorkspace == null) ? 1 : this.$store.state.newlyCreatedNorthstarsCountInWorkspace + 1)
            this.$events.fire('northstars-updated-my-northstar', res.data.copyNorthStar);
          }
        })
    }
  }

  copyObjective(objective_id: string,  northstar_id: string) {
    this.$gtag.event('pembio_create_objective_from_suggestion');
    let boardId;
    if(this.$store.state.board) {
      boardId = this.$store.state.board.id;
    } else if(this.northstarData && this.northstarData.boards && this.northstarData.boards.length >= 1) {
      boardId = this.northstarData.boards[0].id
    } else {
      boardId = undefined;
    }
    if(boardId) {
      this.selectedObjectives.push(objective_id);
        this.service?.copyObjectiveForNorthStar(northstar_id, objective_id, boardId).then((res: any) => {
          this.$events.fire('objective-created-from-northstar', {objective: res.data.copyObjectiveForNorthstar, northStarId: northstar_id });
          //console.log('objective data' + res.data.copyObjectiveForNorthstar.id)
          this.setDateToCopiedObjective(res.data.copyObjectiveForNorthstar.id);
          console.log('objective: ' + res)
        });
    }
  }

  ifAlreadySelected(objectiveId: string) {
    return this.selectedObjectives.includes(objectiveId);
  }

  setDateToCopiedObjective(id: string) {
    
    this.$apollo.mutate({
      mutation: UpdateObjective,
      variables: {
        id: id,
        start_date: this.startDate,
        date: this.dueDate
      }
    })
  }


  mounted() {
    this.service = new NorthStarService(this.$apollo);
    
    if (this.addNorthstarManuallyActive) {
      this.$nextTick(() => {
        if (this.$refs.createNsInput) {
          //@ts-ignore
          this.$refs.createNsInput.focus();
        }
      });
    }
  }
 
  // beforeMount() {
  //   this.randomizeBackground()
  // }

  // randomizeBackground() {
  //   const images = [image1, image2, image3, image4, image5];
  //   const selectedImage = images[Math.floor(Math.random() * images.length)];
  //   this.$set(this, 'randomBackground', selectedImage);
  // }

  handleDoneButton() {
    this.fireConfetti = true;
    setTimeout(() => {
      this.closeModal();
    }, 1000);
  }

  closeModal() {
    // @ts-ignore
    this.$parent.close() 
  }

  publicBoardTemplatesLoadMore() {
    this.publicBoardTemplatesPageCount++;
    this.$apollo.queries.PublicBoardTemplates.refetch();
  }

  addActivityFromSuggestion(activity: any) {
    if(this.suggestionWait) {
      return
    }
    this.allowSwitchStatus = false;
    this.suggestionWait = true;
    setTimeout(() => this.suggestionWait = false, 500);
    this.$gtag.event('pmb_new_app_activation_add_activity_from_suggestion');
    this.createActivityInput = activity;
    this.createObjectiveMutation(false);

  }

  claimAccount() {
    this.$gtag.event('pmb_user_claim_account_manually_initiated_sidebar');
    this.$buefy.modal.open({
      component: VerifyUser,
      props: {
        claimWs: (this.me.isFake) ?? false,
        clickedClaim: true,
        canBypass: true
      },
      width: '500px',
      canCancel: false,
      parent: this,
    });
  }

  handleAddActivity(manually: boolean) {
    if ( this.allowSwitchStatus) {
      this.allowSwitchStatus = false;
      this.createObjectiveMutation(manually);
      
      setTimeout(() => {
        this.allowSwitchStatus = true;
      }, 900);
    }
  }

  createObjectiveMutation(manually: boolean | null) {
    if(manually) {
      this.$gtag.event('pmb_new_app_activation_objective_added_manually');
      this.$gtag.event('pembio_create_objective');
    }
    this.$gtag.event('pmb_new_app_activation_objective_added');
    return this.$apollo
      .mutate({
        mutation: gql`
        mutation createObjective(
        $owner_id: ID!
        $name: String!
        $board_id: ID!
        $start_date: DateTime
        $due_date: DateTime
        $priority: ObjectivePriority
        $background_color: String
        ) {
        createObjective(
        input: {
        owner: { connect: $owner_id }
        board: { connect: $board_id }
        name: $name
        start_date: $start_date
        due_date: $due_date
        priority: $priority
        background_color: $background_color
        }
        ) {
        ...ObjectiveDetailed
        }
        }
        ${ObjectiveDetailedFragment}
        `,
        variables: {
          owner_id: this.$store.state.me.id,
          name: this.createActivityInput,
          board_id: this.$store.state.board.id,
          start_date: new Date(),
          end_date: null,
          due_date: this.endDate,
          priority: this.assignedPriority,
        }
      })
      .then((result) => {
        if(this.lastAddedNorthstar){
          this.attachNorthStar(result.data.createObjective, this.lastAddedNorthstar);
          result.data.createObjective['from_northstar'] = true;
        }else{
          this.$events.fire('objective-created', result.data.createObjective);
        }

        this.addedObjectives.push(result.data.createObjective);
        this.$emit('submitted', true);
        this.$emit('objective-created-from-sprint', result.data.createObjective);
      })
      .finally(() => {
        this.createActivityInput = null;
        this.allowSwitchStatus = true;
      });
  }


  attachNorthStar(objective: ObjectiveObject, northStar: NorthStar){
        this.service?.addNorthStarToObjective(objective, [northStar]).then(res => {
          this.$events.fire('objective-created-from-northstar', {objective: res.data.updateObjective, northStarId: northStar.id });
          return;
        });
  }

  jumpToActivitiesStep() {
    this.addActivitiesActive = true;
    this.$nextTick(function () {
      // @ts-ignore
      this.$refs.createActivityInput.focus();
    });
  }

  hideTemplates() {
    this.templatePreviewActive = false
    
  }

  displayColors() {
    this.showColorPicker = true
  }

}
