








































































  
import {Component, Vue} from 'vue-property-decorator';
import VerifyUser from "@/components/auth/VerifyUser.vue";
  
@Component({
  components: {},
})
export default class FirstOffer extends Vue {
  loading: boolean = false;
  productId: string = '8aa3b033-8134-4532-b5ac-e4bf98b561c8';
  
  get onGuestAccount() {
    return this.$store.state.me && this.$store.state.me.isFake;
  }
  
  claimAccount() {
  
    this.$buefy.modal.open({
      component: VerifyUser,
      props: {
        claimWs: (this.$store.state.me.isFake) ?? false,
        clickedClaim: true
      },
      width: '500px',
      canCancel: !this.$store.state.currently_on_verification_code_step,
      parent: this,
    });
  }
  
  goToCheckout() {
    // this.$store.commit('set_coupon_code', "EARLYBIRD20");
    this.loading = true;
    setTimeout(() => this.routeToCheckout(), 1000);
  }
  
  routeToCheckout() {
    this.$router.push({name: 'checkout-flow' });
    //@ts-ignore
    this.$parent.close();
  }
  
  mounted() {
    this.$gtag.event('pmb_app_soft_block_mounted');
  }
  
}
