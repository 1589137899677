














































































import { Component, Vue } from "vue-property-decorator";
import {MeQuery, SocialLoginMutation, UpdateUserAccountType} from "@/graphql/auth";
import {CreateWorkspaceMutation, HaveWorkspace} from "@/graphql/workspace";
import PusherService from "@/PusherService";
import { ThirdPartyAuth } from "@/graphql/ThirdPartyProvider";
import {addSeconds} from "date-fns";
import Template from "@/components/templates/Template.vue";
import RegistrationSteps from "@/components/auth/RegistrationSteps.vue";
import VerifyUser from "./VerifyUser.vue";


@Component({
  components: {RegistrationSteps, Template, VerifyUser}
})
export default class OauthCallback extends Vue {
  url: string | null = null;
  network: string | null = null;
  code: string | null = null;
  currentIpAddress: string | null = null;
  loginIssue: boolean = false;
  claimIssue: boolean = false;
  userType: boolean = false;
  nameWorkspace: boolean = false;
  userAccountType: string | null = null;
  workspaceName: string | null = null;
  isLoading: boolean = false;
  referralCode = (this.$store.state.autoGenUserData && this.$store.state.autoGenUserData.referral_code)  ? this.$store.state.autoGenUserData.referral_code : null

  templateId = (this.$store.state.autoGenUserData && this.$store.state.autoGenUserData.template_id) ? this.$store.state.autoGenUserData.template_id : null



  get me() {
    return this.$store.state.me;
  }

  get workspace() {
    return this.$store.state.workspace;
  }

  checkWorkspace(): void {
    this.$apollo
      .query({
        query: HaveWorkspace,
      })
      .then((response) => {
        if (response.data.haveWorkspace.present) {

          PusherService.pusherAuth();
          if(this.$store.state.autoGenUserData && this.$store.state.autoGenUserData.template_id == '') {
            this.$store.commit('set_auto_gen_user_data', null);
          }else{
            this.$store.commit('set_is_social_login_with_template', true);
          }
          this.$router.push({name: "workspace-layout"})

        } else {
          this.userType = true;
          this.$router.push({name: "registration-steps"})
          //this.createWorkspace();
        }
      });
  }


  createWorkspace() {
    this.isLoading = true;
    let referralCode = '';
    let templateId = '';
    if(this.$store.state.autoGenUserData && this.$store.state.autoGenUserData.referral_code) {
      referralCode = this.$store.state.autoGenUserData.referral_code
    }

    if(this.$store.state.autoGenUserData && this.$store.state.autoGenUserData.template_id) {
      templateId = this.$store.state.autoGenUserData.template_id
    }
    this.$apollo.mutate({
      mutation: CreateWorkspaceMutation,
      variables: {
        name: this.workspaceName,
        domain: this.workspaceName,
        referral_code: referralCode,
        template_id: templateId
      }
    }).then(({ data: { createWorkspace: workspace } }) => {
      this.isLoading = false;
      this.$store.commit('set_is_social_login_with_template', false);
      this.$store.commit('set_workspace', workspace);
      this.$store.commit('set_active_workspace_pid', workspace.pid);
      this.$store.commit('set_active_workspace_access', workspace.my_access);
      this.$store.commit('set_currently_onboarding', true);
      this.$router.push({name: 'workspace-layout', params: { workspace_pid: this.$store.state.workspace.pid } })
      // if(this.$store.state.autoGenUserData && this.$store.state.autoGenUserData.template_id != '') {
      //   this.$router.push({name: "onboarding-view", params: {workspace_pid: workspace.pid, board_pid: workspace.boards[0].pid}})
      // }else{
      //   this.$router.push({name: 'create-board', params: { workspace_pid: workspace.pid } })
      // }

    })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        //this.clicked = false;
      });

  }

  updateUserAccountType() {

    this.$apollo
      .mutate({
        mutation: UpdateUserAccountType,
        variables: {
          account_type: this.userAccountType
        },
      })
      .then((_data: any) => {
     
        this.userType = false;
        this.nameWorkspace = true;
        //this.createWorkspace();
      })
      .catch((e: any) => {
        Vue.prototype.$consoleLog(e)
      })

  }

  socialAuth(){

    this.waitForSocialAuth(10000)

    //@ts-ignore
    this.$ipaddress.getIpAddress().then((res: string)=>{
      this.currentIpAddress = res;
      this.waitForSocialAuth(10000);
      this.$apollo
        .mutate({
          mutation: SocialLoginMutation,
          variables: {
            token: this.$route.query.code,
            provider: this.network,
            ipAddress: this.currentIpAddress,
          },
        })
        .then(({ data: { socialLogin } }) => {

          this.$store.commit("logged_in", socialLogin.access_token);
          if(this.$store.state.user_from_ad) {
            this.$store.commit('set_show_initial_offer', true);
            this.$store.commit('set_user_session_start', null); 
            this.$store.commit('set_user_session_time', null); 
          }     
          this.$events.fire('auto-gen');
          this.checkWorkspace();


        })
        .catch((_error) => {
          this.handleLoginIssue()
          // this.redirectToGuest();
        })
        .finally(() => {
          //
        });
    });

  }

  socialAuthClaim(){

    this.waitForSocialAuthClaim(10000)

    //@ts-ignore
    this.$ipaddress.getIpAddress().then((res: string)=>{
      this.currentIpAddress = res;
      this.$apollo
        .mutate({
          mutation: SocialLoginMutation,
          variables: {
            token: this.$route.query.code,
            provider: this.network,
            ipAddress: this.currentIpAddress,
            isClaimAccount: (this.me && this.me.isFake) ? true : null,
            claimAccountUserId: (this.me) ? this.me.id : null
          },
          awaitRefetchQueries: true,
          refetchQueries: [{query: MeQuery}]
        })
        .then(({ data: { _socialLogin } }) => {
          this.$gtag.event('auth_claim_success');

          if(this.me.isFake) {
            this.$buefy.toast.open({
              message: 'The email you claimed is already registered please logout and login with the same email.',
              position: 'is-bottom-right',
              type: 'is-black',
            });
            this.$store.commit('set_claim_account_verify', false);
            PusherService.pusherAuth();
            this.$router.push({ name: "dashboard" });
          }

          if(this.$store.state.isOnClaimAccountVerify) {
            this.$store.commit('set_claim_account_verify', false);
            this.$router.push({name: 'overview'})
          }
        })
        .catch((_error) => {
          this.$gtag.event('auth_claim_catch');
          
          if(_error.graphQLErrors[0].message) {
            Vue.prototype.$consoleLog(_error.graphQLErrors[0].message)
          }
          this.handleClaimIssue();
          
          // this.$buefy.toast.open({
          //   message: 'We are experiencing some issue with social login. Please try after sometime or lauch the app with with demo account.',
          //   position: 'is-bottom-right',
          //   type: 'is-danger',
          // });
          // this.redirectToGuest();
        })
        .finally(() => {
          //
        });
    });

  }

  waitForSocialAuth(sec: number) {
    setTimeout(() => {
      if(!this.$store.state.token) {
        this.handleLoginIssue();
      }
    }, sec)
  }

  

  waitForSocialAuthClaim(sec: number) {
    setTimeout(() => {
      if(!this.$store.state.token) {
        this.handleClaimIssue();
      }
    }, sec)
  }

  handleLoginIssue(){
    if(!this.claimIssue) {
      this.loginIssue = true;
    }
  }

  handleClaimIssue(){
    if(!this.loginIssue) {
      this.claimIssue = true;
    }
  }

  logoutAndRedirectToLogin() {
    this.$router.push({ name: 'logout' });
  }

  redirectToGuest() {
    this.$router.push({ name: 'register-layout', query: { network: 'guest' }});
  }

  thirdPartyAuth(provider: String){
    this.$apollo
      .mutate({
        mutation: ThirdPartyAuth,
        variables: {
          token: this.$route.query.code,
          provider: provider,
          codeVarifier: ''
        },
      })
      .then((data: any) => {
        if(data){
          switch(provider){
            case 'asana':
              this.$store.commit('set_asana_token', data.data.thirdPartyAuth.access_token);
              this.$store.commit('set_asana_token_expired', addSeconds(new Date(), data.data.thirdPartyAuth.expires_in));
              break;
            case 'google-calendar':
              this.$store.commit('set_google_token', data.data.thirdPartyAuth.access_token);
              this.$store.commit('set_google_token_expired', addSeconds(new Date(), data.data.thirdPartyAuth.expires_in));
              break;
            case 'monday':
              this.$store.commit('set_monday_token', data.data.thirdPartyAuth.access_token);
              break;
            case 'todoist':
              this.$store.commit('set_todoist_token', data.data.thirdPartyAuth.access_token);
              break;
            case 'clickup':
              this.$store.commit('set_clickup_token', data.data.thirdPartyAuth.access_token);
              break;
            case 'wrike':
              this.$store.commit('set_wrike_token', data.data.thirdPartyAuth.access_token);
              this.$store.commit('set_wrike_token_expired', addSeconds(new Date(), data.data.thirdPartyAuth.expires_in));
              break;
          }
          window.close();
        }

      })
      .finally(() => {
        //
      });
  }

  beforeMount() {
    if (this.$route.query.code) {
      switch (this.$route.params.network) {
        case "google":
          this.network = "google";
          if(this.me){
            this.socialAuthClaim();
          }else{
            this.socialAuth();
          }
          break;
        case "linkedin":
          this.network = "linkedin";
          if(this.me){
            this.socialAuthClaim();
          }else{
            this.socialAuth();
          }
          break;
        case "facebook":
          this.network = "facebook";
          if(this.me){
            this.socialAuthClaim();
          }else{
            this.socialAuth();
          }
          break;
        case "asana":
          this.thirdPartyAuth('asana');
          break;
        case "google-calendar":
          this.thirdPartyAuth('google-calendar');
          break;
        case "monday":
          this.thirdPartyAuth('monday');
          break;
        case "todoist":
          this.thirdPartyAuth('todoist');
          break;
        case "wrike":
          this.thirdPartyAuth('wrike');
          break;
        default:
          this.network = "error";
      }


    }
  }

  openClaimWithEmail() {
    this.$router.push({ name: 'workspace-layout' });
    this.$nextTick(function () {
      this.$buefy.modal.open({
        component: VerifyUser,
        props: {
          claimWs: (this.$store.state.me.isFake) ?? false,
          clickedClaim: true,
          signUpWithEmail: true
        },
        width: '500px',
        canCancel: true,
        parent: this,
      });
    });
  }
}


