










import {Component, Vue} from 'vue-property-decorator';
import Planner from "@/components/layout/Planner.vue";

@Component({
  components: {Planner},
})
export default class Test extends Vue {
  expandNavigation: boolean = false;
  expandSecondary: boolean = false;
  testColor: string = '#7b7343';
  activeTab: number = 0;
  hideSide: boolean = false;

  tasks: Array<string> = [
    'Design onboarding 💃',
    'Write hiring criteria',
    'Publish blog post',
    'Book offsite',
  ];

  amieColors: Array<string> = [
    '#754373',
    '#4a677d',
    '#7b7343',
  ];

  /*
  amieColors: Array<string> = [
    '#c26ebf',
    '#6380a8',
    '#9d935b',
  ];

   */
  colors: Array<string> = [
    '#AF51D2',
    '#11B4E3',
    '#E9C108',
  ];
}
