

























































































import Paywall from '@/components/subscription/Paywall.vue';
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class ObjectiveDetailedMoreActions extends Vue {
  makeFullheight: boolean = false;

  @Prop({required: false})
  canDelete!: boolean;

  get workspace() {
    return this.$store.state.workspace;
  }

  get me() {
    return this.$store.state.me;
  }

  get isPremium() {
    if(this.$store.state.workspace.currentSubscription) {
      return true
    } else { return false }
  }

  get newUser() {
    let userCreationDate = new Date(this.me.created_at);
    let comparisonDate = new Date('2024-02-22');
    return userCreationDate > comparisonDate;
  }

  get currentPlan() {
    if(this.workspace.currentSubscription !== null) {
      return this.workspace.currentSubscription.plan.title
    }
    else { return null }
  }

  closeModal() {
    this.$emit('closemodal');
   
  }

  newBookmark() {
    this.$emit('new-bookmark', true);
    this.close();
  }

  newRecurrence() {
    this.$emit('new-recurrence', true);
    this.close();
  }

  newReminder() {
    this.$emit('new-reminder', true);
    this.close();
  }

  newTask() {
    this.$emit('new-task', true);
    this.close();
  }

  duplicate() {
    this.$emit('open-duplicate', true);
    this.close();
  }

  requestDelete() {
    this.$emit('delete', true);
    this.close();
  }

  close() {
    this.$emit('close', true);
  }

  openPaywall() {
    this.$buefy.modal.open({
      component: Paywall,
      // @ts-ignore
      customClass: 'split-modal',
      parent: this,
      events: {
      // Listen for the 'closemodal' event from the Paywall component
        closemodal: this.closeModal
      }
    });
  }
}
