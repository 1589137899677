






















































































































import {Component, Vue} from 'vue-property-decorator';
import EditBillingDetails from "@/components/subscription/EditBillingDetails.vue";
import PaymentMethods from "@/components/subscription/PaymentMethods.vue";
import Invoices from "@/components/subscription/Invoices.vue";
import {Role} from "@/typescript/types";
import * as ct from "countries-and-timezones";
import ConfirmDelete from "@/components/general/ConfirmDelete.vue";
import {CancelSubscription} from "@/graphql/WorkspaceSubscription";
import Template from "@/components/templates/Template.vue";
import {WorkspaceQuery} from "@/graphql/workspace";


@Component({
  components: {Template, EditBillingDetails, PaymentMethods, Invoices},
})
export default class WorkspacePlanManager extends Vue {

  intervals: any = {'month': 'Monthly', 'year': 'Yearly', 'day': 'Daily', 'week': 'Weekly'}

  get me() {
    return this.$store.state.me;
  }

  get planList() {
    return this.$store.state.plans;
  }

  get workspace() {
    return this.$store.state.workspace;
  }

  get isPrimaryOwner() {
    return (this.workspace.my_role === Role.PrimaryOwner) ? true : false;
  }

  get workspaceSubscription() {
    return this.$store.state.workspace.currentSubscription;
  }

  get billingDetails() {
    return this.me.billingDetails;
  }


  openBillingDetailsEdit() {
    this.$buefy.modal.open({
      component: EditBillingDetails,
      props: {
        open: true
      },
      width: '480px',
      parent: this,
    });
  }

  openPlanSelection() {
    this.$router.push({name: 'checkout-flow'});

    this.$emit('close', true);

    // this.$nextTick(function(){
    //   //@ts-ignore ToDo: fix type issue
    //   this.$parent.close();
    // });
  }

  getTaxName(tax_type: string) {
    let taxType = tax_type.split('-');
    const taxname = taxType[0].replace(/_/g, " ");
    let country = this.getCountry(taxType[1]);
    return taxname.toUpperCase() + ' ' + country;
  }

  getCountry(countryCode: string) {
    const country = ct.getCountry(countryCode);
    if(country) {
      return country.name;
    }

  }

  cancelPlan() {
    this.$buefy.modal.open({
      parent: this,
      component: ConfirmDelete,
      props: {
        deleteItemName: 'Are you sure you want to cancel current subscription?',
        closeParentFirst: true
      },
      events: {
        //@ts-ignore ToDo: fix type issue
        'confirmed': value => {
          if (value === true) {
            this.cancelSubscription();
          }
        }
      },
      width: '500px',
    });
  }

  cancelSubscription() {
    this.$apollo.mutate({
      mutation: CancelSubscription,
      variables: {
        subscription_id: this.workspace.currentSubscription.subscription_id,
        workspace_id: this.workspace.id
      },
      refetchQueries:[{ query: WorkspaceQuery, variables: { pid: this.$store.state.active_workspace_pid } }]
    }).then((_result: any) => {
      this.$buefy.toast.open({
        message: 'The subscription will be cancelled at the end of billing period',
        position: 'is-bottom-right',
        type: 'is-black',
      });
    })
  }


}
