
























































import { Component, Vue } from 'vue-property-decorator';
import { ForgotPasswordMutation } from '@/graphql/auth';
import LoginLayout from '@/components/auth/login/LoginLayout.vue';
@Component({
  components: { LoginLayout },
})
export default class PasswordReset extends Vue {
  email = '';
  sent = false;
  resetSuccessful = false;
  emailNotFound: boolean | null = null;

  send() {
    this.sent = true;
    this.$apollo
      .mutate({
        mutation: ForgotPasswordMutation,
        variables: {
          email: this.email,
        },
      })
      .then(_data => {
        this.resetSuccessful = true;
        this.emailNotFound = false;
      })
      .catch(error => {
        console.error(error);
        this.emailNotFound = true;
        this.resetSuccessful = false;
      })
      .finally(() => {
        this.sent = false;
      });
  }
}
