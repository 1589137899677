













































































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import WidgetTemplate from "@/components/general/WidgetTemplate.vue";
import SmartModal from "@/components/general/SmartModal.vue";
import {ObjectivePaginator} from "@/typescript/types";
import ConfettiCanon from "@/components/general/ConfettiCanon.vue";
import confetti from "canvas-confetti";
import {
  CompletedObjectivesByDate,
  ObjectivesListQuery,
  TotalObjectiveCountInDateRange,
} from "@/graphql/objective";
import Objective from "@/components/board/objective/Objective.vue";
import {
  addDays,
  eachDayOfInterval, endOfISOWeek,
  isAfter,
  isBefore,
  isEqual,
  isToday,
  parseISO,
  startOfDay,
  startOfISOWeek
} from "date-fns";




@Component({
  components: {Objective, SmartModal, WidgetTemplate, ConfettiCanon},
})
export default class DashboardCompletedObjectives extends Vue {
  getObjectivesLists: ObjectivePaginator | null = null;
  getObjectivesByDay: Array<{day: string, objectCount: number}>  = [];
  page: number = 0;
  highestValue: number | null = null;
  totalObjectiveCount: number | null = null;
  totalCompletedObjectiveCount: number | null = null;
  updatedObjective: any | null =null;
  showPercentage: boolean = this.completionTrackerView;
  confettiFire: boolean = false;

  animatedCompletePercent: string = '0';
  animatedCompletedObjectives: string = '0/0';

  myConfetti: any | null = null;

  private targetCompletePercent: number = 0;
  private targetCompletedObjectives: number = 0;

  previewColors: Array<string> = [
    '#bd5fb4',
    '#814181',
  ];

  colors: Array<string> = [
    '#bd5fb4',
    '#773f75',
    '#4a677d',
    '#66737a',
    '#776015',
    '#7b7343',
    '#316863',
    '#2e825f',
    '#4e6068',
    '#717171',
    '#434343',
  ];
  lastCompletedObjective: String | null = null;
  flashEffect: boolean = false;

  @Prop({required: false, default: false})
  inModal!: boolean;

  @Prop({required: false, default: false})
  minimized!: boolean;

  @Prop({required: true})
  origin!: string

  @Prop({required: true})
  originId!: string

  selfModalOpen() {
    // animation: 'animated fadeInLeft faster',
    this.$buefy.modal.open({
      component: DashboardCompletedObjectives,
      //@ts-ignore
      customClass: 'smart-modal animate-up-left',
      props: {
        inModal: true,
        startDate: this.startDate,
        endDate: this.endDate,
        userId: this.$props.userId,
        origin: this.$props.origin,
        originId: this.$props.originId
      },
      parent: this,
    });
  }


  @Prop({required: false, default: null})
  startDate!: Date

  @Prop({required: false, default: null})
  endDate!: Date

  @Prop({required: false, default: null})
  userId!: String

  // @Watch ( '$route', { immediate: true, deep: true })
  // onRouteChange(){
  //   Vue.prototype.$consoleLog('route changed in dashboard statistics, running refetch...')
  //   this.$nextTick(function () {
  //     Vue.prototype.$consoleLog('hi')
  //     //this.refetchData();
  //   });
  // }

  // get totalObjectiveCount() {
  //   return this.$store.state.totalObjectivesInTimeStamp;
  // }

  get fakeObjectivesByDay() {
    return [

      {day: startOfISOWeek(new Date()), objectCount: 0, id: '1'},
      {day: addDays(startOfISOWeek(new Date()), 1), objectCount: 0, id: '2'},
      {day: addDays(startOfISOWeek(new Date()), 2), objectCount: 0, id: '3'},
      {day: addDays(startOfISOWeek(new Date()), 3), objectCount: 0, id: '4'},
      {day: addDays(startOfISOWeek(new Date()), 4), objectCount: 0, id: '5'},
    ];
  }

  get completePercent() {
    if (this.totalCompletedObjectiveCount && this.totalCompletedObjectiveCount > 0 && this.totalObjectiveCount !== null) {
      this.fireConfetti();
        
      // Calculate percentage
      const percent = (this.totalCompletedObjectiveCount / this.totalObjectiveCount) * 100;

      // Check if the result is finite and non-negative
      if (isFinite(percent) && percent >= 0) {
        return percent.toFixed(1);
      } else {
        return '0';
      }
    }
    return '0';
  }

  get displayObjectives() {
    if(this.totalObjectiveCount != null) {
      return this.totalObjectiveCount
    }
    return '0';
  }

  get displayCompletedObjectives() {
    if(this.totalCompletedObjectiveCount != null) {
      return this.totalCompletedObjectiveCount
    }
    return '0';
  }

  get workspace(){
    return this.$store.state.workspace;
  }

  get completionTrackerView() {
    return this.$store.state.completion_tracker_view;
  }

  returnIndexOfToday(day: any, index: any) {
    let inputDate = new Date(day);
    let todaysDate = new Date();

    if(inputDate.setHours(0,0,0,0) == todaysDate.setHours(0,0,0,0)) {
      return index;
    } else {
      return null;
    }
  }

  // fireConfetti() {
  //   this.confettiFire = true;
  //   Vue.prototype.$consoleLog("confetti true")

  //   setTimeout(() => {
  //     this.confettiFire = false;
  //     Vue.prototype.$consoleLog("confetti false")
  //   }, 200);
  // }

  toggleMinimize() {
    this.$emit('minimize-toggle', true);
  }

  toggleCompletionView() {
    this.showPercentage = !this.showPercentage;
    this.$store.commit('set_completion_tracker_view', this.showPercentage);
  }

  getAnimationDelay(index: number) {
    // Animation for delay of graph bars. Takes the index of v-for loop and converts it to ms
    return index * 100 + 'ms';
  }

  getAllObjectivesCounts() {
    this.$apollo.query({
      query: TotalObjectiveCountInDateRange,
      fetchPolicy: 'no-cache',
      variables: {
        workspace_id: (this.$props.origin == 'workspace') ? this.$props.originId : this.workspace.id,
        board_id: (this.$props.origin == 'board') ? this.$props.originId : null,
        start_date: this.$props.startDate,
        end_date: this.$props.endDate,
        user_id: this.$props.userId

      },
      errorPolicy: 'all'
    }).then((result: any) => {
      
      if(result.data.getTotalObjectiveCountInDateRange) {

        this.totalObjectiveCount = result.data.getTotalObjectiveCountInDateRange.totalObjectiveCount;
        this.totalCompletedObjectiveCount = result.data.getTotalObjectiveCountInDateRange.totalCompletedObjectiveCount;
      }
    }).catch((_error: any) =>{
     
    })
  }

  getAllObjectives() {
    this.$apollo.query({
      query: ObjectivesListQuery,
      fetchPolicy: 'network-only',
      variables: {
        filter_type: this.$props.origin,
        filter_type_id: this.$props.originId,
        status: 'completed',
        completed_at_start: this.$props.startDate,
        completed_at_end: this.$props.endDate,
        userId: this.$props.userId,
        page: this.page

      },
      errorPolicy: 'all',
    }).then((result: any) => {
      if(this.getObjectivesLists) {
        this.getObjectivesLists.data = this.filterDuplicateData([...this.getObjectivesLists.data, ...result.data.getObjectivesLists.data]);
        this.getObjectivesLists.paginatorInfo = result.data.getObjectivesLists.paginatorInfo;
      }else{
        this.getObjectivesLists = result.data.getObjectivesLists;
      }

    }).catch((error: any) =>{
      Vue.prototype.$consoleLog(error);
    })
  }

  getCompletedObjectivesCountByDay() {
    
    this.$apollo.query({
      query: CompletedObjectivesByDate,
      fetchPolicy: 'network-only',
      variables: {
        workspace_id: (this.$props.origin == 'workspace') ? this.$props.originId : this.workspace.id,
        board_id: (this.$props.origin == 'board') ? this.$props.originId : null,
        start_date: startOfISOWeek(new Date()),
        end_date: endOfISOWeek(new Date()),
        user_id: this.$props.userId

      }
    }).then((result: any) =>{

      const dayInterval = eachDayOfInterval({
        start: startOfISOWeek(new Date()),
        end: endOfISOWeek(new Date())
      })
      let resMap = {};

      if(result.data.getCompletedObjectivesByDay.length > 0) {
        resMap= result.data.getCompletedObjectivesByDay.map((item: any) => {
          return new Date(item.completed_at);
        }).reduce((a: any,c: any)=>{
          let d=c.toDateString();
          a[d]=a[d]?a[d]+1:1;
          return a;}, {});
      }

      dayInterval.forEach((item: any) => {
        //let index = resMap.findIndex((i: any) => i == item.toDateString());
        const countData = {
          'day': item,
          //@ts-ignore
          'objectCount': (item.toDateString() in resMap) ? resMap[item.toDateString()] : 0
        }
        this.getObjectivesByDay.push(countData)
      })
      this.calcHighestValue();
      // this.getObjectivesByDay = result.data.getCompletedObjectivesByDay;

    })
  }

  get filterByPreviewData() {
    if(this.getObjectivesByDay && this.getObjectivesByDay.length > 0) {
      if(this.origin === 'workspace') {
        return this.getObjectivesByDay.slice(0, 5);
      } else {
        const currentDate = new Date().getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday

        if (currentDate === 0) {
          // If it's Sunday, return the current day (Sunday) and the previous day (Saturday)
          return [
            this.getObjectivesByDay[6],
            this.getObjectivesByDay[0],
          ];
        }

        // Return the current day and the next day
        return [
          this.getObjectivesByDay[currentDate - 1],
          this.getObjectivesByDay[currentDate],
        ];
      }
    } else {
      if(this.origin === 'workspace') {
        return this.fakeObjectivesByDay;
      } else {
        return this.fakeObjectivesByDay.slice(0,2);
      }
    }
  }

  calcHighestValue() {
    // Filter the highest value in the array of data, in order to give a reference to rest of values
    let arr = this.getObjectivesByDay;
    let largest = arr[0].objectCount;

    for (let i = 0; i < arr.length; i++) {
      if (arr[i].objectCount > largest ) {
        largest = arr[i].objectCount;
      }
    }
    this.highestValue = largest;
  }

  compareWithHighest(num: number) {
    // This calculates how to display a representative visual height of progress of each bar item
    if(this.highestValue) {
      return (num / this.highestValue * 100) - 15 + '%';
    } else {
      return '10%';
    }
  }

  filterDuplicateData(arr: Array<any>) {
    return arr.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i)
  }

  changePage() {
    this.page++;
    this.getAllObjectives();
  }

  refetchData() {
    this.getObjectivesByDay = [];
    this.getObjectivesLists = null;
    this.totalObjectiveCount = null;
    this.getCompletedObjectivesCountByDay();
    this.getAllObjectivesCounts();
    //this.getAllObjectives();
  }

  mounted() {

    this.animatedCompletePercent = this.completePercent;
    this.targetCompletePercent = parseFloat(this.completePercent);
    this.animatedCompletedObjectives = `${this.displayCompletedObjectives}/${this.displayObjectives}`;
    //@ts-ignore
    this.targetCompletedObjectives = this.displayCompletedObjectives;

    this.page++

    this.prepareConfetti();

    if(this.$props.minimized) {
      this.getAllObjectivesCounts();
      //this.getAllObjectives();
    }else{

      this.getCompletedObjectivesCountByDay();
      this.getAllObjectivesCounts();
      //this.getAllObjectives();

    }


    this.$events.listen('objective-updated', (eventData) => {

      if((eventData.status === 'completed' && isToday(parseISO(eventData.completed_at))) || (eventData.is_recurring && isToday(parseISO(eventData.completed_at)))) {

       
        /**
         * Add completed count for live update
         * */
        // let index = this.getObjectivesLists!.data.findIndex((obj: any) => {
        //   return obj.id === eventData.id;
        // });
        //
        // if(index == -1) {
        //   this.getObjectivesLists!.data.push(eventData);
        //   this.getObjectivesLists!.paginatorInfo.total += 1;
        // }
        // if(!this.updatedObjective) {
        //   this.updatedObjective = eventData;
        //this.getAllObjectivesCounts();
        this.totalCompletedObjectiveCount! += 1;
        // }else if(this.updatedObjective.id != )

        /**
         * Checking day present in array list or not
         * add 1 to objectcount if present and completed
         * */
        if(this.getObjectivesByDay) {
          let dateIndex = this.getObjectivesByDay.findIndex((data: any) => {

    
            return isEqual(startOfDay(data.day), startOfDay(parseISO(eventData.completed_at)));
          });

          if(dateIndex != -1) {
            this.getObjectivesByDay[dateIndex].objectCount += 1;
            this.calcHighestValue();
          }
        }
        this.getCompletedObjectivesCountByDay();

      }else{
        /**
         * Subsctract the completed count for live update
         * */
        // let index = this.getObjectivesLists!.data.findIndex((obj: any) => {
        //   return obj.id === eventData.id;
        // });
        // if(index != -1) {
        //   this.getObjectivesLists!.data.splice(index, 1);
        //   this.getObjectivesLists!.paginatorInfo.total -= 1;
        // }
        //if(this.updatedObjective.id == eventData.id) {
        //this.updatedObjective = eventData;
        //this.getAllObjectivesCounts();
        //}
        if(!eventData.is_recurring) {
          this.totalCompletedObjectiveCount! -= 1;


          /**
           * Checking day present in array list or not
           * substract 1 to objectcount if present and not completed
           * */
          if(this.getObjectivesByDay) {
            let dateIndex = this.getObjectivesByDay.findIndex((data: any) => {
              return isEqual(startOfDay(data.day), startOfDay(new Date()));
            });

            if(dateIndex != -1) {
              this.getObjectivesByDay[dateIndex].objectCount -= 1;
              this.calcHighestValue();
            }
          }
        }
        this.getCompletedObjectivesCountByDay();

      }

    })

    this.$events.listen('objective-created', (_eventData) => {
      if(isAfter(parseISO(_eventData.start_date), this.$props.startDate)  && isBefore(parseISO(_eventData.start_date), this.$props.endDate) || isEqual(parseISO(_eventData.objective.start_date), this.$props.startDate)) {
        this.totalObjectiveCount! += 1;
      }
    })

    this.$events.listen('objective-created-from-northstar', (_eventData) => {
      
      if(isAfter(parseISO(_eventData.objective.start_date), this.$props.startDate)  && isBefore(parseISO(_eventData.objective.start_date), this.$props.endDate) || isEqual(parseISO(_eventData.objective.start_date), this.$props.startDate) ) {
        this.totalObjectiveCount! += 1;
      }
    })

    this.$events.listen('objective-deleted', (_eventData) => {
      this.totalObjectiveCount! -= 1;
    })

    this.$events.listen('snooze-objective-clear', (_eventData: any) => {
      this.totalObjectiveCount! -= 1;
    });
  }

  prepareConfetti() {
    this.$nextTick(() => {
      const canvas = this.$el.querySelector('#custom-canvas');
      if (canvas) {
        // Store the confetti instance in the component for global access
        //@ts-ignore
        this.myConfetti = confetti.create(canvas, {
          resize: true
        });
      }
    });
  }

  fireConfetti() {
    if (this.myConfetti) {
      this.myConfetti({
        origin: { y: 0.5, x: 0.5},
        fire_timeout: 100,
        fireCount: 6,
        startVelocity: 10,
        particleCount: 5,
        spread: 150,
        gravity: 100,
        angle: 90,
        ticks: 35,
        shapes: ['circle'],
        colors: ['#DFF8FE', '#82B1BE'],
        scalar: 0.4
      });
    }
  }
  
  animateValue(start: number, end: number, duration: number, callback: (value: number) => void) {
    let startTimestamp: number | null = null;
    const step = (timestamp: number) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      const value = start + progress * (end - start);
      callback(value);
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  }

  @Watch('completePercent')
  onCompletePercentChanged(newValue: string, oldValue: string) {
    const newPercent = parseFloat(newValue);
    const oldPercent = parseFloat(oldValue);
    this.confettiFire = true; 
    this.fireConfetti();

    if (newPercent > oldPercent) {
      setTimeout(() => {
        this.confettiFire = false;
      }, 200);
  
      this.targetCompletePercent = parseFloat(newValue);
      this.animateValue(parseFloat(this.animatedCompletePercent), this.targetCompletePercent, 500, (value) => {
        this.animatedCompletePercent = value.toFixed(0); // Adjust to match your formatting
      });
    } else {
      
      this.animatedCompletePercent = newPercent.toFixed(0);
    }
   
  }

  @Watch('displayCompletedObjectives')
  onDisplayCompletedObjectivesChanged(newValue: number) {
    this.targetCompletedObjectives = newValue;
    this.animateValue(parseFloat(this.animatedCompletedObjectives.split('/')[0]), this.targetCompletedObjectives, 500, (value) => {
      this.animatedCompletedObjectives = `${Math.round(value)}/${this.displayObjectives}`;
    });
  }

  @Watch('startDate', {deep: true})
  completedAtChange() {

    this.$nextTick(function () {
      this.totalObjectiveCount = null;
      this.totalCompletedObjectiveCount = null;
      this.getAllObjectivesCounts();
      //this.getObjectivesLists = null;
      //this.getAllObjectives();
      //this.getObjectivesByDay = [];
      this.getCompletedObjectivesCountByDay();
    });

  }

  @Watch('userId', {deep: true})
  userIdChange() {
    this.$nextTick(function () {
      this.totalObjectiveCount = null;
      this.totalCompletedObjectiveCount = null;
      this.getAllObjectivesCounts();
      //this.getObjectivesLists = null;
      //this.getAllObjectives();
      //this.getObjectivesByDay = [];
      this.getCompletedObjectivesCountByDay();
    });
  }

  @Watch('originId', {deep: true})
  originIdChange() {
    this.$nextTick(function () {
      this.totalObjectiveCount = null;
      this.totalCompletedObjectiveCount = null;
      this.getAllObjectivesCounts();
      //this.getObjectivesLists = null;
      //this.getAllObjectives();
      this.getObjectivesByDay = [];
      this.getCompletedObjectivesCountByDay();
    });
  }


}
