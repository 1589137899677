
















































































import {Component, Prop, Vue} from 'vue-property-decorator';
import UserService from "@/services/UserService";
import CountDownTimer from "@/components/general/CountDownTimer.vue";
import {addMinutes} from "date-fns";
import SocialAuth from "@/components/auth/SocialAuth.vue";

@Component({
  components: {SocialAuth, CountDownTimer}
})
export default class LoginForm extends Vue {
  clicked = false;
  redirectClicked = false;
  error: string | null = null;
  password: string | null = null;
  username: string | null = null;
  loginAttempt: number = 0;
  startDate: Date | null = null;
  endDate: Date | null = null;
  isDisabled: boolean = false;

  @Prop({required: false})
  email!: string | null;

  service!: UserService | null;

  mounted() {
    this.service = new UserService(this.$apollo);

    if (this.email) {
      this.username = this.email;
    }

    this.$events.listen('time-ended', (_)=>{
      this.isDisabled = false;
      this.loginAttempt = 0;
      this.error = null;
    })
  }

  get disableEmailEdit() {
    return this.email;
  }


  login() {
    this.error = null;
    this.clicked = true;
    this.service?.login(this.username, this.password).then(() => {
      this.$emit('logged-in');
    }).catch(error => {
      this.error = error;
      this.clicked = false;
      if(error.graphQLErrors[0].extensions.category === 'authentication') {
        this.loginAttempt++;
        if(this.loginAttempt === 5){
          this.startDate = new Date();
          this.endDate = addMinutes(this.startDate, 5);
          this.isDisabled = true;
        }

      }


      this.$nextTick(function () {
        let input = document.querySelector('#pwInput') as HTMLInputElement;
        input.select();
      });
    }).finally(() => {
      this.$gtag.event('login', {method: 'Google'})
    });
  }
  
}
