

















































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import UserAvatar from '@/components/user/UserAvatar.vue';
import UsersSelector from '@/components/formfields/UsersSelector.vue';
import ListedBoardUsers from '@/components/board/ListedBoardUsers.vue';
import { User, BoardMember, WorkspaceMember } from '@/typescript/types';
import gql from 'graphql-tag';
import { BoardQuery } from '@/graphql/board';
import CreateWorkspaceInviteModal from "@/components/settings/workspace/CreateWorkspaceInviteModal.vue";
import InlineDropdown from "@/components/general/InlineDropdown.vue";
import {CreateWorkspaceInviteMutation} from "@/graphql/workspace_invite";
import {WorkspaceQuery} from "@/graphql/workspace";
import Template from "@/components/templates/Template.vue";
import Paywall from "@/components/subscription/Paywall.vue";
import VerifyUser from "@/components/auth/VerifyUser.vue";


@Component({
  components: {Template, InlineDropdown, ListedBoardUsers, UserAvatar, UsersSelector },
})
export default class BoardMembers extends Vue {
  searchInput: string | null = null;
  selectedUsers: Array<User> = [];
  expandMembers = false;
  loadMembersComponents = false;
  openLoading = false;
  addMembers = false;
  clicked = false;
  inviteEmails: Array<string> = new Array<string>();
  selectedRole: string | null = 'guest';
  inviteSent: boolean = false;

  @Prop({default: false, required: false})
  onboarding!: boolean;

  get workspaceName() {
    return this.$store.state.workspace.name;
  }

  get isDisabled(){
    if(this.selectedUsers.length >= 1 || this.inviteEmails.length >= 1){
      return false;
    }else{
      return true;
    }
  }

  get workspaceSubscription() {
    return this.$store.state.workspace.currentSubscription;
  }

  get filterDeletedWorkspaceMember() {
    if(this.workspace.members) {
      return this.workspace.members.filter((member: any) => {
        return member.user.deleted_at === null;
      })
    }
    return null;
  }

  get checkWorkspaceSubscription() {

    if(this.workspace.currentSubscription && this.filterDeletedWorkspaceMember.length < this.workspace.currentSubscription.plan.planAccess
      .max_user) {
      
      return true;
    }else if(!this.workspace.currentSubscription && this.filterDeletedWorkspaceMember.length < this.$store.state.plans[0].planAccess.max_user) {
      
      return true;
    }
    return false;
  }

  get isAdminOfWorkspace() {
    return this.$store.getters.isAdminOfWorkspace;
  }

  get board() {
    return this.$store.state.board;
  }

  get isOnGuestAccount() {
    return this.$store.state.me.isFake;
  }

  get myRole() {
   
    return this.$store.state.board.my_role;
  }

  get workspacePid() {
    return this.$store.state.active_workspace_pid;
  }

  get workspaceMembersCount() {
    return this.$store.state.workspace.members.length;
  }

  get boardMembersCount() {
    return this.$store.state.board.members.length;
  }

  get allMembersInvited() {
    if(this.boardMembersCount >= this.workspaceMembersCount) {
      return true;
    } else {
      return false;
    }
  }

  get workspace() {
    return this.$store.state.workspace;
  }

  get workspaceId() {
    return this.$store.state.workspace.id;
  }

  get variables() {
    return {
      workspace: this.workspaceId,
      role: this.selectedRole,
      boards: this.board.id,
    };
  }

  get pendingInvites() {
    if(this.workspace.invites && this.workspace.invites.length > 0){
      return this.workspace.invites.filter((inv: any) => {
        if(inv.boards.length > 0 && inv.boards[0].id === this.board.id){
          return inv;
        }
      })
    }
    return [];
  }


  mounted() {
    this.inviteSent = false;
  
    this.$nextTick(function () {
      //@ts-ignore ToDo: fix type issue
      this.$refs.mainInput.focus();
    });
  }

  beforeDestroy() {
    this.inviteSent = false;
  }

  goToWorkspaceMembers() {
    this.$router.push({ name: 'members-manager' });
    //@ts-ignore
    this.$parent.close();
  }

  openUserInviteModal() {
    this.$buefy.modal.open({
      component: CreateWorkspaceInviteModal,
      props: {
        workspaceId: this.workspaceId,
        workspacePid: this.workspacePid,
      },
      // @ts-ignore
      customClass: 'fullpage-modal',
      parent: this,
      width: 500,
    });
  }

  get boardUsers() {
    let bUsers: Array<User> = [];
    this.board.members.forEach((element: BoardMember) => {
      if(element.user.deleted_at === null)
        bUsers.push(element.user);
    });
    return bUsers;
  }

  get workspaceUsers() {
    let wUsers: Array<User> = [];
    this.board.workspace.members.forEach((element: WorkspaceMember) => {
      if(element.user.deleted_at===null)
        wUsers.push(element.user);
    });
    return wUsers;
  }

  addUsers() {
    this.clicked = true;
    if(this.selectedUsers.length >= 1){
      this.addWorkspaceMember();
    }

    if(this.inviteEmails.length >= 1){
      if(this.checkWorkspaceSubscription) {
        this.inviteExternalUser();
      }else{
        this.upgradePlan();
      }

    }

  }

  closeModal() {
    // @ts-ignore
    this.$parent.close() 
  }

  claimAccount() {
    this.$gtag.event('pmb_user_claim_account_manually_initiated_create_invite');
    this.$buefy.modal.open({
      component: VerifyUser,
      props: {
        claimWs: (this.$store.state.me.isFake) ?? false,
        clickedClaim: true
      },
      width: '500px',
      canCancel: !this.$store.state.currently_on_verification_code_step,
      parent: this,
    });
  }

  upgradePlan() {
    //@ts-ignore
    this.$parent.close();
    this.$buefy.modal.open({
      component: Paywall,
      width: '480px',
      // @ts-ignore
      customClass: 'split-modal',
      parent: this,
    });
  }

  addWorkspaceMember(){
    for (let i = 0; i < this.selectedUsers.length; i++) {
      this.$apollo
        .mutate({
          mutation: gql`
                        mutation addUserToBoard($user_id: ID!, $board_id: ID!) {
                            addUserToBoard(user_id: $user_id, board_id: $board_id) {
                                user {
                                    id
                                    name
                                }
                                board {
                                    id
                                    pid
                                    name
                                }
                                role
                            }
                        }
                    `,
          variables: {
            user_id: this.selectedUsers[i].id,
            board_id: this.board.id,
          },
          refetchQueries: [{ query: BoardQuery, variables: { id: this.board.id } }],
        })
        .then(_ => {
          this.$gtag.event('pmb_room_internal_member_invite_created');
        })
        .finally(() => {
          this.clicked = false;
          this.addMembers = false;
          this.selectedUsers = [];
        });
    }
    this.$buefy.toast.open({
      message: 'Added users!',
      position: 'is-bottom-right',
      type: 'is-black',
    });
  }

  inviteExternalUser(){
    for (let i = 0; i < this.inviteEmails.length; i++) {
      this.$apollo
        .mutate({
          mutation: CreateWorkspaceInviteMutation,
          variables: {
            invitee_email: this.inviteEmails[i],
            ...this.variables,
          },
          refetchQueries: [
            {query: WorkspaceQuery, variables: {pid: this.workspacePid}},
          ],
        })
        .catch(error => {
          console.error(error);
          this.$gtag.event('pmb_room_external_invite_failed');
        })
        .then(_ => {
          this.inviteSent = true;
          //@ts-ignore
          this.$gtag.event('pmb_room_external_invite_created');
        })
        .finally(() => {
          this.$buefy.toast.open({
            message: 'Created invite!',
            position: 'is-bottom-right',
            type: 'is-black',
          });

          this.$gtag.event('pembio_invite_workspace');
        });
    }
  }
}
