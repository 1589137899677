










import {Component, Prop, Vue} from 'vue-property-decorator';
import Encryption from "@/encryption";
import {formatISO} from "date-fns";

@Component({
  components: {},
})
export default class ObjectiveCalender extends Vue{
  decryptedDescription: string | null = null;
  decryptName: string | null = null;
  @Prop({required: true})
  startDate!: Date

  @Prop({required: true})
  dueDate!: Date

  @Prop({required: true})
  objectiveName!: string

  @Prop({required: true})
  objectiveDescription!: string

  @Prop({required: true})
  objectiveMembers!: Array<any>

  calenderArray: Array<{ id: number, name: string, path: string }> = [
    { id: 0, name: 'Google Calender', path: 'https://calendar.google.com/calendar/render?'},
    { id: 1, name: 'Outlook Calender', path: 'https://outlook.live.com/calendar/0/deeplink/compose?'}
  ];

  attendees: string | null = null;

  createLink(calender: any){
    let startDate =  new Date(this.$props.startDate);
    let endDate = new Date(this.$props.dueDate);
    let link= calender.path;

    if(calender.id === 0){

      if(!!this.$props.startDate  && (endDate.getTime() > new Date().getTime())) {
        link = link + 'action=TEMPLATE&dates='+formatISO(startDate,{ format: 'basic' })+'/'+formatISO(endDate,{ format: 'basic' })+'&details='+this.decryptedDescription+'&text='+this.decryptName;
      }else{
        link = link + 'action=TEMPLATE&details='+this.decryptedDescription+'&text='+this.decryptName;
      }

      if(this.attendees){
        link = link + '&add='+encodeURIComponent(this.attendees);
      }
    }

    if(calender.id === 1){
      if(!!this.$props.startDate  && (endDate.getTime() > new Date().getTime())) {
        link = link + 'body=' + this.decryptedDescription + '&enddt=' + this.$props.dueDate + '&startdt' + this.$props.startDate + '&path=/calendar/action/compose&rru=addevent&subject=' + this.decryptName;
      }else{
        link = link + 'body=' + this.decryptedDescription + '&path=/calendar/action/compose&rru=addevent&subject=' + this.decryptName;
      }
      if(this.attendees){
        link = link + '&to='+encodeURIComponent(this.attendees);
      }
    }

    return link;
  }

  getCommaSeperatedMember(members: any){
    if(members.length > 0){
      return members.map(function(member: any){return member.email}).join(",");
    }
    return null;
  }
  mounted() {
    this.decryptName = Encryption.decrypt(this.$props.objectiveName);
    if (this.objectiveDescription) {
      this.decryptedDescription = (Encryption.decrypt(this.objectiveDescription)) ? Encryption.decrypt(this.objectiveDescription) : '';
    }
    this.attendees =  this.getCommaSeperatedMember(this.$props.objectiveMembers);

    this.$events.listen('calender-assignees', eventData => {
      this.attendees = this.getCommaSeperatedMember(eventData.assignees);
      if (eventData.description) {
        this.decryptedDescription = (Encryption.decrypt(this.objectiveDescription)) ? Encryption.decrypt(this.objectiveDescription) : '';
      }
      this.decryptName = Encryption.decrypt(eventData.name);
    })
  }

}
