

















































































































import {Component, Vue} from 'vue-property-decorator';
import AutoGenRegister from "@/components/auth/AutoGenRegister.vue";

@Component({
  components: {AutoGenRegister},
})
export default class LandingPageClient extends Vue {

  goals: Array<{ name: string, items: Array<any> }> = [
    {
      name: 'Wellness and health',
      items: []},
    {
      name: 'Become a better manager',
      items: []},
    {
      name: 'Improve communication',
      items: [
        'Notify stakeholders',
        'Communicate internally',
        'Book a partner workshop',
        'Prepare copy for new ads',
        'Book the event venue',
        'Request analytics',
      ]},
  ];

  mounted() {
    this.$store.commit('set_onboarding_use_case', 'client');
  }

}
