import Vue from 'vue';

import VueRouter from 'vue-router';
Vue.use(VueRouter);

import BoardCreate from '@/components/auth/register/BoardCreate.vue';
import Login from '@/components/auth/login/Login.vue';
import Register from '@/components/auth/register/Register.vue';
import WorkspaceCreate from '@/components/auth/register/WorkspaceCreate.vue';
import GoalCreate from '@/components/auth/register/GoalCreate.vue';
import MembersInvite from '@/components/auth/register/MembersInvite.vue';
import PasswordReset from '@/components/auth/login/PasswordReset.vue';
import NewPasswordForm from '@/components/auth/login/NewPasswordForm.vue';
import CreateAvatarPage from '@/components/user/CreateAvatarPage.vue';

import InvitationView from '@/components/invitations/InvitationView.vue';

// workspace components
import WorkspaceLayout from '@/components/workspace/WorkspaceLayout.vue';
import DashboardLayout from '@/components/workspace/DashboardLayout.vue';
import FilesManager from '@/components/workspace/FilesManager.vue';
import MembersManager from '@/components/workspace/MembersManager.vue';
import WorkspaceOnboarding from "@/components/workspace/WorkspaceOnboarding.vue";


import store from '@/store';

import Logout from '@/components/auth/login/Logout.vue';
import Board from '@/components/board/Board.vue';

import WorkspaceAllObjectives from "@/components/workspace/WorkspaceAllObjectives.vue";

import AddToSlackCallback from '@/components/auth/AddToSlackCallback.vue';
import OauthCallback from '@/components/auth/OauthCallback.vue';

// templates

import TemplatesLayout from '@/components/templates/TemplatesLayout.vue';
import Template from '@/components/templates/Template.vue';

import DeleteAccount from "@/components/user/DeleteAccount.vue";

// board presence

import StartCall from "@/components/board/presence/StartCall.vue";

// Rooms

import Room from "@/components/room/Room.vue";


// Room onboarding

import OnboardingView from "@/components/board/onboarding/OnboardingView.vue";


// board utilities

import BoardActivity from "@/components/board/utilities/BoardActivity.vue";
import BoardApps from "@/components/board/utilities/BoardApps.vue";
import BoardNotes from "@/components/board/utilities/BoardNotes.vue";
import BoardGoals from "@/components/board/utilities/BoardGoals.vue";
import BoardEmbed from "@/components/board/utilities/BoardEmbed.vue";
import BoardFilePreview from "@/components/board/utilities/BoardFilePreview.vue";

//import ObjectiveDetailed from "@/components/board/objective/ObjectiveDetailed.vue";

// board widgets

import BoardData from "@/components/board/widgets/BoardData.vue";

// Search test
import Test from "@/components/Test.vue";

import MasterLayout from "@/components/layout/MasterLayout.vue";
import PageNotFound from "@/components/404.vue";
import BoardMembers from "@/components/board/BoardMembers.vue";
import BoardActivityFilters from "@/components/board/widgets/BoardActivityFilters.vue";
import PublicTemplatesView from "@/components/templates/PublicTemplatesView.vue";
import WorkspacePinnedMessages from "@/components/workspace/WorkspacePinnedMessages.vue";
import WorkspaceBlocked from "@/components/workspace/WorkspaceBlocked.vue";
import Chat from "@/components/chat/Chat.vue";
import BoardShared from "@/components/board/utilities/BoardShared.vue";
import BoardNavigator from "@/components/board/BoardNavigator.vue";
import GraphGenerator from "@/components/GraphGenerator.vue";
import BoardGoal from "@/components/board/utilities/BoardGoal.vue";
import Partner from "@/components/partner/PartnersLayout.vue";
import CompletedObjectives from "@/components/objectives/CompletedObjectives.vue";
import BoardSettings from "@/components/board/utilities/BoardSettings.vue";
import UnreadWorkspaceNotification from "@/components/workspace/UnreadWorkspaceNotification.vue";
import AutoLinkBuilder from "@/components/auth/AutoLinkBuilder.vue";
import CreateBoard from "@/components/workspace/CreateBoard.vue";
import MyNorthstars from "@/components/workspace/MyNorthstars.vue";
import TemplatePreview from "@/components/workspace/TemplatePreview.vue";
import Checkout from "@/components/subscription/Checkout.vue";
import Invoices from "@/components/subscription/Invoices.vue";
import WorkspacePlanManager from "@/components/workspace/WorkspacePlanManager.vue";
import QuickSetup from "@/components/workspace/QuickSetup.vue";
import CheckoutFlow from "@/components/checkout/CheckoutFlow.vue";
import Plans from "@/components/subscription/Plans.vue";
import Overview from "@/components/workspace/Overview.vue";
import SubscriptionSuccessPage from "@/components/subscription/SubscriptionSuccessPage.vue";
import IndexOverview from "@/components/workspace/IndexOverview.vue";
import AhaMoment from "@/components/workspace/AhaMoment.vue";
import TrialEnd from "@/components/subscription/TrialEnd.vue";
import RoomTutorial from "@/components/auth/demo/RoomTutorial.vue";
import RegisterLayout from "@/components/auth/register/RegisterLayout.vue";
import RegistrationSteps from "@/components/auth/RegistrationSteps.vue";

import Marketplace from "@/components/workspace/marketplace/Marketplace.vue";
import LandingPageTodoList from "@/components/auth/register/landingpages/LandingPageTodoList.vue";
import LandingPageSmartGoals from "@/components/auth/register/landingpages/LandingPageSmartGoals.vue";
import LandingPageGTD from "@/components/auth/register/landingpages/LandingPageGTD.vue";
import LandingPageClient from "@/components/auth/register/landingpages/LandingPageClient.vue";
import LandingPageCollaborative from "@/components/auth/register/landingpages/LandingPageCollaborative.vue";
import LandingPagePersonalGoals from "@/components/auth/register/landingpages/LandingPagePersonalGoals.vue";
import LandingPageIndex from "@/components/auth/register/landingpages/LandingPageIndex.vue";


const router: VueRouter = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/slackconnect/callback',
      name: 'slack-callback',
      component: AddToSlackCallback
    },
    {
      path: '/authcallback/:network?',
      name: 'oauth-callback',
      component: OauthCallback
    },
    { path: '/create-workspace', name: 'workspace-create', component: WorkspaceCreate },
    { path: '/invite-members', name: 'members-invite', component: MembersInvite },
    { path: '/create-goal/:board_pid?', name: 'goal-create', component: GoalCreate },
    { path: '/login', name: 'login', component: Login, props: true },
    { path: '/reset', name: 'password-reset', component: PasswordReset, meta: { redirectToDashboard: true } },
    {
      path: '/onboarding',
      name: 'register-layout',
      redirect: { name: 'landing-page-index' },
      component: RegisterLayout,
      children: [
        { path: 'start', name: 'landing-page-index', component: LandingPageIndex },
        { path: 'to-do-lists', name: 'landing-page-todo-list', component: LandingPageTodoList },
        { path: 'smart-goals', name: 'landing-page-smart-goals', component: LandingPageSmartGoals },
        { path: 'personal-goals', name: 'landing-page-personal-goals', component: LandingPagePersonalGoals },
        { path: 'get-things-done', name: 'landing-page-gtd', component: LandingPageGTD },
        { path: 'collaborative', name: 'landing-page-collaborative', component: LandingPageCollaborative },
        { path: 'client', name: 'landing-page-client', component: LandingPageClient },
        { path: '/register/:step_id?', name: 'register', component: Register },
        { path: '/registration-steps', name: 'registration-steps', component: RegistrationSteps },
        { path: '/create-board',
          name: 'board-create',
          component: BoardCreate,
          children: [
            { path: 't/:template_id', name: 'template-preview-in-register', component: TemplatePreview },
          ]
        },
      ]
    },
    {
      path: '/auto-reg', redirect: { name: 'register-layout' },
    },
    {
      path: '/new-password',
      name: 'new-password',
      component: NewPasswordForm,
      meta: { redirectToDashboard: true },
    },
    { path: '/public', name: 'public-templates-view', component: PublicTemplatesView },
    { path: '/templates',
      name: 'templates-layout',
      component: TemplatesLayout,
      children: [
        { path: ':template_id', name: 'template', component: Template },
      ],
    },
    {
      path: '*',
      name: 'Not Found',
      component: PageNotFound,
    },
    {
      path: '/ws-blocked',
      name: 'WorkspaceBlocked',
      component: WorkspaceBlocked,
    },
    {
      path: '/test',
      name: 'Test',
      component: Test,
    },
    {
      path: '/room-tutorial/:workspace_pid?',
      name: 'room-tutorial',
      component: RoomTutorial,
    },
    {
      path: '/trial-end',
      name: 'Trial-end',
      component: TrialEnd,
    },
    {
      path: '/partner',
      name: 'Partner',
      meta: { requiresAuth: true },
      component: Partner,
    },
    {
      path: '/link-builder',
      name: 'auto-link-builder',
      component: AutoLinkBuilder,
    },
    {
      path: '/graph',
      name: 'Graph',
      component: GraphGenerator,
    },
    {
      path: '/create-avatar',
      name: 'create-avatar-page',
      meta: { requiresAuth: true },
      component: CreateAvatarPage,
    },
    {
      path: '/logout',
      component: Logout,
      name: 'logout',
      meta: { requiresAuth: true },
    },
    {
      path: '/delete/account',
      component: DeleteAccount,
      name: 'delete-account',
      meta: { requiresAuth: true },
    },
    {
      path: '',
      component: DashboardLayout,
      name: 'dashboard',
      meta: {
        requiresAuth: true,
        //requiresVerified: true,
        requireAccess: true,
      },
    },
    {
      path: '/board/overview',
      component: DashboardLayout,
      name: 'dashboard-overview',
      meta: {
        requiresAuth: true,
        //requiresVerified: true,
        requireAccess: true,
      },
    },
    { path: '/workspace-onboarding', name: 'workspace-onboarding', component: WorkspaceOnboarding },
    {
      path: '/i/:pid',
      name: 'invitation-view',
      component: InvitationView,
    },
    {
      path: '/checkout-start',
      name: 'checkout-flow',
      redirect: { name: 'plans' },
      component: CheckoutFlow,
      children: [
        { path: 'plans', name: 'plans', component: Plans },
        { path: 'checkout/:productId/:billingPeriod', name: 'workspace-checkout', component: Checkout },
      ]
    },
    {
      path: '/:workspace_pid/:objective_pid?',
      component: DashboardLayout,
      name: 'dashboard-layout',
      redirect: { name: 'workspace-layout' },
      meta: { requiresAuth: true, requireAccess: true },
      children: [
        {
          path: '/',
          component: WorkspaceLayout,
          redirect: { name: 'overview' },
          name: 'workspace-layout',
          children: [
            { path: 'all/:user_id?', name: 'workspace-all-objectives', component: WorkspaceAllObjectives },
            { path: 'invoices', name: 'invoices', component: Invoices},
            { path: 'setup', name: 'quick-setup', component: QuickSetup},
            { path: 'create-room',
              name: 'create-board',
              component: CreateBoard,
              children: [
                { path: ':template_id', name: 'template-preview', component: TemplatePreview },
              ]
            },
            {
              path: 'master',
              name: 'master-layout',
              redirect: { name: 'members-manager' },
              component: MasterLayout,
              children: [
                { path: 'my-northstars', name: 'my-northstars', component: MyNorthstars},
                { path: 'unread-notification', name: 'workspace-unread-notification', component: UnreadWorkspaceNotification},
                { path: 'members', name: 'members-manager', component: MembersManager },
                { path: 'pinned-message', name: 'workspace-pinned-messages', component: WorkspacePinnedMessages},
                { path: 'plan', name: 'workspace-plan-manager', component: WorkspacePlanManager},
                { path: 'files', name: 'files-manager', component: FilesManager },
              ]
            },
            { path: 'aha', name: 'aha-moment', component: AhaMoment },
            { path: 'overview',
              name: 'overview',
              component: Overview,
              children: [
                { path: 'index', name: 'index-overview', component: IndexOverview },
              ]
            },
            { path: 'marketplace', name: 'marketplace', component: Marketplace },
            {
              path: 'my-files',
              name: 'my-files',
              component: FilesManager,
            },
            {
              path: 'pinned',
              name: 'pinned',
              component: WorkspacePinnedMessages,
            },
            {
              path: 'confirmation',
              name: 'subscription-success-page',
              component: SubscriptionSuccessPage,
            },
            {
              path: ':board_pid',
              component: Room,
              name: 'room',
              children: [
                { path: 'notes', name: 'board-notes', component: BoardNotes },
                { path: 'call', name: 'start-call', component: StartCall },
                { path: 'apps', name: 'inner-apps', component: BoardApps },
                { path: 'embed', name: 'board-embed', component: BoardEmbed },
                { path: 'file', name: 'board-file-preview', component: BoardFilePreview },
                { path: 'statistics', name: 'board-data', component: BoardData },
                { path: 'invite', name: 'board-members', component: BoardMembers },
                { path: 'more', name: 'board-navigator', component: BoardNavigator },
                { path: 'activity', name: 'board-activity', component: BoardActivity },
                /*{ path: ':objective_pid', component: ObjectiveDetailed, name: 'board-with-objective', }, */
                { path: 'onboarding', name: 'onboarding-view', component: OnboardingView,},
                { path: 'completed', name: 'completed-objectives', component: CompletedObjectives },
                { path: 'apps', name: 'board-apps', component: BoardApps },
                { path: 'chat', name: 'chat', component: Chat },
                { path: 'shared', name: 'board-shared', component: BoardShared },
                { path: 'settings', name: 'board-settings', component: BoardSettings },

                {
                  path: 'overview',
                  meta: { requiresAuth: true },
                  component: Board,
                  redirect: { name: 'board-settings' },
                  name: 'board-layout',
                  children: [
                    { path: 'goals', name: 'board-goals', component: BoardGoals },
                    { path: 'filter', name: 'board-activity-filters', component: BoardActivityFilters },
                    { path: 'goal/:goal_id', name: 'related-goal', component: BoardGoal },
                  ]
                },
              ]
            },
          ]
        },
      ],
    },
  ],
});

router.beforeEach((to, _from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      return next({name: 'login', params: {redirect: to.path}});
    }
  }

  return next();
});

// router.beforeEach((to, _from, next) => {
//   if (to.matched.some(record => record.meta.requiresVerified)) {
//     if (store.getters.isAuthenticated && !store.getters.isVerified) {
//       return next({name: 'register', params: {step_id: 'verify'}});
//     }
//   }
//
//   return next();
// });

router.beforeEach((to, _from, next) => {
  if (to.matched.some(record => record.meta.redirectToDashboard)) {
    if (store.getters.isAuthenticated) {
      return next({ name: 'dashboard' });
    }
  }

  return next();
});

router.beforeEach((to, _from, next) => {
  if (to.matched.some(record => record.meta.requireAccess)) {
    if (store.getters.getActiveWorkspaceAccess == 1) {
      return next({name: 'WorkspaceBlocked'});
    }
  }


  return next();
});

export default router;
