import { render, staticRenderFns } from "./WorkspaceBlocked.vue?vue&type=template&id=47fdd0be&"
import script from "./WorkspaceBlocked.vue?vue&type=script&lang=ts&"
export * from "./WorkspaceBlocked.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports