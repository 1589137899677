










































import {Component, Prop, Vue} from 'vue-property-decorator';
import {AddBoardToMilestoneMutation, CreateMilestone} from "@/graphql/NorthStar";
import WorkableItem from "@/components/general/WorkableItem.vue";

@Component({
  components: {WorkableItem},
})
export default class PageNotFound extends Vue {
  name: string | null = '';
  description: string | null = '';
  status: string = 'not_started';
  due_date: Date | null = null;
  reward: string | null = null;

  wait: boolean = false;
  submitting: boolean = false;

  loadingMilestone: boolean = false;

  @Prop({ default: undefined, required: true })
  northStarId!: string;

  @Prop({ default: undefined, required: true })
  boardIdFromParent!: string;

  @Prop({ default: 0, required: true })
  milestoneCount!: number;

  mounted() {
    this.$nextTick(function () {
      //@ts-ignore ToDo: fix type issue
      this.$refs.milestoneInput.focus();
    });
  }

  get boardId() {
    if(this.$store.state.board) {
      return this.$store.state.board.id
    } else if(this.boardIdFromParent) {
      return this.boardIdFromParent
    } else {
      return null;
    }
  }

  addMilestone() {
    if (this.wait || !this.name) {
      return
    }
    this.wait = true;
    setTimeout(() => this.wait = false, 1000);
    this.loadingMilestone = true;

    if(this.name) {
      this.$apollo
        .mutate({
          mutation: CreateMilestone,
          variables: {
            name: this.name,
            description: this.description,
            status: this.status,
            due_date: this.due_date,
            north_star_id: this.northStarId,
            reward: this.reward,
            order: this.milestoneCount + 1
          },
        })
        .then((response) => {
          if(this.boardId) {
            this.connectToBoard(response.data.createMilestone.id);
          }else{
            this.$emit('submitted', { status:true,milestone: response.data.createMilestone});
          }
          
        })
      ;
    }
    this.name = null;
  }

  connectToBoard(milestoneId: any) {
    this.$apollo
      .mutate({
        mutation: AddBoardToMilestoneMutation,
        variables: {
          board_id: this.boardId,
          milestone_id: milestoneId
        },
      })
      .then((response) => {
        this.loadingMilestone = false;
        this.$events.fire('board-milestone-added', {'milestone':response.data.addBoardToMilestone, 'northStarId': this.$props.northStarId});
        this.$buefy.toast.open({
          message: 'Created!',
          position: 'is-bottom-right',
          type: 'is-black',
        });
      })
    ;
  }
}
