




























































import {Component, Vue} from 'vue-property-decorator';
import Template from "@/components/workspace/Template.vue";

@Component({
  components: {Template},
})
export default class WorkspaceBlocked extends Vue {

  get me() {
    return this.$store.state.me;
  }

  get workspace() {
    return this.$store.state.workspace;
  }

  get allFreeWorkspaces() {
    return this.me.workspaces.filter((workspace: any) => {
      return workspace.my_access == 0;
    })
  }

  switchToWorkspace(workspacePid: string) {
    this.$store.commit('set_active_workspace_access', 0);
    this.$router.push({ name: 'dashboard-layout', params: { workspace_pid: workspacePid } });
  }

  createNewWorkspace() {
    this.$router.push('/create-workspace');
  }

}
