






































import { Component, Vue, Prop } from "vue-property-decorator";
import { User } from "@/typescript/types";
import { GetPersonalBoardQuery } from "@/graphql/board";
import Roles from '@/roles';
import UserStatus from "@/components/user/UserStatus.vue";
import UserAvatar from "@/components/user/UserAvatar.vue";
import UserStatusText from "@/components/user/UserStatusText.vue";
import UserProfile from "@/components/user/profile/UserProfile.vue";
import UserProfileDetails from "@/components/user/UserProfileDetails.vue";

@Component({
  components: {
    UserAvatar, UserStatusText
  }
})
export default class WorkspaceMemberItem extends Vue {
  @Prop({ default: undefined, required: true })
  member!: User;

  @Prop({ default: undefined, required: false })
  index!: Number;


  get me() {
    return this.$store.state.me;
  }

  get workspacePid() {
    return this.$store.state.active_workspace_pid;
  }

  get activeBoardPid() {
    return this.$store.state.active_board_pid;
  }

  get checkMe(){
    if(this.$props.member.user.id === this.me.id){
      return true;
    }
    return false;
  }


  roleName(role: string) {
    return Roles.formateRole(role);
  }

  routeUserSchedule(member: any) {
    this.$router.push({
      name: 'workspace-all-objectives',
      params: {
        user_id: member.user.id,
        member_data: member
      }
    });
  }

  openProfileDetails() {
    if (this.$props.member.user.id === this.$store.state.me.id) {
      this.$buefy.modal.open({
        component: UserProfile,
        // @ts-ignore
        customClass: 'is-paddingless user_profile_modal',
        width: '700px',
        parent: this,
      });
    } else {
      this.$buefy.modal.open({
        component: UserProfileDetails,
        // @ts-ignore
        customClass: 'is-paddingless other_user_profile_modal',
        props: {userId: this.$props.member.user.id},
        width: '520px',
        parent: this,
      });
    }
  }

  public getPersonalBoard(id: any) {
    this.$apollo
      .query({
        query: GetPersonalBoardQuery,
        variables: {
          id: id,
          pid: this.workspacePid,
        },
      })
      .then((response) => {
        if(response.data.getPersonalBoard[0].pid !== this.activeBoardPid) {
          this.$store.commit('set_active_board_pid', null);
          this.$store.commit('set_board', response.data.getPersonalBoard[0]);
          this.$store.commit('set_active_board_pid', response.data.getPersonalBoard[0].pid);
        }
      })
      .catch((_response) => {
    
      });
  }

  public isOnline(id: any): boolean {
    return this.$store.state.online_user_ids.includes(id);
  }

  public openStatusModal() {
    this.$buefy.modal.open({
      component: UserStatus,
      // @ts-ignore
      customClass: 'is-paddingless',
      width: '520px',
      parent: this,
    });
  }
}
