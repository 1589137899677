




























































































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import CreateObjectiveTrigger from "@/components/objectives/CreateObjectiveTrigger.vue";
import WeeklyEngagement from "@/components/workspace/statistics/WeeklyEngagement.vue";
import ConfettiCanon from "@/components/general/ConfettiCanon.vue";
import {getWeek} from "date-fns";


@Component({
  components: {ConfettiCanon, WeeklyEngagement, CreateObjectiveTrigger},
})
export default class AhaMoment extends Vue {
  completedCount: number = 6;
  triggerConfetti: boolean = false;
  latestCompletedMessage: string | null = "Setup pembio account";
  currentMember: string | null = "You";
  currentWeek: any | null = null;
  goals: Array<string> = [
    'Holiday Sale Week',
    'Launch a blog 2023 ',
    'Summer 2024 Campaign',
  ];

  @Prop({ required: false, default: 2 })
  step!: number;

  @Prop({default: false, required: false})
  inRegister!: boolean;

  @Prop({default: null, required: false})
  roomName!: string;

  confettiOrigin() {
    const options = [
      { x: 0.8, y: 0.9 },
      { x: 0.9, y: 0.9 },
      { x: 0.9, y: 0.4 },
      { x: 0.9, y: 0.3 }
    ];

    // Generate a random index to select an option
    const randomIndex = Math.floor(Math.random() * options.length);

    // Return the randomly selected option
    return options[randomIndex];
  }

  colors: Array<string> = [
    '#7b7343',
    '#4a677d',
    '#773f75',
    '#bd5fb4',
    '#4a677d',
    '#374ea9',
    '#776015',
    '#2e825f',
    '#4e6068',
    '#66737a',
    '#717171',
    '#434343',
    'No color'
  ];

  tasks: Array<{ text: string; completed: boolean }> = [
    { text: 'Publish blog post', completed: false },
    { text: 'Notify stakeholders', completed: false },
    { text: 'Draw campaign ideas', completed: false },
    { text: 'Communicate internally', completed: false },
    { text: 'Book a partner workshop', completed: false },
    { text: 'Prepare copy for new ads', completed: false },
    { text: 'Inform john to check status', completed: false },
    { text: 'Set up workshop with Anna', completed: false },
    /*
    { text: 'Book the event venue', completed: false },
    { text: 'Request analytics', completed: false },
     */
  ];

  firstNames: Array<string> = [
    'Anna',
    'Uma',
    'Eric',
    'Adam',
  ];

  getRandMembers() {
    const randomIndex = Math.floor(Math.random() * this.firstNames.length);
    this.currentMember = this.firstNames[randomIndex];
  }

  // Create a method to update tasks with a delay
  async updateTasksWithDelay() {
    for (let i = 0; i < 2; i++) {
      await this.delay(1500); // Wait for 1.5 seconds
      await this.markAsCompleted(i);
    }
  }

  async markAsCompleted(index: any) {
    this.tasks[index].completed = true;
    this.latestCompletedMessage = this.tasks[index].text;
    this.getRandMembers();
    //this.playSound();
    this.completedCount++;
    this.triggerConfetti = true;
    await this.delay(1000); // Wait for 1 second
    this.tasks.splice(index, 1); // Remove the completed task from the list
    this.triggerConfetti = false
  }

  // Helper function to create a delay using Promises
  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  playSound() {
    if(this.$store.state.mute_notification_sound) {
      let audio = new Audio(require('@/assets/audio/initiative-check.mp3'));
      audio.volume = 0.1;
      audio.play();
    }
  }

  // Call the function when the component is mounted
  mounted() {
    this.updateTasksWithDelay();
    this.currentWeek = getWeek(new Date);
  }
}
