import gql from 'graphql-tag';
import {InitiativeDetailedFragment, KeyResultDetailedFragment, ObjectiveDetailedFragment} from "@/graphql/objective";
import {NorthStarFragment} from "@/graphql/NorthStar";
import {AttachmentDetailedFragment} from "@/graphql/attachment";
import {UserStatusDetailedFragment} from "@/graphql/auth";
import {BoardMessageDetailedFragment} from "@/graphql/board";
import {WorkspaceSubscriptionFragment} from "@/graphql/WorkspaceSubscription";
import {BookmarkDetailedFragment} from "@/graphql/bookmark";

export const WorkspaceListedBoardsFragment = gql`
    fragment WorkspaceListedBoards on Board {
        id
        pid
        name
        description
        public
        starred
        my_role
        permissions
        slackConnected
        order
        members {
            id
            user {
                id
                initials
                name
                avatar
                deleted_at
            }
        }
        boardLevel {
            identifier
        }
        ongoingObjectives {
            id
            status
            start_date
            due_date
        }
    }
`;

export const WorkspaceDetailedFragment = gql`
    fragment WorkspaceDetailed on Workspace {
        id
        pid
        name
        domain
        my_role
        my_access
        avatar
        encryption_key
        created_at
        invites {
            id
            pid
            invitee_email
            created_at
            role
            boards {
                id
                pid
                name
            }
            initiator {
                id
                name
                initials
                avatar
            }
        }
        members {
            id
            role
            user {
                id
                name
                first_name
                last_name
                email
                created_at
                deleted_at
                initials
                avatar
                timezone
                country
                country_code
                city
                status{
                    ...UserStatusDetailed
                }
                activeOnObjectives{
                    id
                    name
                    board {
                        id
                        members {
                            id
                            role
                            user {
                                id
                                name
                                email
                                deleted_at
                            }

                        }
                    }
                }
            }
            access_workspace
        }
        allMembers {
            id
            role
            user {
                id
                name
                first_name
                last_name
                email
                created_at
                deleted_at
                initials
                avatar
                status{
                    ...UserStatusDetailed
                }
                activeOnObjectives{
                    id
                    name
                    board {
                        id
                        members {
                            id
                            role
                            user {
                                id
                                name
                                email
                                deleted_at
                            }
                            
                        }
                    }
                }
            }
            access_workspace
        }
        owner {
            id
            name
            email
            initials
            avatar
            deleted_at
        }
        available_boards {
            id
            pid
            name
            description
            public
            permissions
            slackConnected
            members {
                id
            }
            boardLevel {
                identifier
            }
        }
        archive_boards {
            id
            pid
            name
            description
            public
            permissions
            slackConnected
            members {
                id
            }
            boardLevel {
                identifier
            }
        }
        boards {
            ...WorkspaceListedBoards
        }
        boardsinLevel1{
            ...WorkspaceListedBoards
        }
        boardsinLevel2{
            ...WorkspaceListedBoards
        }
        boardsinLevel3{
            ...WorkspaceListedBoards
        }
        northStars {
            ...NorthStarDetailed
        }
        attachments {
            ...AttachmentDetailed
        }
        pinnedMessages {
            ...BoardMessageDetailed
        }
        totalObjectivesCount
        
    }
    ${WorkspaceListedBoardsFragment}
    ${ObjectiveDetailedFragment}
    ${KeyResultDetailedFragment}
    ${InitiativeDetailedFragment}
    ${NorthStarFragment}
    ${AttachmentDetailedFragment}
    ${UserStatusDetailedFragment}
    ${BoardMessageDetailedFragment}
`;

export const WorkspaceLimitedDetailedFragment = gql`
    fragment WorkspaceLimitedDetailed on Workspace {
        id
        pid
        name
        domain
        my_role
        my_access
        avatar
        encryption_key
        created_at
        currentSubscription {
            ...WorkspaceSubscription
        }
        invites {
            id
            pid
            invitee_email
            created_at
            role
            boards {
                id
                pid
                name
            }
            initiator {
                id
                name
                initials
                avatar
            }
        }
        members {
            id
            role
            user {
                id
                name
                first_name
                last_name
                email                
                initials
                deleted_at
                created_at
                avatar
                timezone
                country
                country_code
                city
                status{
                    ...UserStatusDetailed
                }
                
                
            }
            access_workspace
        }
        allMembers {
            id
            role
            user {
                id
                name
                first_name
                last_name
                email                
                initials
                avatar
                deleted_at
                created_at
                status{
                    ...UserStatusDetailed
                }
                
               
            }
            access_workspace
        }
        owner {
            id
            name
            email
            initials
            avatar            
        }
        available_boards {
            id
            pid
            name
            description
            public
            permissions
            slackConnected
            members {
                id
            }
            boardLevel {
                identifier
            }
        }
        archive_boards {
            id
            pid
            name
            description
            public
            permissions
            slackConnected
            members {
                id
            }
            boardLevel {
                identifier
            }
        }
        boards {
            ...WorkspaceListedBoards
        }        
        pinnedMessages {
            ...BoardMessageDetailed
        }
        totalObjectivesCount

    }
    ${WorkspaceListedBoardsFragment} 
    ${UserStatusDetailedFragment}
    ${BoardMessageDetailedFragment}
    ${WorkspaceSubscriptionFragment}
`;

export const PrivateWorkspaceTemplateDetailedFragment = gql`
    fragment PrivateWorkspaceTemplatesDetailed on PublicBoardTemplate {
        id
        pid
        name
        description
        goal_method {
            id
            name
            description
        }
        public
        metrics {
            objectivesCount
            keyResultsCount
            keyResultsCompletedCount
            keyResultsOverallProgress
        }
        boardLevel {
            name
            identifier
        }
        permissions
        deleted_at
        template
        templateDetails {
            template_id
            template_type
            description
            short_description
            template_category {
                id
                name
                description
            }
            author_name
            author_description
            author_link
            author_role
            author_company
            cover
        }
        objectives {
            id
            name
            background_color
            objective_type {
                id
                name
                description
            }
            priority
            key_results {
                data {
                    id
                    description
                    start_value
                    target_value
                    unit
                    priority
                }
            }
        }
        northStars {
            id
            name
            description
            status
            due_date
            completed_at
            color
            is_public
        }
        attachments {
            id
            file_name
            file_type
            created_at
        }
        bookmarks {
            ...BookmarkDetailed
        }
    }
    ${BookmarkDetailedFragment}
`;

export const WorkspaceQuery = gql`
    query Workspace($id: ID, $pid: ID) {
        workspace(id: $id, pid: $pid) {
            ...WorkspaceLimitedDetailed
        }
    }
    ${WorkspaceLimitedDetailedFragment}
`;

export const CreateWorkspaceMutation = gql`
    mutation CreateWorkspace($name: String!, $domain: String!, $referral_code: String, $template_id: String) {
        createWorkspace(input: { name: $name, domain: $domain, referral_code: $referral_code, template_id: $template_id }) {
            id
            pid
            name
            domain
            encryption_key
            boards {
                id
                pid
                name
            }
            members {
                id
                role
                user {
                    id
                    name
                    first_name
                    last_name
                    email
                    created_at
                    deleted_at
                    initials
                    avatar
                    timezone
                    country
                    country_code
                    city
                    status{
                        ...UserStatusDetailed
                    }                    
                }
                access_workspace
            }
        }
    }
    ${UserStatusDetailedFragment}
`;

export const UpdateWorkspaceMutation = gql`
    mutation UpdateWorkspace($id: ID!, $avatar: String, $name: String) {
        updateWorkspace(id: $id, input: { avatar: $avatar, name: $name }) {
            id
            avatar
        }
    }
`;

export const WorkspaceAvailable = gql`
    query WorkspaceAvailable($name: String!) {
        workspaceAvailable(name: $name) {
            available
            domain
        }
    }
`;

export const HaveWorkspace = gql`
    query HaveWorkspace{
        haveWorkspace{
            present
        }
    }
`;

export const AddWorkspaceAttachments = gql `
    mutation addWorkspaceAttachments($workspace_id: ID! , $files: [Upload!]!) {
        addWorkspaceAttachments(
            input: {
                workspace_id: $workspace_id
                files: $files
            }
        ){
            ...AttachmentDetailed
        }
    }
    ${AttachmentDetailedFragment}
`;

export const CompletedObjectiveQuery = gql`
    query GetCompletedObjectives($workspace_id: ID, $board_id: ID, $first: Int, $page: Int) {
        getCompletedObjectives(workspace_id: $workspace_id, board_id: $board_id, first: $first, page: $page) {
            data {
                ...ObjectiveDetailed
            }
            paginatorInfo {
                total
                currentPage
                hasMorePages
            }
        }
    }
    ${ObjectiveDetailedFragment}
`;

export const CompletedObjectiveReactedSubscription = gql`
    subscription CompletedObjectiveReacted($workspace_id: ID) {
        completedObjectiveReacted(workspace_id: $workspace_id) {
            ...ObjectiveDetailed
        }
    }
    ${ObjectiveDetailedFragment}
`;

export const UpdateWorkspaceBoards = gql`
    mutation UpdateWorkspaceBoards($id: ID!, $boards: [UpdateBoardBulkInput!]) {
        updateWorkspaceBoards(
            id: $id,
            data: $boards           
        ){
            ...WorkspaceDetailed
        }
    }
    ${WorkspaceDetailedFragment}
`;

export const DeleteWorkspaceAvatar = gql`
    mutation DeleteWorkspaceAvatar($id: ID!) {
        deleteWorkspaceAvatar(
            workspace_id: $id,            
        ){
            id
        }
    }  
`;

export const PrivateWorkspaceTemplates = gql`
    query PrivateWorkspaceTemplates($id: ID!) {
        privateWorkspaceTemplates(id: $id) {
            ...PrivateWorkspaceTemplatesDetailed
        }
    }
    ${PrivateWorkspaceTemplateDetailedFragment}
`;

