






















import {Component, Vue} from 'vue-property-decorator';
import UserProfile from './profile/UserProfile.vue';

@Component({
  components: {UserProfile},
})
export default class UserFeedbackButton extends Vue {

  get workspace() {
    return this.$store.state.workspace;
  }

  get isPremium() {
    if(this.$store.state.workspace.currentSubscription) {
      return true
    } else { return false }
  }

  openFeedback() {
    this.$buefy.modal.open({
      component: UserProfile,
      // @ts-ignore
      customClass: 'is-paddingless user_profile_modal',
      props: {
        redirect: 'feedback'
      },
      width: '700px',
      parent: this,
    });
  }

}
