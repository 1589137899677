
























































































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import UserAvatar from '@/components/user/UserAvatar.vue';
import {BoardMessagesQuery, BoardQuery, CreateBoardMessageMutation, UpdateBoardMessageMutation} from '@/graphql/board';
import At from 'vue-at';
import Formatting from "@/components/chat/Formatting.vue";
import {BoardAttachments} from "@/graphql/attachment";
import BoardBookmarks from "@/components/board/utilities/BoardBookmarks.vue";
import {WorkspaceQuery} from "@/graphql/workspace";
import VoiceRecorder from "@/components/general/VoiceRecorder.vue";

@Component({
  components: {BoardBookmarks, Formatting, UserAvatar, At},
})
export default class PostComment extends Vue {
  moreActions: boolean = true;
  composeMode = false;
  wait = false;
  inputIsFocused = false;
  file: File | null = null;
  message: string | null = null;
  html: string | null = null;
  isEmojiSelectorOpen: boolean = false;
  attachments: Array<any> = [];
  chatMessageId: string | null = null;
  emojis: Array<{ icon: string, name: string }> = [
    {icon: '👍', name: '+1'},
    {icon: '👎', name: '-1'},
    {icon: '👋', name: 'wave'},
    {icon: '👏', name: 'clap'},
    {icon: '💪', name: 'strong'},
    {icon: '🙏', name: 'pray'},
    {icon: '😄', name: 'smile'},
    {icon: '😃', name: 'smiley'},
    {icon: '😉', name: 'wink'},
    {icon: '🤩', name: 'star_struck'},
    {icon: '😬', name: 'grimacing'},
    {icon: '😇', name: 'angel'},
    {icon: '😢', name: 'sad'},
    {icon: '😅', name: 'sweat_smile'},
    {icon: '🦄', name: 'unicorn'},
    {icon: '🎉', name: 'party_popper'},
    {icon: '🐶', name: 'dog'},
    {icon: '👽', name: 'alien'},
    {icon: '🎃', name: 'pumpkin'},
    {icon: '👻', name: 'ghost'},
  ];
  quickReplies: Array<{ name: string }> = [
    {name: 'Sweet!'},
    {name: 'Too easy'},
    {name: 'Got it!'},
  ];

  @Prop({default: true, required: false})
  access!: boolean;

  toggleMoreActions() {
    this.$emit('expand', true);
    this.moreActions = !this.moreActions;
    this.focusInput();
    setTimeout(() => {
      this.$emit('focused', true);
    }, 200);
  }

  get me() {
    return this.$store.state.me;
  }

  get board() {
    return this.$store.state.board;
  }

  get availableUsers() {
    let userArray = [];
    for (let i = 0; i < this.board!.members.length; i++) {
      userArray.push({name: this.board!.members[i].user.name, id: this.board.members[i].user.id});
    }
    return userArray;
  }
 
  openChatRoute() {
    this.$router.push({ name: 'chat' });
    this.moreActions = false;
  }

  advancedCompose() {
    this.composeMode = !this.composeMode;
    this.$nextTick(function () {
      // @ts-ignore
      this.$refs.edit.focus();
    });
  }

  toggleEmojiPicker() {
    this.isEmojiSelectorOpen = !this.isEmojiSelectorOpen;
  }

  focusInput() {
    this.inputIsFocused = true;
    this.composeMode = true;
    // @ts-ignore
    this.$refs.edit.focus();
    setTimeout(() => {
      this.$emit('focused', true);
    }, 200);
  }

  cancelCreate() {
    this.message = null;
    this.html = null;
    this.file = null;
    this.composeMode = false;
    this.moreActions = false;
    // @ts-ignore
    this.$refs.edit.innerHTML = '';
    this.inputIsFocused = false;
  }

  applyFormat(type: string) {
    // @ts-ignore
    document.execCommand(type);
    // @ts-ignore
    let e = document.createEvent('HTMLEvents');
    // @ts-ignore
    e.initEvent('input', true, true);
    // @ts-ignore
    this.$refs.edit.dispatchEvent(e);
  }

  insertAt(type: any) {
    this.setCaret(this.$refs.edit);
    // @ts-ignore
    document.execCommand('insertText', 0, ' ' + type);
    // @ts-ignore
    let e = document.createEvent('HTMLEvents');
    // @ts-ignore
    e.initEvent('input', true, true);
    // @ts-ignore
    this.$refs.edit.dispatchEvent(e);
  }

  setCaret(refs: any) {
    let range = document.createRange();
    let sel = window.getSelection();
    let lastPosition = refs.childNodes.length;
    if (lastPosition != 0) {
      range.setStart(refs.childNodes[lastPosition - 1], refs.childNodes[lastPosition - 1].data.length);
      range.collapse(true);
            sel?.removeAllRanges();
            sel?.addRange(range);
    }
    refs.focus();
  }



  postBoardChatMessage() {
    if (this.wait) {
      return
    }
    this.wait = true;
    setTimeout(() => this.wait = false, 1000);


    //@ts-ignore
    this.message = this.$refs.edit.innerHTML;

    if(this.chatMessageId){
      this.postEditMessage();
      return;
    }


    let randId = Math.random().toString(36).substring(7)

    this.$emit('new-message', {
      id: randId,
      board: this.board,
      sender: this.me,
      edited: false,
      created_at: new Date(),
      updated_at: new Date(),
      message: this.message,
      messageFormatted: this.message,
      reactionSummaries: [],
      reactionsCount: 0,
      reactions : [],
      pinnedUsers: [],
      attachments : this.attachments,
      __typename: "BoardMessage",
    });

    let message = this.message;

    this.cancelCreate();

    this.$apollo.mutate({
      mutation: CreateBoardMessageMutation,
      variables: {
        id: this.board.id,
        message: message,
        files: this.attachments,
      },
      // optimisticResponse: {
      //   createBoardMessage: {
      //     id: randId,
      //     board: this.board,
      //     sender: this.me,
      //     edited: false,
      //     created_at: new Date(),
      //     updated_at: new Date(),
      //     deleted_at: null,
      //     message: this.message,
      //     messageFormatted: this.message,~
      //     reactionSummaries: [],
      //     reactionsCount: 0,
      //     reactions : [],
      //     pinnedUsers: [],
      //     attachments : this.attachments,
      //     __typename: "BoardMessage",
      //   }
      // },
      refetchQueries:[{query: BoardMessagesQuery, variables: {id: this.board.id}},{query: BoardQuery, variables:{pid: this.board.pid}},{query: WorkspaceQuery, variables:{pid: this.$store.state.workspace.pid}},{query: BoardAttachments, variables:{board_id: this.board.id}}]
    }).then((result) => {

      this.$emit('update-message', {
        boardMessage: result?.data?.createBoardMessage,
        id: randId
      });
    }).finally(() => {
      this.attachments = [];
      // @ts-ignore
      this.$refs.edit.focus();
    });
  }

  postEditMessage() {
    this.$apollo.mutate({
      mutation: UpdateBoardMessageMutation,
      variables: {
        id: this.chatMessageId,
        message: this.message,
        files: this.attachments,
      },
      refetchQueries:[{query: BoardMessagesQuery, variables: {id: this.board.id}},{query: BoardQuery, variables:{pid: this.board.pid}},{query: WorkspaceQuery, variables:{pid: this.$store.state.workspace.pid}}]
    }).then((result) => {
      this.$emit('update-message', {
        boardMessage: result.data.updateBoardMessage,
        id: this.chatMessageId
      });
    }).finally(() => {
      this.attachments = [];
      this.chatMessageId = null;
      // @ts-ignore
      this.$refs.edit.innerHTML = '';
      this.$emit('expand', false);
      this.moreActions = !this.moreActions;
      // @ts-ignore
      this.$refs.edit.blur();
    });
  }

  selectEmoji(emoji: any){
    this.insertAt(emoji.data);
    this.isEmojiSelectorOpen = false;
  }

  uploadChatFiles() {
    this.postBoardChatMessage();
  }

  get keymap() {
    return {
      // 'esc+ctrl' is OK.  - OBSERVE: don't add parenthesis at the end of the function triggered.. This messes things up.
      'meta+k': this.openVoiceRecord,
    }
  }

  openVoiceRecord() {
    this.$buefy.modal.open({
      component: VoiceRecorder,
      events: {
        'recorded-file': (file: any) => {
          this.attachments = [file.file];
          this.postBoardChatMessage();
        }
      },
      parent: this,
      width: '500px'
    });
  }


  @Watch('$store.state.active_board_pid', {immediate: true})
  boardChanged(_board_pid: string, _old_board_pid: string) {
    if (this.$refs.edit) {
      this.cancelCreate();
    }
  }

  handleOutsideClick(event: any) {
    const emojiPicker = this.$refs.emojiPicker;
    // @ts-ignore
    let targetElement = emojiPicker instanceof Element ? emojiPicker : emojiPicker?.$el;


    if (targetElement && !targetElement.contains(event.target) && this.isEmojiSelectorOpen) {
      this.isEmojiSelectorOpen = false;
    }
  }

  beforeDestroy() {
    document.removeEventListener('click', this.handleOutsideClick);
  }

  mounted() {
    document.addEventListener('click', this.handleOutsideClick);
    // @ts-ignore
    document.querySelector("div[contenteditable]").addEventListener("paste", function (e) {
      e.preventDefault();
      // @ts-ignore
      let text = e.clipboardData.getData("text/plain");
      document.execCommand("insertHTML", false, text);
    });



    if(this.access) {
      this.focusInput();
    }

    this.$events.listen('edit-message', (eventData => {
      this.$emit('expand', true);
      this.moreActions = true;
      this.focusInput();
      if(eventData.messageId) {
        this.chatMessageId = eventData.messageId;
      }
      if(eventData.message) {
        //@ts-ignore
        this.message = this.$refs.edit.innerHTML = eventData.message;
        this.setCaret(this.$refs.edit);
      }
    }))
  }
}
