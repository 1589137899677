import gql from 'graphql-tag';
import {ObjectiveDetailedFragment} from "@/graphql/objective";

export const NorthStarSmallFragment = gql`
    fragment NorthStarSmallDetailed on NorthStar {
        id
        name
        color
        due_date
        start_date
        status
        description
        is_public
        boards {
            id
            pid
        }
        objectives {
            id
            pid
            name           
            status
            created_at
            priority
            is_recurring
            order
            background_color
            start_date
            due_date
            deleted_at
            completed_at
        }
    }
`;


export const NorthStarFragment = gql`
    fragment NorthStarDetailed on NorthStar {
        id
        name
        description
        status
        due_date
        start_date
        completed_at
        color
        is_public
        ws_order
        b_order
        workspace {
            id
        }
        boards {
            id
            pid
            name
        }
        objectives {
            ...ObjectiveDetailed
        }
        milestones {
            id
            name
            description
            order
            creator {
                id
                name
                email
                avatar
                deleted_at
            }
            created_at
            status
            due_date
            reward
            boards {
                id
                pid
                name
            }
        }
        objective_suggestions {
            ...ObjectiveDetailed
        }
        milestone_suggestions {
            id
            name
            description
            order
            creator {
                id
                name
                email
                avatar
                deleted_at
            }
            created_at
            status
            due_date
            reward
            boards {
                id
                pid
                name
            }
        }
    }
    ${ObjectiveDetailedFragment}
`;

export const MilestoneDetailedFragment = gql`
    fragment MilestoneDetailed on Milestone {
        id
        name
        description
        north_star {
            ...NorthStarDetailed
        }
        creator {
            id
            name
            email
            avatar
            deleted_at
        }
        created_at
        status
        due_date
        reward
        boards {
            id
            pid
            name
        }
    }
    ${NorthStarFragment}
`;

export const CreateMilestone = gql`
    mutation createMilestone(
        $name: String!
        $description: String
        $north_star_id: ID!
        $reward: String
        $status: MilestoneStatus!
        $due_date: DateTime
        $order: Int
    ) {
        createMilestone(
            input: {
                name: $name
                description: $description,
                north_star: {connect: $north_star_id}
                reward: $reward,
                status: $status,
                due_date: $due_date
                order: $order
            }
        ) {
            ...MilestoneDetailed
        }
    }
    ${MilestoneDetailedFragment}
`;

export const UpdateMilestoneMutation = gql`
    mutation updateMilestone($id: ID!, $name: String!, $description: String, $reward: String, $status: MilestoneStatus!, $due_date: DateTime, $order: Int ) {
        updateMilestone(
            id: $id
            input: {
                name: $name
                description: $description,
                reward: $reward,
                status: $status,
                due_date: $due_date,
                order: $order
            }
        ) {
            id
        }
    }
`;

export const AddBoardToMilestoneMutation = gql`
    mutation addBoardToMilestone(
        $board_id: ID!
        $milestone_id: ID!
    ) {
        addBoardToMilestone(
            board_id: $board_id
            milestone_id: $milestone_id
        ) {
            ...MilestoneDetailed
        }
    }
    ${MilestoneDetailedFragment}
`;

export const AddObjectiveToNorthStar = gql`
    mutation updateNorthStar($id: ID! $objectives: [ID!]) {
        updateNorthStar(
            id: $id
            input: {
                objectives: { 
                    sync: $objectives
                }
            }
        ) {
            ...NorthStarDetailed
        }
    }
    ${NorthStarFragment}
`;

export const UpdateNorthStarDescription = gql`
    mutation updateNorthStar($id: ID! $description: String, $status: String, $due_date: DateTime, $start_date: DateTime, $name: String, $color: String, $completed_at: DateTime, $is_public: Boolean) {
        updateNorthStar(
            id: $id
            input: {
                name: $name
                description: $description
                status: $status
                due_date: $due_date
                start_date: $start_date
                color: $color
                completed_at: $completed_at
                is_public: $is_public
            }
        ) {
            ...NorthStarDetailed
        }
    }
    ${NorthStarFragment}
`;

export const AddBoardToNorthStar = gql`
    mutation updateNorthStar($id: ID!, $board: [ID!]) {
        updateNorthStar(
            id: $id
            input: {
                boards: { sync: $board }
            }
        ) {
            ...NorthStarDetailed
        }
    }
    ${NorthStarFragment}
`;

export const CreateNorthStar = gql`
    mutation createNorthStar($workspace: ID!, $board: ID!, $name: String!, $start_date: DateTime, $color: String, $isPublic: Boolean) {
        createNorthStar(
            input: {
                workspace: {connect: $workspace}
                boards: {sync: [$board]}
                name: $name
                color: $color,
                start_date: $start_date
                is_public: $isPublic
            }
        ) {
            ...NorthStarDetailed
        }
    }
    ${NorthStarFragment}
`;

export const CreateNorthStarWithoutBoard = gql`
    mutation createNorthStar($workspace: ID!, $name: String!, $color: String, $due_date: DateTime, $start_date: DateTime, $isPublic: Boolean) {
        createNorthStar(
            input: {
                workspace: {connect: $workspace}
                name: $name,
                color: $color
                due_date: $due_date
                start_date: $start_date
                is_public: $isPublic
            }
        ) {
            ...NorthStarDetailed
        }
    }
    ${NorthStarFragment}
`;

export const DeleteNorthStarMutation = gql`
    mutation deleteNorthStar($id: ID!) {
        deleteNorthStar(id: $id) {
            id
        }
    }
`;

export const DeleteMilestoneMutation = gql`
    mutation deleteMilestone($id: ID!) {
        deleteMilestone(id: $id) {
            id
        }
    }
`;

export const NorthStars = gql`
    query northStars($board_id: ID!, $workspace: Boolean!) {
        getNorthStars(board_id: $board_id, workspace: $workspace) {
            ...NorthStarDetailed
        }
    }
    ${NorthStarFragment}
`;

export const RemoveBoardFromMilestone = gql `
    mutation removeBoardFromMilestone($board_id: ID!, $milestone_id: ID!){
        removeBoardFromMilestone(board_id: $board_id, milestone_id: $milestone_id){
            id            
        }
    }
`;

export const SingleNorthStar = gql`
    query singleNorthStar($goal_id: ID!) {
        getNorthStar(goal_id: $goal_id) {
            ...NorthStarDetailed
        }
    }
    ${NorthStarFragment}
`;

export const CopyNorthStar = gql`
    mutation copyNorthStar($id: ID!, $board_id: ID, $workspace_id: ID!) {
        copyNorthStar(
            id: $id
            board_id: $board_id,
            workspace_id: $workspace_id
        ) {
            ...NorthStarDetailed
        }
    }
    ${NorthStarFragment}
`;

export const CopyObjectiveForNorthStar = gql`
    mutation copyObjectiveForNorthstar($northstar_id: ID!, $objective_id: ID!, $board_id: ID!) {
        copyObjectiveForNorthstar(
            northstar_id: $northstar_id
            objective_id: $objective_id  
            board_id: $board_id
        ) {
            ...ObjectiveDetailed
        }
    }
    ${ObjectiveDetailedFragment}
`;

export const CopyMilestoneForNorthStar = gql`
    mutation copyMilestoneForNorthstar($northstar_id: ID!, $milestone_id: ID!, $board_id: ID!) {
        copyMilestoneForNorthstar(
            northstar_id: $northstar_id
            milestone_id: $milestone_id
            board_id: $board_id
        ) {
            ...MilestoneDetailed
        }
    }
    ${MilestoneDetailedFragment}
`;

export const GetCompletedNortStarCount = gql`
    query getCompletedNorthStarCount($workspace_id: ID!, $board_id: ID, $start_date: DateTime!, $end_date: DateTime!, $userId: ID) {
        getCompletedNorthStarOnDateRange(
            workspace_id: $workspace_id
            board_id: $board_id
            start_date: $start_date
            end_date: $end_date
            userId: $userId
        ) {
           data{
               ...NorthStarDetailed
           } 
            paginatorInfo {
                total
                currentPage
                hasMorePages
            }
        }
    }
    ${NorthStarFragment}
`;

export const MyWorkspaceNorthstars = gql`
  query myNorthstarsInWorkspace($workspace_id: ID!, $board_id: ID, $user_id: ID, $start_date: DateTime, $end_date: DateTime, $status: String, $sortByDateType: String, $first: Int, $page: Int ) {
      myNorthstarsInWorkspace(
          workspace_id: $workspace_id,
          board_id: $board_id,
          user_id: $user_id
          start_date: $start_date,
          end_date: $end_date,
          status: $status,
          first: $first, 
          page: $page
          sortByDateType: $sortByDateType
      ){
          data{
              id
              name
              color
              due_date
              start_date
              status
              description
              is_public
              boards {
                  id
                  pid
              }
              
              ws_order
              b_order
              objectives {
                  paginatorInfo {
                      total
                      currentPage
                      hasMorePages
                  }
                  data {
                      id
                      pid
                      name
                      description
                      status
                      created_at
                      priority
                      is_recurring
                      order
                      background_color
                      start_date
                      due_date
                      deleted_at
                      completed_at
                      owner {
                        id
                        name
                        full_name
                        first_name
                      }
                      northStars {
                          id
                          name
                          description
                          color
                      }
                  }
              }

              objective_suggestions {
                  id
                  name
                  description
              }
          }
          paginatorInfo {
              total
              currentPage
              hasMorePages
          }
      }
  }
 
`;

export const UpdateNorthstarOrder = gql`
    mutation updateNorthstarOrder($orderType: String!, $northStarIds: [ID!]!) {
        updateNorthstarOrder (
            input: {
                orderType: $orderType
                northStarIds: $northStarIds
            }
        )
    }
  
`;
