













































import {Component, Vue, Watch} from 'vue-property-decorator';
import WorkspaceSidebar from "@/components/navigation/WorkspaceSidebar.vue";
import UserSidebar from "@/components/navigation/UserSidebar.vue";
import WorkspaceSidebarLoader from "@/components/loaders/WorkspaceSidebarLoader.vue";
import {WorkspaceQuery} from '@/graphql/workspace';
import MobileUserMenu from "@/components/navigation/MobileUserMenu.vue";
import PusherService from '@/PusherService';
import gql from "graphql-tag";
import {Objective as ObjectiveObject, WorkspaceMember} from "@/typescript/types";
import {UserStatusDetailedFragment} from "@/graphql/auth";
import Template from "@/components/templates/Template.vue";
import UserLayoutMenu from "@/components/user/UserLayoutMenu.vue";
import {BoardQuery} from "@/graphql/board";
import ObjectiveDetailed from "@/components/board/objective/ObjectiveDetailed.vue";
import VerifyUser from "@/components/auth/VerifyUser.vue";

@Component({
  components: {
    UserLayoutMenu,
    Template,
    UserSidebar,
    WorkspaceSidebar,
    WorkspaceSidebarLoader,
    MobileUserMenu,
  },
  apollo: {
    workspace: {
      query: WorkspaceQuery,
      fetchPolicy: 'network-only',
      subscribeToMore: [
        {
          document: gql`
                        subscription StatusUpdated {
                            statusUpdated {
                                ...UserStatusDetailed

                            }
                        }
                        ${UserStatusDetailedFragment}
                    `,

          updateQuery: (previous, {subscriptionData}) => {
            let previousData = previous.workspace;
            let newStatusData = subscriptionData.data.statusUpdated;
            let index = previousData.members.findIndex((workspaceMember: WorkspaceMember) => {
              return workspaceMember.user.id === newStatusData.user_id;
            });


            if(index!=-1){
              if(previousData.members[index].user.status != null && previousData.members[index].user.status.tenure === newStatusData.tenure && previousData.members[index].user.status.status_text === newStatusData.status_text){
                previousData.members[index].user.status = null;
              }else{
                previousData.members[index].user.status= newStatusData;
              }
            }

            return {
              workspace: previousData,
            };
          },
        }

      ],
      variables() {
        return {
          pid: this.$route.params.workspace_pid,
        };
      },
      skip() {
        return !this.$route.params.workspace_pid
      },
      update(result) {
        if (result.workspace) {
          this.$store.commit('set_active_workspace_access', result.workspace.my_access);
          if(!this.$store.state.active_board_pid) {
            if (this.$router.currentRoute.name === 'dashboard-layout' || this.$router.currentRoute.name === 'workspace-all-objectives') {
              this.$router.push({
                name: 'workspace-all-objectives'
              }).catch(()=>{});
            }
          } /* else {
            let path = '/w/'+this.workspacePid+'/'+this.$store.state.active_board_pid;
            if(this.$route.path != path){
              this.$router.push({
                name: 'board-layout',
                params: { board_pid: this.$store.state.active_board_pid }
              });
            }
          }
          */

          this.$store.commit('set_workspace', result.workspace);
          this.$store.commit('set_active_workspace_pid', result.workspace.pid);

          return result.workspace;
        }
      },
      error(error) {
        if(error.graphQLErrors[0].message) {
          this.$buefy.toast.open({
            message: error.graphQLErrors[0].message,
            position: 'is-bottom-right',
            type: 'is-black',
          });

          this.$router.go(-1);
        }
      }
    },
  },
})
export default class DashboardLayout extends Vue {
  userSidebarActive = true;
  currentRouteName = this.$router.currentRoute.name;
  hasDarkMode: boolean = false;
  isBoardMember: boolean = true;
  checkIfBrowserNotificationIsActive: boolean = true;

  get meObject() {
    return this.$store.state.me;
  }

  get onGuestAccount() {
    return this.$store.state.me && this.$store.state.me.isFake;
  }

  get boardPid() {
    return this.$store.state.active_board_pid;
  }

  get workspacePid() {
    return this.$store.state.active_workspace_pid;
  }

  get workspace() {
    return this.$store.state.workspace
  }

  get board() {
    return this.$store.state.board
  }

  get currentlyOnboarding() {
    return !!(this.$store.state.currently_onboarding || this.$store.state.currently_onboarding_in_room);
  }

  openBrowserNotification(){
    Notification.requestPermission().then(() => {

      this.checkIfBrowserNotificationIsActive = true;

    });
  }

  openObjectiveDetailed(objectivePid: string) {
    PusherService.objectiveSubscription(objectivePid);

    let modal = this.$buefy.modal.open({
      component: ObjectiveDetailed,
      props: {
        objectivePid: objectivePid
      },
      events: {
        'objective-updated': (objective: ObjectiveObject) => {
          
          this.$emit('objective-updated', objective)
          // if(this.isAllObjectiveLoaded) {
          //   this.getObjectives('all');
          // }else{
          //   this.getObjectives('ongoing');
          // }

        }
      },
      // @ts-ignore
      customClass: 'objective-modal',
      parent: this,
      animation: 'none',
    });

    modal.$on("close", () => {
      PusherService.unsubscribeObjectiveSubscription(objectivePid);
      // if(this.$route.params.obj_id) {
      //   this.$router.replace('/'+this.workspace.pid+'/'+this.boardPid+'/overview/settings')
      // }
    });

  }

  mounted() {
    const workspacePidRoute = this.$router.currentRoute.params.workspace_pid;

    if (!workspacePidRoute) {
      this.$router.replace({
        name: 'dashboard-layout', params: {
          workspace_pid: this.workspacePid
        }
      }).catch(()=>{});
    }

    if(this.$route.params.objective_pid) {
      this.openObjectiveDetailed(this.$route.params.objective_pid)
    }

    if(this.workspace && this.workspace.members.length > 1) {
      PusherService.workspaceSubscription(this.workspacePid);
    }


    this.$events.listen('is-board-member', eventData =>{
      this.isBoardMember = eventData;
    })

    if (Notification.permission == "granted" || Notification.permission == "denied") {
      this.checkIfBrowserNotificationIsActive = true;
    }

    this.$events.listen('refetch-workspace', (_) => {
      this.$apollo.queries.workspace.refetch({pid: this.$store.state.workspace.pid});
    })

    this.$events.listen('open-browser-notofication-permission', (_) => {
      this.checkIfBrowserNotificationIsActive = false;
    })

  }

  get keymap() {
    return {
      // 'esc+ctrl' is OK.  - OBSERVE: don't add parenthesis at the end of the function triggered.. This messes things up.
      'meta+b': this.darkModeToggle,
    }
  }

  darkModeToggle() {
    if(this.$route.name === 'workspace-pinned-messages') {
      this.hasDarkMode = !this.hasDarkMode;
    }
  }

  viewInvite(pid: string) {
    this.$router.push({name: 'invitation-view', params: {pid: pid}});
  }


  claimAccount() {
    this.$gtag.event('pmb_guest_account_claim_forced');
    this.$buefy.modal.open({
      component: VerifyUser,
      props: {
        claimWs: (this.meObject.isFake) ?? false,
        clickedClaim: true
      },
      width: '5000px',
      canCancel: false,
      parent: this,
    });
  }


  joinBoard() {
    this.$apollo.mutate({
      mutation: gql`
              mutation JoinBoard($id: ID!) {
                  joinBoard(id: $id) {
                      id
                      pid
                      name
                  }
              }`,
      variables: {
        id: this.$store.state.board.id
      },
      refetchQueries: [{query: WorkspaceQuery, variables: {pid: this.$store.state.active_workspace_pid}},{query: BoardQuery, variables:{pid: this.$route.params.board_pid}}],
    })
      .then(() => {
        this.isBoardMember = true;
        //this.$events.fire('is-board-member', this.isMember);
      })
      .catch(() => {
       
      })
      .finally(() => {
        this.$buefy.toast.open({
          message: 'Joined successfully!',
          position: 'is-bottom-right',
          type: 'is-black',
        });
      });


  }



  @Watch('$store.state.active_workspace_pid', {immediate: true})
  workspaceChanged(workspace_pid: string, old_workspace_pid: string) {

    if (old_workspace_pid) {
      PusherService.unsubscribeWorkspaceSubscription(old_workspace_pid);
    }
    if (workspace_pid && this.workspace.members.length > 1) {
      PusherService.workspaceSubscription(workspace_pid);
    }
  }

  @Watch ( '$route', { immediate: true, deep: true })
  onRouteChange(to: string, from: any){
    //@ts-ignore
    if(to['name'] != 'board-layout'){
      this.isBoardMember = true;
    }
    //@ts-ignore
    if(!!from && from['name'] == 'board-layout') {
      PusherService.unsubscribeBoardSubscription(from['params']['board_pid']);
    }
  }
}
