






















import {Component, Vue} from 'vue-property-decorator';
import MasterDashboard from "@/components/workspace/MasterDashboard.vue";
import UserProfile from "@/components/user/profile/UserProfile.vue";

@Component({
  components: {MasterDashboard, UserProfile},
})
export default class Overview extends Vue {

  get workspace() {
    return this.$store.state.workspace;
  }

  get singleRoomWorkspace() {
    return this.workspace.boards.length === 1;
  }

  mounted() {
    if (this.singleRoomWorkspace) {
      const firstRoom = this.workspace.boards[0].pid;
      this.$router.push({ name: 'room', params: { board_pid: firstRoom} });
    }

    if(this.$route.query.profile == '1') {
      this.$buefy.modal.open({
        component: UserProfile,
        // @ts-ignore
        customClass: 'is-paddingless user_profile_modal',
        width: '700px',
        parent: this,
      });
    }
  }
}
