













































































import { Component, Vue } from 'vue-property-decorator';
import RegisterLayout from "@/components/auth/register/RegisterLayout.vue";
import { CreateWorkspaceInviteMutation } from '@/graphql/workspace_invite';
import { WorkspaceQuery } from '@/graphql/workspace';

@Component({
  components: {RegisterLayout},
})
export default class MembersInvite extends Vue {
  inviteeRoleType: string | null = 'member';
  inviteeEmail: string | null = null;
  referralCode: string | null = null;

  get workspacePid() {
    return this.$store.state.active_workspace_pid;
  }

  get workspace() {
    return this.$store.state.workspace;
  }

  get boardId() {
    return this.$store.state.board.id;
  }

  get boardPid() {
    return this.$store.state.board.pid;
  }

  launchApp(pid: string) {
    this.$router.push({
      name: 'board-layout', params: {
        board_pid: pid,
        workspace_pid: this.workspacePid
      }
    });
  }

  create() {
    this.$apollo
      .mutate({
        mutation: CreateWorkspaceInviteMutation,
        variables: {
          workspace: this.workspace.id,
          invitee_email: this.inviteeEmail,
          role: this.inviteeRoleType,
          boards: this.boardId,
        },
        refetchQueries: [{ query: WorkspaceQuery, variables: { pid: this.$store.state.active_workspace_pid } }],
      })
      .then(_ => {
        this.inviteeEmail = null;
        this.$buefy.toast.open({
          message: 'Created invite!',
          position: 'is-bottom-right',
          type: 'is-black',
        });
      })
  }

  mounted() {

  }
}
