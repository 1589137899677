








import { Component, Vue, Prop } from 'vue-property-decorator';
import LoginForm from '@/components/auth/login/LoginForm.vue';

@Component({
  components: { LoginForm },
})
export default class LoginModal extends Vue {
  @Prop({ required: false })
  email!: string;

  /* Needed to to be able to call `this.$parent.close()` below,
     * see https://github.com/buefy/buefy/issues/1184
     */
  public $parent: any;

  loggedIn() {
    this.$parent.close();
  }
}
