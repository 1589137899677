






































































  
import {Component, Vue} from 'vue-property-decorator';
import {feedback_types} from "@/graphql/feedback";
import {CreateFeedbackMutation} from '@/graphql/feedback';
import {UpdateUserMutation, MeQuery} from "@/graphql/auth";

@Component({
  apollo: {
    feedback_types: {
      query: feedback_types,
      result(_result) {
        this.feedbackTypeId = _result.data.feedback_types[0].id;
      }
    },
  },
  components: {},
})

export default class Downgrade extends Vue {
  loading: boolean = false;
  downgradeLoading: boolean = false;
  canDowngrade: boolean = false;

  feedbackTitle: string = "!!User opted out from hard block!! User: " + this.me.name + "  Email: " + this.me.email + " User: " + this.me.name + "  Email: " + this.me.email + " Total activites: " + this.activitesCreated + " Account created: " + this.me.created_at;;
  feedbackDescription: string | null = null;
  feedbackTypeId: string = ""

  feedbacks: string = "";
  onStep: number = 1;

  get workspace() {
    return this.$store.state.workspace
  }

  get activitesCreated() {
    return this.workspace.totalObjectivesCount
  }

  get workspaceId() {
    return this.$store.state.workspace.id;
  }

  get meId() {
    return this.$store.state.me.id;
  }

  get meScore() {
    return this.$store.state.me.engagement_score;
  }

  get me() {
    return this.$store.state.me;
  }

  nextStep()  {
    if(this.onStep === 2) {
      this.closeModal();
      this.$router.push({ name: 'dashboard-layout' })
    }
    this.onStep++;
  }


  submitFeedback() {
    this.$gtag.event('pmb_user_feedback_submitted');
    this.$apollo
      .mutate({
        mutation: CreateFeedbackMutation,
        variables: {
          creator_id: this.meId,
          workspace_id: this.workspaceId,
          feedback_type_id: this.feedbackTypeId,
          title: this.feedbackTitle,
          description: this.feedbackDescription
        },
      })
      .then(() => {
        this.setUserAccess();
        this.$gtag.event('pmb_user_plan_opt_out');
        this.feedbackTitle = "";
        this.feedbackDescription = "";
        this.$buefy.toast.open({
          message: 'Submitted!',
          position: 'is-bottom-right',
          type: 'is-black',
        });

        //@ts-ignore
        this.nextStep();
      });
  }

  setUserAccess() {
    this.$apollo
      .mutate({
        mutation: UpdateUserMutation,
        variables: {
          id: this.$store.state.me.id,
          has_freemium_access: true,
        },
        awaitRefetchQueries: true,
        refetchQueries: [{query: MeQuery}],
      })
      .then(() => {
        this.$buefy.toast.open({
          message: 'Access updated!',
          position: 'is-bottom-right',
          type: 'is-black',
        });
      })
      .catch(error => {
        console.error('mutation failed', error);
      })
  }
 

  routeToCheckout() {
    this.$router.push({name: 'checkout-flow' });
    //@ts-ignore
    this.$parent.close();
  }

  downgradeAccount() {
    this.downgradeLoading = true
    this.$store.commit('set_trial_ended', true); 
    setTimeout(() => {
      this.$router.push({name: 'workspace-layout'});
      //@ts-ignore
      this.$parent.close();
    }, 1000);
    
  }
  
  mounted() {
    this.$gtag.event('pmb_app_downgrade_modal_mounted');
  }
  
  closeModal() {
    // @ts-ignore
    this.$parent.close() 
  }
}
