

































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ConfirmDelete extends Vue {
  confirmDeleteLoading = false;

  @Prop({ required: false, default: '' })
  deleteItemName!: string;

  @Prop({ required: false, default: 'You\'ll lose all content related to this. We can\'t recover it once you confirm this action.' })
  deleteParagraph!: string;

  @Prop({ required: false, default: false })
  closeParentFirst!: boolean;

  /* Needed to to be able to call `this.$parent.close()` below,
     * see https://github.com/buefy/buefy/issues/1184
     */
  public $parent: any;

  mounted() {
    window.addEventListener('keydown', this.handleKeyDown);
  }

  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.deleteAccept();
    }
  }

  deleteAccept() {
    this.confirmDeleteLoading = true;
    // Todo: rewrite this. If closeParentFirst is true, we should simply set a timeout on emit
    if(this.closeParentFirst === true) {
      setTimeout(() => {
        this.$emit('confirmed', true);
        this.confirmDeleteLoading = false;
        this.$parent.close();
      }, 1200);
    } else {
      this.$emit('confirmed', true);
      this.confirmDeleteLoading = false;
      this.$parent.close();
    }
  }
}
