











































































import {Component, Vue} from 'vue-property-decorator';
import {DeleteWorkspaceAvatar, UpdateWorkspaceMutation, WorkspaceQuery} from "@/graphql/workspace";
import WorkspaceAvatar from '@/components/workspace/WorkspaceAvatar.vue';
import AvatarModal from "@/components/settings/workspace/AvatarModal.vue";
import {Role} from "@/typescript/types";
import ConfirmDelete from "@/components/general/ConfirmDelete.vue";

@Component({
  components: {WorkspaceAvatar},
})
export default class WorkspaceSettingsIndex extends Vue {
  currentView: string | null = 'display';
  public workspaceName: string | null = null;
  public loading = false;
  public $parent: any;

  mounted() {
    document.title = "Pembio: " + this.workspace.name + " settings";
    this.workspaceName = this.workspace.name;

    this.$events.listen('workspace-avatar-changed', eventData => {
      this.workspace.avatar = eventData;
      this.$buefy.toast.open({
        message: 'Avatar updated!',
        position: 'is-bottom-right',
        type: 'is-black',
      });
    })
  }

  get me() {
    return this.$store.state.me;
  }

  get myRole(): Role {
    return this.$store.state.workspace.my_role;
  }

  get workspace() {
    return this.$store.state.workspace;
  }

  get isAdminOfWorkspace() {
    return this.$store.getters.isAdminOfWorkspace;
  }

  get roleName(): string {
    if (this.myRole === Role.Admin) {
      return 'Admin'
    }
    if (this.myRole === Role.Member) {
      return 'Member'
    }
    if (this.myRole === Role.Observer) {
      return 'Observer'
    }
    if (this.myRole === Role.Owner) {
      return 'Owner'
    }
    if (this.myRole === Role.PrimaryOwner) {
      return 'Primary owner'
    }
    return 'Guest'
  }

  openAvatarModal() {
    this.$buefy.modal.open({
      component: AvatarModal,
      props: {
        workspace_id: this.workspace.id,
      },
      width: '300px',
      parent: this,
      animation: 'none',
    });
  }

  save() {
    this.loading = true
    return this.$apollo.mutate({
      mutation: UpdateWorkspaceMutation,
      variables: {
        id: this.workspace.id,
        name: this.workspaceName
      },
      refetchQueries:[{query: WorkspaceQuery, variables:{pid: this.workspace.pid}}]
    }).catch(error => {
      console.error(error);
    }).then(() => {
      this.$emit('close', true);
    }).finally(() => {
      this.loading = false
      this.$buefy.toast.open({
        message: 'Workspace name updated!',
        position: 'is-bottom-right',
        type: 'is-black',
      });
    });
  }

  confirmAvatarDelete(){
    this.$buefy.modal.open({
      component: ConfirmDelete,
      props: {
        deleteItemName: 'Are you sure you want to delete?'
      },
      events: {
        //@ts-ignore ToDo: fix type issue
        'confirmed': value => {
          if (value === true) {
            this.avatarDeleteConfirmed();
          }
        }
      },
      width: '500px',
      parent: this,
    });
  }

  avatarDeleteConfirmed() {
    this.$apollo.mutate({
      mutation: DeleteWorkspaceAvatar,
      variables: {
        id: this.workspace.id
      }

    }).then((_) => {
      this.workspace.avatar = null;
    })
  }


}
