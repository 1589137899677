



































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import UserService from "@/services/UserService";
import { VueRecaptcha } from 'vue-recaptcha';
import SocialAuth from "@/components/auth/SocialAuth.vue";
import Template from "@/components/templates/Template.vue";
import RegistrationSteps from "@/components/auth/RegistrationSteps.vue";
import Register from './register/Register.vue'
import RegisterPageTemplate from "@/components/auth/register/RegisterPageTemplate.vue";
//import PusherService from "@/PusherService";

@Component({
  components: {
    RegisterPageTemplate,
    RegistrationSteps, Template, SocialAuth, VueRecaptcha, Register},
})
export default class AutoGenRegister extends Vue {
  workspaceName: string | null = null;
  isLoading: boolean = false;

  userFirstName: string | null = null;
  userLastName: string | null = null;
  userEmail: string | null = null;
  referralCode: string | null = null;

  templates: Array<any> = [];
  template_id: string | null = null;

  referrer: string | null = null;
  introMessage: string | null = null;
  service: UserService | null = null;
  additional_registration_info: Object | null = null;
  visiorInfo: Object | null = null;
  ipAddress: String | null = null;
  recaptchaToken: String | null = null;
  googleSiteKey = process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY

  requestGuestSignUp: boolean = false;

  userType: boolean = false;
  nameWorkspace: boolean = false;
  userAccountType: string | null = null;
  currentStep: number = 1;
  emailRegistration: boolean = false;
  showMoreInformation: boolean = false;
  hideGuestAuth: boolean = false;
  softIntroButton: boolean = true;
  useCaseActive: boolean = false;

  introStep: number = 0;

  get meObject() {
    return this.$store.state.me;
  }

  get isAuthenticated() {
    return this.$store.getters.isAuthenticated;
  }

  get todoRoute() {
    return this.$route.name === 'landing-page-smart-goals' || this.$route.name === 'landing-page-personal-goals' || this.$route.name === 'landing-page-gtd';
  }

  get displayFullName() {
    // bad code - rewrite. Should return either full name, or name/lastname or else "continue as guest".
    if(this.userFirstName && this.userLastName) {
      return this.userFirstName + ' ' + this.userLastName;
    } else if(this.userFirstName && !this.userLastName) {
      return this.userFirstName;
    } else if(!this.userFirstName && this.userLastName) {
      return this.userLastName;
    } else {
      return null;
    }
  }

  get domainName() {
    return this.workspaceDemoName;
  }

  get hasCustomHeadline() {
    if(this.$route.query.pmb_campaign) {
      return this.$route.query.pmb_campaign;
    } else {
      return null;
    }
  }

  get workspaceDemoName() {
    if(this.workspaceName) {
      return this.workspaceName;
    } else {
      //return 'Space-'+(Math.random() * 100);
      return 'Work';
    }
  }

  playIntroSteps() {
    this.introStep = 1;
    setTimeout(() => this.introStep = 2, 2000);
  }

  bookDemo() {
    window.open('https://calendly.com/johan-flodgren/30min', '_blank');
  }


  createUser() {
    this.isLoading = true;
    this.$gtag.event('pmb_app_decision');
    this.$gtag.event('src_' + this.$route.path);
    // Create the user
    //@ts-ignore
    this.service.createFakeUser(this.userEmail, this.userFirstName, this.userLastName, JSON.stringify(this.additional_registration_info)).then((res: any) => {
      this.$store.commit('logged_in', res.data.registerFakeUser.access_token);
      this.$events.fire('auto-gen');
      this.userType = true;
      this.currentStep = 2;
      if(this.referralCode && this.template_id) {
        this.$router.push({name: 'registration-steps', query: {'template_id': this.template_id, 'u_referral_code': this.referralCode}})

      }else if(this.referralCode) {
        this.$router.push({name: 'registration-steps', query: {'u_referral_code': this.referralCode}})

      }else if(this.template_id) {
        this.$router.push({name: 'registration-steps', query: {'template_id': this.template_id}})

      }else {
        this.$router.push({name: 'registration-steps'})

      }
    }).catch((error: any) => {
      Vue.prototype.$consoleLog(error);
    })
  }

  createReferral() {
    // Only run this if there's a referrer reference in the url parameter
  }
  connectTemplates() {
    // if there are one or multiple template_id references, connect them to the workspace.
  }
  assignUrlParameters() {
    
    const userData = {
      'email': '',
      'first_name': '',
      'last_name': '',
      'referral_code': '',
      'template_id': ''
    }
    const urlParams = new URLSearchParams(window.location.search);

    const workspaceName = urlParams.get('w_name');

    const userName = urlParams.get('u_name');
    const userLastName = urlParams.get('u_lastname');
    const userEmail = urlParams.get('u_email');
    const referral_code = (this.$store.state.autoGenUserData && this.$store.state.autoGenUserData!.referral_code) ? this.$store.state.autoGenUserData!.referral_code : urlParams.get('u_referral_code');
    const template_id = urlParams.get('template_id');
    const additional_registration_info = urlParams.get('additional_registration_info');

    if(workspaceName) { this.workspaceName = workspaceName; }

    if(userName) { this.userFirstName = userName; userData.first_name = userName; }
    if(userLastName) { this.userLastName = userLastName; userData.last_name = userLastName; }
    if(userEmail) { this.userEmail = userEmail; userData.email = userEmail; }
    if(referral_code) { this.referralCode = referral_code; userData.referral_code = referral_code;}
    if(template_id) { this.template_id = template_id; userData.template_id = template_id; }
    if(additional_registration_info) {
      let addInfo = {'marketing_referrer': additional_registration_info, 'ipAddress': this.ipAddress};
      this.additional_registration_info = {
        ...addInfo,
        ...this.visiorInfo,
      }
    }else{
      let ip = {'ipAddress': this.ipAddress}
      this.additional_registration_info = {
        ...this.visiorInfo,
        ...ip
      }
    }

 

    this.$store.commit('set_auto_gen_user_data', userData);
   
  }

  beforeMount() {
    this.service = new UserService(this.$apollo);
  }

  onCaptchaVerified(recaptchaToken: any) {
    this.recaptchaToken = recaptchaToken
    this.createUser();
  }

  onCaptchaExpired() {
    //@ts-ignore
    this.$refs.recaptcha.$el.reset();
  }

  mounted() {

    

    // If user is on the verification step in the email registration process redirect to the auth step
    if (this.$store.state.currently_on_verification_code_step_email) {
      this.$router.push({name: 'register'});
    }
    // if user is authenticated - this page should redirect to the app
    if(this.isAuthenticated) {
      this.$router.push({name: 'dashboard'});
    }
    //@ts-ignore
    this.$ipaddress.getIpAddress().then((res: string)=>{
      this.ipAddress = res;
      //@ts-ignore
      this.visiorInfo = this.$userAgent.getUserAgent();

      this.assignUrlParameters();
    });
    this.$gtag.event('pmb_app_decision_page_mounted');

    const urlParams = new URLSearchParams(window.location.search);
    const network = urlParams.get('network');
    const campaign = urlParams.get('campaign');
    const adSource = urlParams.get('ad_source');
    const guestAllow = urlParams.get('guest');
    const softIntro = urlParams.get('soft');
    const useCase = urlParams.get('use_case');

    if(adSource) {
      this.$gtag.event('pmb_reg_source_' + adSource);
    }
    if(campaign === 'true') {
      this.$store.commit('set_user_from_ad', true);
    }
    if(network === 'guest') {
      this.requestGuestSignUp = true;
      this.createUser();    
    }
    if(guestAllow === 'false') {
      this.hideGuestAuth = true;
    }
    if(softIntro === 'false') {
      this.softIntroButton = false;
    }
    if(useCase === 'true') {
      this.useCaseActive = true;
    }
  }

  @Watch ( '$route', { immediate: true, deep: true })
  networkAdded() {
    const urlParams = new URLSearchParams(window.location.search);
    const network = urlParams.get('autoRun');
    if(network === 'guest') {
      this.createUser();
    }
  }

}
