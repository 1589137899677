import gql from 'graphql-tag';
import {ThirdPartyProviderDetailedFragment} from "@/graphql/ThirdPartyProvider";
import {WorkspaceSubscriptionFragment} from "@/graphql/WorkspaceSubscription";

export const UserStatusDetailedFragment = gql`
    fragment UserStatusDetailed on UserStatus {
        id
        user_id
        emoji
        status_text
        tenure
        start_at
        end_at
    }
`;

export const MeDetailedFragment = gql`
    fragment Me on User {
        id
        avatar
        email
        first_name
        full_name
        name
        has_verified_email
        initials
        last_name
        timezone
        country
        country_code
        region
        city
        isFake
        engagement_score
        created_at
        account_type
        last_active_date
        unique_day_session_count
        stripe_customer_id
        has_freemium_access
        settings {
            dark_mode
        }
        invites {
            id
            pid
            workspace {
                id
                pid
                name
            }
        }
        notifications {
            data {
                id
                message {
                    source
                    message
                    message_detail_id
                    message_detail_board_pid
                    message_detail_workspace_pid
                }
                read_at
                created_at
            }
            paginatorInfo {
                total
                currentPage
                hasMorePages
            }
        }
        workspaces {
            id
            pid
            name
            domain
            my_role
            avatar
            members {
                id
            }
            my_access
            boards {
                id
                pid
            }
           
        }
        status {
            ...UserStatusDetailed
        }
        referral_code

        referredData {
            total_referred_workspaces
            total_members
            referredWorkspaces {
                id
                deleted_at
                workspace {
                    id
                    pid
                    name
                    owner {
                        id
                        email
                        isFake
                    }
                    members {
                        id
                        role
                        user {
                            name
                        }
                    }
                    currentSubscription {
                        ...WorkspaceSubscription
                    }
                }
            }
        }
        thirdPartyProviders {
            ...ThirdPartyProviderDetailed
        }
        billingDetails {
            id
            name
            email
            account_type
            line1
            line2
            country
            city
            state
            postal_code
            is_default
        }
        taxDetails {
            id
            stripe_tax_id
            tax_type
            tax_id
        }

    }
    ${UserStatusDetailedFragment}
    ${ThirdPartyProviderDetailedFragment}
    ${WorkspaceSubscriptionFragment}
`;


export const AuthPayloadFragment = gql`
    fragment AuthPayload on AuthPayload {
        access_token
        refresh_token
        expires_in
        token_type
    }
`;

export const MeQuery = gql`
    query Me {
        me {
            ...Me
        }
    }
    ${MeDetailedFragment}
`;

export const RemindersDetailedFragment = gql`
    fragment RemindersDetailed on Reminder {
        id
        name
        description
        date_time
        notification_type
        creator {
            id
            name
            email
        }
        remindable {
            ... on BoardMessage {
                id
                message
            }
            ... on KeyResult{
                id
                description
                due_at
            }
            ... on Objective {
                id
                name
            }
            ... on User {
                id                
                name
                deleted_at
            }
        }
        users {
            id
            name
            email
            deleted_at
        }
    }
`;

export const CreatedReminders = gql`
    query createdReminders {
        createdReminders {
            ...RemindersDetailed
        }
    }
    ${RemindersDetailedFragment}
`;

export const CreateReminderMutation = gql`
    mutation createReminder(
        $name: String!,
        $description: String,
        $date_time: DateTime!,
        $remindable_type: RemindableType!,
        $remindable_id: ID!
        $users: [ID!]!
    ) {
        createReminder(
            input: {
                name: $name,
                description: $description,
                date_time: $date_time,
                remindable: { connect: { type: $remindable_type, id: $remindable_id } },
                users: $users
            }) {
            id
            name
            description
            date_time
        }
    }
`;

export const DeleteReminderMutation = gql`
    mutation DeleteReminder($id: ID!) {
        deleteReminder(id: $id)
    }
`;

export const ForgotPasswordMutation = gql`
    mutation ForgotPassword($email: String!) {
        forgotPassword(input: { email: $email }) {
            status
            message
        }
    }
`;

export const RegisterMutation = gql`
    mutation Register(
        $email: String!
        $first_name: String
        $last_name: String
        $password: String
        $password_confirmation: String
        $ipAddress: String
    ) {
        register(
            input: {
                email: $email
                first_name: $first_name
                last_name: $last_name
                password: $password
                password_confirmation: $password_confirmation
                ipAddress: $ipAddress
            }
        ) {
            access_token
        }
    }
`;

export const LoginMutation = gql`
    mutation Login($username: String!, $password: String!) {
        login(input: { username: $username, password: $password }) {
            ...AuthPayload
        }
    }
    ${AuthPayloadFragment}
`;

export const GetAccessTokenWithEmailMutation = gql`
    mutation GetAccessTokenWithEmail($email: String!) {
        getAccessTokenWithEmail(email: $email) {
            access_token
        }
    }
`;

export const SocialLoginMutation = gql`
    mutation SocialLogin($token: String!, $provider: String!, $ipAddress: String, $isClaimAccount: Boolean, $claimAccountUserId: ID) {
        socialLogin(input: { token: $token, provider: $provider, ipAddress: $ipAddress, isClaimAccount: $isClaimAccount, claimAccountUserId: $claimAccountUserId  }) {
            ...AuthPayload
        }
    }
    ${AuthPayloadFragment}
`;

export const LogoutMutation = gql`
    mutation Logout {
        logout {
            status
            message
        }
    }
`;

export const UpdateForgottenPasswordMutation = gql`
    mutation UpdateForgottenPassword(
        $email: String!
        $token: String!
        $password: String!
        $password_confirmation: String!
    ) {
        updateForgottenPassword(
            input: { email: $email, token: $token, password: $password, password_confirmation: $password_confirmation }
        ) {
            status
            message
        }
    }
`;

export const UpdateUserMutation = gql`
    mutation UpdateUser(
        $id: ID!
        $first_name: String
        $last_name: String
        $email: String
        $avatar: String
        $last_active_date: DateTime
        $country: String
        $country_code: String
        $city: String
        $timezone: String     
        $has_freemium_access: Boolean
    ) {
        updateUser(input: {
            id: $id
            first_name: $first_name
            last_name: $last_name
            email: $email
            avatar: $avatar
            last_active_date: $last_active_date
            country: $country
            country_code: $country_code
            city: $city
            timezone: $timezone           
            has_freemium_access: $has_freemium_access
        }) {
            ...Me
        }
    }
    ${MeDetailedFragment}
`;
export const UpdateUserSettingsMutation = gql`
    mutation UpdateUserSettings(
        $email_marketing: Boolean
    ) {
        updateUserSettings(input: {
            email_marketing: $email_marketing
        }) {
            email_marketing
        }
    }
`;

export const VerifyEmailWithPin = gql`
    mutation VerifyEmailWithPin($pin: String!) {
        verifyEmailWithPin(pin: $pin) {
            ...Me
        }
    }
    ${MeDetailedFragment}
`;

export const SetInitialPassword = gql`
    mutation SetInitialPassword($password: String!) {
        setInitialPassword(password: $password)
    }
`;

export const MarkAllNotificationsAsReadMutation = gql`
    mutation MarkAllNotificationsAsRead {
        markAllNotificationsAsRead
    }
`;

export const MarkNotificationAsReadMutation = gql`
    mutation MarkNotificationAsRead($id: ID!) {
        markNotificationAsRead(id: $id)
    }
`;

export const MarkNotificationAsUnreadMutation = gql`
    mutation MarkNotificationAsUnread($id: ID!) {
        markNotificationAsUnRead(id: $id)
    }
`;

export const DeleteNotificationMutation = gql`
    mutation DeleteNotification($id: ID!) {
        deleteNotification(id: $id)
    }
`;

export const DeleteAllNotificationsMutation = gql`
    mutation DeleteAllNotifications {
        deleteAllNotifications
    }
`;

export const ResendEmailVerification = gql`
    mutation resendEmailVerification {
        resendEmailVerification
    }
`;

export const AlternateVerification = gql`
    mutation alternateVerification($password: String!) {
        verifyAlternatively(password: $password)
    }
`;

export const SetMyStatus = gql`
    mutation SetMyStatus(
        $emoji: String
        $status_text: String
        $tenure: String
        $start_at: DateTime
        $end_at: DateTime
    ) {
        setMyStatus(input: {
            emoji: $emoji
            status_text: $status_text
            tenure: $tenure
            start_at: $start_at
            end_at: $end_at
        }) {
            ...UserStatusDetailed
        }
    }
    ${UserStatusDetailedFragment}
`;

export const DeleteUserStatus = gql`
    mutation DeleteUserStatus(
        $id: ID!
    ) {
        deleteStatus(id: $id) {
            id
        }
    }
`;

export const UpdateEmail = gql`
    mutation UpdateEmail(
        $email: String!
    ){
        updateEmail(input: { email: $email})
        {
            id
            user_id
            verification_pin
            email
        }
    }
`;

export const VerifyPinAndUpdateEmail = gql`
    mutation VerifyPinAndUpdateEmail(
        $pin: String!,
        $email: String!
    ){
        verifyPinAndUpdateEmail(pin: $pin, email: $email)
        {
            id
        }
    }
`;

export const AddNewReferralCode = gql`
    mutation AddNewReferralCode{
        addNewReferralCode {
            referral_code
        }
    }
`;

export const SetUserTimeZone = gql `
    mutation SetUserTimeZone($ipAddress: String!){
        setUserTimeZone(ipAddress: $ipAddress){
            id
        }
    }
`;

export const GetUserNotifications = gql`
    query getUserNotifications($first: Int, $page: Int, $isWorkspace: Boolean, $workspacePid: String) {
        userNotifications(first: $first, page: $page, isWorkspace: $isWorkspace, workspacePid: $workspacePid){
            data {
                id
                message {
                    source
                    message
                    message_detail_id
                    message_detail_board_pid
                    message_detail_workspace_pid
                }
                read_at
                created_at
            }
            paginatorInfo {
                total
                currentPage
                hasMorePages
            }
        }
    }
`;

export const CheckEmail = gql`
    query CheckEmail($email: String!) {
        checkEmail(email: $email)
    }
`;


export const RegisterFakeMutation = gql`
    mutation RegisterFakeUser(
        $email: String
        $first_name: String
        $last_name: String
        $additional_registration_info: String       
    ) {
        registerFakeUser(
            input: {
                email: $email
                first_name: $first_name
                last_name: $last_name
                additional_registration_info: $additional_registration_info                
            }
        ) {
            access_token
        }
    }
`;

export const ClaimOwnAccount = gql`
    mutation ClaimOwnAccount($email: String!, $firstName: String!, $lastName: String!, $password: String!){
        claimAccount(
            input: {
                email: $email
                first_name: $firstName
                last_name: $lastName
                password: $password                
            }
        )
    }
`;

export const GetMyRemindersOnDateRange = gql`
    query getMyRemindersOnDateRange($start_date: DateTime!, $end_date: DateTime!, $userId: ID ) {
        myRemindersOnDateRange(start_date: $start_date, end_date: $end_date, userId: $userId) {
            data {
                ...RemindersDetailed
            }
            paginatorInfo {
                total
                currentPage
                hasMorePages
            }
            
        }
    }
    ${RemindersDetailedFragment}
`;

export const UpdateUserAccountType = gql `
    mutation UpdateUserAccountType($account_type: String!) {
        updateUserAccountType(account_type: $account_type) {
            ...Me
        }
    }
    ${MeDetailedFragment}
`;

export const GetAllPaymentMethods = gql`
    mutation GetAllPaymentMethods($stripe_customer_id: String!) {
        getAllPaymentMethods(stripe_customer_id: $stripe_customer_id) {
            id
            card {
                brand
                last_4
                exp_month
                exp_year
                fingerprint
            }   
            is_default
        }
    }
`

export const MakePaymentMethodPrimary = gql`
    mutation MakePaymentMethodPrimary($stripe_customer_id: String!, $paymentMethod: String!) {
        setPaymentMethodPrimary(stripe_customer_id: $stripe_customer_id, paymentMethod: $paymentMethod) {
            id
            card {
                brand
                last_4
                exp_month
                exp_year
                fingerprint
            }
            is_default
        }
    }
`

export const RemovePaymentMethod = gql`
    mutation RemovePaymentMethod($paymentMethod: String!) {
        removePaymentMethod(paymentMethod: $paymentMethod) 
    }
`

export const AddNewPaymentMethod = gql`
    mutation AddNewPaymentMethod($stripe_customer_id: String!, $paymentMethod: String!) {
        addNewCard(stripe_customer_id: $stripe_customer_id, paymentMethod: $paymentMethod) {
            id
            card {
                brand
                last_4
                exp_month
                exp_year
                fingerprint
            }
            is_default
        }
    }
`

export const AddStripeCustomerBillingDetails = gql`
    mutation AddStripeCustomerBillingDetails($name: String!, $email: String!, $accountType: String, $line1: String, $country: String, $city: String, $postal_code: String, $isDefault: Boolean) {
        addBillingDetails(input: {
            name: $name,
            email: $email,
            account_type: $accountType
            line1: $line1
            country: $country
            city: $city
            postal_code: $postal_code
            isDefault: $isDefault
        }){
            id
            name
            email
            account_type
            line1
            country
            city
            postal_code
            is_default
        }
    }
`

export const UpdateStripeCustomerBillingDetails = gql`
    mutation UpdateStripeCustomerBillingDetails($name: String!, $email: String!, $accountType: String, $id: ID!, $line1: String, $country: String, $city: String, $postal_code: String, $isDefault: Boolean) {
        updateBillingDetails(
            id: $id
            input: {
              name: $name,
              email: $email,
              account_type: $accountType
              line1: $line1
              country: $country
              city: $city
              postal_code: $postal_code
              isDefault: $isDefault
        }){
            id
            name
            email
            account_type
            line1
            country
            city
            postal_code
            is_default
        }
    }
`

export const RemoveBillingDetails = gql `
    mutation RemoveBillingDetails($id: ID!) {
        removeBillingDetails(id: $id) {
            id
        }
    }
`

export const AddStripeCustomerTaxDetails = gql`
    mutation AddStripeCustomerTaxDetails($tax_type: String!, $tax_id: String!) {
        addUserTaxDetail(tax_type: $tax_type, tax_id: $tax_id){
            id
            tax_type
            tax_id
            stripe_tax_id
        }
    }
`

export const UpdateStripeCustomerTaxDetails = gql `
    mutation UpdateStripeCustomerTaxDetails($id: ID!, $tax_type: String!, $tax_id: String!) {
        updateUserTaxDetail(id: $id, tax_type: $tax_type, tax_id: $tax_id) {
            id
            tax_type
            tax_id
            stripe_tax_id
        }
    }
`


