




























































































































































  
import {Component, Vue} from 'vue-property-decorator';
import NotificationBarCountDownTimer from '../general/NotificationBarCountDownTimer.vue';


@Component({
  components: { NotificationBarCountDownTimer},
})
export default class InitialOffer extends Vue {
  cancelClicked: boolean = false;
  reason: string | null = null;
  loading: boolean = false;
  
  claimPremiumClicked() {
    this.loading = true;
    setTimeout(() => {
      this.$router.push({name: 'plans' });
      this.loading = false;
    }, 1400);
    this.$store.commit('set_show_initial_offer_notification_bar', true);
    this.$store.commit('set_initial_offer_code', true);
    this.$store.commit('set_show_initial_offer', null);

    this.$gtag.event('pmb_initial_offer_accepted');
    //@ts-ignore
    this.$parent.close();
  }
  
  back() {
    this.cancelClicked = false;
    this.reason = null;
  }

  closeModal(reason: string) {
    this.$store.commit('set_show_initial_offer_notification_bar', true);
    this.$store.commit('set_initial_offer_code', true);
    this.$store.commit('set_show_initial_offer', null);
    if(reason === '1') {
      this.$gtag.event('pmb_initial_offer_denied_expensive');
    } else if(reason === '2') {
      this.$gtag.event('pmb_initial_offer_denied_more_time');
    } else if(reason === '3') {
      this.$gtag.event('pmb_initial_offer_denied_wont_pay');
    }
    this.$router.push({name: 'overview' });
    //@ts-ignore
    this.$parent.close();
  }
}
