


















import {Component, Vue} from 'vue-property-decorator';
import WorkspaceSidebar from "@/components/navigation/WorkspaceSidebar.vue";
import WorkspaceSwitcher from "@/components/navigation/WorkspaceSwitcher.vue";
import OnboardingNavigator from "@/components/board/onboarding/OnboardingNavigator.vue";
import UserActivation from "@/components/auth/UserActivation.vue";


@Component({
  components: {UserActivation, OnboardingNavigator, WorkspaceSwitcher, WorkspaceSidebar},
})
export default class WorkspaceLayout extends Vue {
  mobileMenuExpanded: boolean = false;

  get workspace() {
    return this.$store.state.workspace;
  }

  get hasRooms() {
    return this.workspace && this.workspace.boards && this.workspace.boards.length >= 1;
  }

  toggleSidebar() {
    
  }

  mounted() {
    if(this.workspace && !this.hasRooms) {
      this.$router.push({name: 'create-board', params: { workspace_pid: this.$store.state.workspace.pid } })
    }
  }
}
